import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const backgroundImg = "./img/wordcloud_512.jpg";
const videoURL = "./background.mov";
import Heading from "./Heading";
import Footer from "./Footer";
import { Button, CardActions, Container, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { ContentCard } from "./components/ContentCard";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { useEffect, useState } from "react";
import { loadFrontPageDiscovery } from "./DiscoveryStore";
import { useNavigate } from "react-router-dom";
import { useCacheBusterStore } from "./UrlUrils";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { useLogger } from "./logger";
import { GuideCard } from "./components/GuideCard";
import { TopButtonMenu } from "./components/TopButtonMenu";
const LOGPREFIX = "Public: ";
export function PublicContent() {
    const [discoveryCards, setDiscoveryCards] = useState([]);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const auth = useProfileAuthStore((state) => state);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const layout = getLayoutInfo();
    const logger = useLogger((state) => state);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect");
        const retVal = () => { };
        if (auth && auth.isSignedIn()) {
            navigate("/Home");
            return retVal;
        }
        if (!loading && !loaded) {
            setLoading(true);
            loadFrontPageDiscovery({
                okCallback: (elements) => {
                    logger.log(LOGPREFIX + "useEffect - okCallback - cards = " + elements.length);
                    setDiscoveryCards(elements);
                    setLoading(false);
                    setLoaded(true);
                },
                errorCallback: (errorMessage) => {
                    logger.error(LOGPREFIX + "useEffect - discovery load failed: " + errorMessage);
                    setLoading(false);
                    setLoaded(false);
                },
            }, false, navigate, cacheBusterStore, logger, auth);
        }
        return retVal;
    }, [setDiscoveryCards, loaded]);
    const backgroundVideo = (_jsx("div", Object.assign({ className: "backgroundVideo" }, { children: _jsxs("video", Object.assign({ id: "background-video", loop: true, autoPlay: true, style: {
                verticalAlign: "middle",
                width: layout.videoWidth,
            }, poster: backgroundImg }, { children: [_jsx("source", { src: videoURL, type: "video/quicktime" }), _jsx("source", { src: videoURL, type: "video/mp4" }), _jsx("source", { src: videoURL, type: "video/ogg" })] }), "background-video") }), "background-video-div"));
    const workInProgressCardContent = (_jsxs(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: [_jsx(Typography, Object.assign({ variant: "body1", sx: {
                    textAlign: "left",
                    paddingLeft: layout.sidePadding,
                    paddingRight: layout.sidePadding,
                } }, { children: "We know you have more choices than ever in Social Media Networks. We hope you will find MediaShare.Link to be a great place to share your journey with your friends and family. Thank you for visiting." })), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Our Vision" })), _jsx("br", {}), _jsx("div", Object.assign({ style: {
                    width: "80%",
                    height: "80%",
                    display: "inline-block",
                    textAlign: "center",
                    margin: "10px",
                    padding: 0,
                } }, { children: backgroundVideo }))] })));
    const workInProgressCard = (_jsx(ContentCard, { keyStr: "public.wip.card", childComponent: workInProgressCardContent, title: "Welcome to MediaShare.Link!", textColor: "main", backgroundColor: "#FFFFFF", mediaUrl: "./img/public_splash.jpg" }, "public.wip.card"));
    const cardsList = [];
    if (errorMessage) {
        cardsList.push(_jsx(ContentCard, { title: "ERROR", keyStr: "error.card.latest", textColor: "#000000", backgroundColor: "#FFEEEE", childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })), actions: _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setErrorMessage(undefined);
                        setLoaded(false);
                    } }, { children: "RETRY" })) })) }));
    }
    else if (loaded) {
        discoveryCards.forEach((card) => {
            cardsList.push(card);
        });
        cardsList.push(workInProgressCard);
        cardsList.push(_jsx(GuideCard, {}));
    }
    const cardsStack = (_jsx(ContentCardStacks, { cards: cardsList, columnCount: 4 }, "cards.stack.public"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", height: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsxs(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    }, maxWidth: false }, { children: [_jsx(TopButtonMenu, { current: "latest" }), loading && _jsx(LinearProgress, {}), cardsStack] })), _jsx(Footer, { stickToBottom: false })] })) })));
    return output;
}
