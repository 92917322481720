var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CardActions, LinearProgress, Stack, TextField, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { contactOperation } from "../DataUtils";
import { useCacheBusterStore } from "../UrlUrils";
const LOGPREFIX = "ContactUs";
export const ContactUs = (props) => {
    const [subject, setSubject] = useState();
    const [helperSubject, setHelperSubject] = useState(undefined);
    const [text, setText] = useState();
    const [helperText, setHelperText] = useState(undefined);
    const [email, setEmail] = useState();
    const [helperEmail, setHelperEmail] = useState(undefined);
    const [saving, setSaving] = useState(false);
    const [savedOk, setSavedOk] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const auth = useProfileAuthStore();
    const cacheBusterStore = useCacheBusterStore();
    const validateSubject = (subject) => {
        setErrorMessage(undefined);
        setSaving(false);
        if (!subject) {
            setHelperSubject(undefined);
            return;
        }
        if (subject.includes("<")) {
            setHelperSubject("Subject cannot contain '<' character.");
        }
        else if (subject.includes("@")) {
            setHelperSubject("Subject cannot contain '@' character.");
        }
        else if (subject.includes("\n")) {
            setHelperSubject("Subject cannot contain newline character.");
        }
        else {
            setHelperSubject(undefined);
        }
    };
    const validateText = (text) => {
        setErrorMessage(undefined);
        setSaving(false);
        if (!text) {
            setHelperText(undefined);
            return;
        }
        if (text.includes("<")) {
            setHelperText("Text cannot contain '<' character.");
        }
        else {
            setHelperText(undefined);
        }
    };
    const validateEmail = (email) => {
        setErrorMessage(undefined);
        setSaving(false);
        if (!email) {
            setHelperEmail(undefined);
            return;
        }
        // TODO: check valid email address
        if (email.includes("<")) {
            setHelperEmail("Text cannot contain '<' character.");
        }
        else {
            setHelperEmail(undefined);
        }
    };
    useEffect(() => {
        validateEmail(email);
        return () => { };
    }, [email]);
    useEffect(() => {
        validateSubject(subject);
        return () => { };
    }, [subject]);
    useEffect(() => {
        validateText(text);
        return () => { };
    }, [text]);
    const doPublish = (token) => {
        console.log("doPublish: " + token);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const handleError = (errorMessage) => {
            console.log("doPublish: handleError " + errorMessage);
            setTimeout(() => {
                setErrorMessage(errorMessage);
                setSavedOk(false);
                setSaving(false);
            }, 300);
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const handleOk = () => {
            console.log("doPublish: handleOk");
            setSaving(false);
            setErrorMessage(undefined);
            setTimeout(() => {
                setSavedOk(true);
            }, 300);
        };
        setErrorMessage(undefined);
        setSavedOk(false);
        setSaving(true);
        const ts = Math.floor(new Date().getTime() / 1000);
        const inputData = {
            subject: subject ? subject : "no subject",
            email: email ? email : "no email",
            text: text ? text : "no text",
            userId: auth && auth.userId ? auth.userId : "",
            displayName: auth && auth.displayName ? auth.displayName : "",
            bearer: auth && auth.bearer ? auth.bearer : "",
            timeStamp: ts,
            token: token,
        };
        console.log("Saving Contact: " + JSON.stringify(inputData));
        contactOperation(inputData, {
            okCallback: function (clientDetailCode, data) {
                console.log(LOGPREFIX + "saved ok - " + clientDetailCode + " " + data);
                setSaving(false);
                setSavedOk(true);
            },
            errorCallback: function (clientDetailCode, errorMessage) {
                setSaving(false);
                setSavedOk(false);
                let message = errorMessage;
                if (!message) {
                    message = "Failed to send message. " + clientDetailCode;
                }
                setErrorMessage(message);
            },
        }, cacheBusterStore);
    };
    if (savedOk) {
        const childComponent = (_jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: "Your message has been sent." })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + ".contactsent", title: "Message Sent", childComponent: childComponent, textColor: getTheme().palette.black.main, backgroundColor: "#EEFFEE" }, props.keyStr + ".contactsent"));
    }
    if (errorMessage) {
        const errorChildComponent = (_jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + ".errormessage", title: "ERROR", childComponent: errorChildComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", actions: _jsx(CardActions, Object.assign({ sx: { justifyContent: "center", display: "flex" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setErrorMessage(undefined);
                    } }, { children: "DISMISS" })) })) }, props.keyStr + ".errormessage"));
    }
    const content = (_jsxs(Stack, Object.assign({ direction: "column", sx: { justifyContent: "center", display: "flex", margin: "10%" }, spacing: 2 }, { children: [!savedOk && (_jsx(Typography, Object.assign({ variant: "body1" }, { children: "Please provide your email address, subject, and the details of your inquiry." }))), !savedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-title", label: "Email Address", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log(LOGPREFIX + "add contact email onChange - " + value);
                    setEmail(value);
                }, error: !!helperEmail, helperText: helperEmail, disabled: saving, value: email, sx: { marginBottom: "8px" } }, props.keyStr + "contactcard.textfield.email")), !savedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-title", label: "Subject", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log(LOGPREFIX + "add contact subject onChange - " + value);
                    setSubject(value);
                }, error: !!helperSubject, helperText: helperSubject, disabled: saving, value: subject, sx: { marginBottom: "8px" } }, props.keyStr + "contactcard.textfield.subject")), !savedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static", label: "Write details here...", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("edit channel text onChange - " + value);
                    setText(value);
                }, error: !!helperText, helperText: helperText, disabled: saving, value: text, sx: { marginBottom: "3px" } }, props.keyStr + ".contactcard.textfield.text")), saving && _jsx(LinearProgress, {}), _jsxs("small", { children: ["This site is protected by reCAPTCHA and the Google", " ", _jsx("a", Object.assign({ href: "https://policies.google.com/privacy" }, { children: "Privacy Policy" })), " and", " ", _jsx("a", Object.assign({ href: "https://policies.google.com/terms" }, { children: "Terms of Service" })), " apply."] })] })));
    return (_jsx(ContentCard, { title: "Contact Us", keyStr: "contactus", textColor: "#000000", backgroundColor: "#FFFFFF", childComponent: content, actions: savedOk ? undefined : (_jsx(CardActions, Object.assign({ sx: {
                justifyContent: "center",
                display: "flex",
                margin: "20px",
                padding: 0,
            } }, { children: _jsx(Button, Object.assign({ variant: "contained", disabled: saving || savedOk || !subject || !text || !email, onClick: (e) => {
                    var _a, _b;
                    e.preventDefault();
                    const grecaptcha = window.grecaptcha;
                    if (grecaptcha &&
                        ((_a = grecaptcha.enterprise) === null || _a === void 0 ? void 0 : _a.ready) &&
                        ((_b = grecaptcha.enterprise) === null || _b === void 0 ? void 0 : _b.execute)) {
                        grecaptcha.enterprise.ready(() => __awaiter(void 0, void 0, void 0, function* () {
                            grecaptcha.enterprise
                                .execute("6LeMTvYqAAAAAEXBRStAkzbH267Azriet8mmhqMq", {
                                action: "submit",
                            })
                                .then((token) => {
                                doPublish(token);
                            });
                        }));
                    }
                    else {
                        doPublish("grecaptcha-not-loaded");
                    }
                } }, { children: "Send Message" })) }))) }));
};
