var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { sendAjaxRequest, sendGETRequest } from "./API";
import { getContentURL } from "./ContentAPI";
import { ChannelCard } from "./components/ChannelCard";
import { PostCard } from "./components/PostCard";
import { authUrl } from "./UrlUrils";
import { loadChannelDataFromURL } from "./DataUtils";
const PUBLIC_HOME_DISCOVERY_FILENAME = "/d/frontPageDiscovery.json?cb=";
const LOGPREFIX = "DiscoveryStore: ";
export const discoveryPostEntryToReactElement = (entry, cacheBusterStore, auth, navigate, logger) => {
    const postKey = entry.channelOwnerId + ":" + entry.channelId + ":" + entry.postId;
    return (_jsx(PostCard, { keyStr: "postKeyCard." + postKey, basePath: getContentURL(), enableEditButtons: false, expandPostContent: false, channelOwnerId: entry.channelOwnerId, channelId: entry.channelId, postId: entry.postId, postJson: authUrl("u/" +
            entry.channelOwnerId +
            "/" +
            entry.channelId +
            "/" +
            entry.postId +
            (entry.postId.endsWith(".json") ? "" : ".json"), cacheBusterStore, auth), onClick: () => {
            logger.log(LOGPREFIX +
                "post.onClick: channelId " +
                entry.channelId +
                " postId " +
                entry.postId);
            navigate("/PostView?channelOwnerId=" +
                entry.channelOwnerId +
                "&channelId=" +
                entry.channelId +
                "&postId=" +
                entry.postId);
        } }, "postKeyCard." + postKey));
};
export const loadFrontPageDiscovery = (callbacks, channelsOnly, navigate, cacheBusterStore, logger, auth) => __awaiter(void 0, void 0, void 0, function* () {
    logger.log(LOGPREFIX + "loadFrontPageDiscovery");
    const postEntries = [];
    const elements = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deDupePostIds = {};
    const sortAndInsertEntries = () => {
        // Sort by timestamp
        postEntries.sort((a, b) => a.postId.localeCompare(b.postId));
        postEntries.forEach((entry) => {
            let postId = entry.postId;
            if (postId.endsWith(".json")) {
                postId = postId.substring(0, postId.length - 5);
            }
            entry.postId = postId;
            if (deDupePostIds[postId]) {
                return;
            }
            deDupePostIds[postId] = 1;
            elements.push(discoveryPostEntryToReactElement(entry, cacheBusterStore, auth, navigate, logger));
        });
    };
    const processChannels = (topChannelsAndPosts) => {
        logger.log(LOGPREFIX + "processChannels");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        topChannelsAndPosts.topChannels.forEach((chanKey) => {
            const chanKeyParts = chanKey.split(":");
            const channelOwnerId = chanKeyParts[0];
            const channelId = chanKeyParts[1];
            const channelJson = "u/" + channelOwnerId + "/" + channelId + "/channelData.json";
            elements.push(_jsx(ChannelCard, { keyStr: "chanKeyCard." + chanKey, basePath: getContentURL(), enableEditButtons: false, channelJson: authUrl(channelJson, cacheBusterStore, auth), maxResolution: true, maxMediaWidth: window.innerWidth, maxMediaHeight: window.innerHeight }, "chanKeyCard." + chanKey));
        });
        logger.log(LOGPREFIX + "processChannels - invoking okCallback");
        callbacks.okCallback(elements);
    };
    const startTime = new Date().getTime();
    const topPostsChannelsCallback = (ok, status, message) => {
        logger.log(LOGPREFIX +
            "topPostsChannelsCallback: ok = " +
            ok +
            ", status = " +
            status +
            ", message = " +
            message);
        // const deDupePostIds: any = {};
        if (!ok || status < 200 || status > 299) {
            logger.error(LOGPREFIX +
                "topPostsChannelsCallback - status = " +
                status +
                ", message = " +
                message);
            const errorMessage = message
                ? message
                : "Request Failed - Status = " + status;
            callbacks.errorCallback(errorMessage);
            return;
        }
        try {
            const endTime = new Date().getTime();
            logger.log("Timing: topPosts " + (endTime - startTime) + " millis");
            const topChannelsAndPosts = JSON.parse(message);
            if (!channelsOnly) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                topChannelsAndPosts.topPosts.forEach((postKey) => {
                    const postKeyParts = postKey.split(":");
                    const channelOwnerId = postKeyParts[0];
                    const channelId = postKeyParts[1];
                    //            const postOwnerId = postKeyParts[2];
                    const postId = postKeyParts[3];
                    const postEntry = {
                        channelOwnerId,
                        channelId,
                        postId,
                    };
                    postEntries.push(postEntry);
                });
                if (auth && auth.isSignedIn()) {
                    const startTime = new Date().getTime();
                    loadLatestPersonalized({
                        okCallback: function (entries) {
                            const endTime = new Date().getTime();
                            logger.log("Timing: loadLatestPersonalized " +
                                (endTime - startTime) +
                                " millis");
                            entries.forEach((entry) => {
                                postEntries.push(entry);
                            });
                            sortAndInsertEntries();
                            processChannels(topChannelsAndPosts);
                            return;
                        },
                        errorCallback: function (message) {
                            logger.error(LOGPREFIX +
                                "topPostsChannelsCallback - errorCallback message = " +
                                message);
                            let errorMessage = message;
                            if (!errorMessage) {
                                errorMessage = "Unknown Error";
                            }
                            callbacks.errorCallback(errorMessage);
                            return;
                        },
                    }, navigate, cacheBusterStore, logger, auth, deDupePostIds);
                }
                else {
                    // Not signed in
                    logger.log(LOGPREFIX + "user is NOT signed in");
                    sortAndInsertEntries();
                    processChannels(topChannelsAndPosts);
                    return;
                }
            }
            else {
                // Channels only
                processChannels(topChannelsAndPosts);
                return;
            }
        }
        catch (err) {
            logger.error(LOGPREFIX + "topPostsChannelsCallback - error = " + err);
            callbacks.errorCallback(err.message);
            return;
        }
    };
    const url = getContentURL() +
        PUBLIC_HOME_DISCOVERY_FILENAME +
        cacheBusterStore.getCacheBuster();
    sendGETRequest(topPostsChannelsCallback, url, cacheBusterStore);
});
export const loadTopFrontPageImage = (callbacks, cacheBusterStore, logger, auth) => {
    const apiCallback = (ok, status, message, cacheBusterStore) => {
        logger.log(LOGPREFIX +
            "loadTopFrontPageImage: ok = " +
            ok +
            ", status = " +
            status +
            ", message = " +
            message);
        if (ok && status >= 200 && status < 300) {
            try {
                const topChannelsAndPosts = JSON.parse(message);
                if (auth && auth.isSignedIn()) {
                    if (topChannelsAndPosts.sequence && topChannelsAndPosts.bearer) {
                        auth.setBearerAndSequence(topChannelsAndPosts.bearer, topChannelsAndPosts.sequence);
                    }
                }
                if (topChannelsAndPosts &&
                    topChannelsAndPosts.topPosts &&
                    topChannelsAndPosts.topPosts.length > 0) {
                    topChannelsAndPosts.topPosts.sort((a, b) => a.split(":")[3].localeCompare(b.split(":")[3]));
                    const postKey = topChannelsAndPosts.topPosts[0];
                    const postKeyParts = postKey.split(":");
                    const channelOwnerId = postKeyParts[0];
                    const channelId = postKeyParts[1];
                    //            const postOwnerId = postKeyParts[2];
                    const postId = postKeyParts[3];
                    let url = getContentURL() +
                        "/u/" +
                        channelOwnerId +
                        "/" +
                        channelId +
                        "/" +
                        postId;
                    if (!url.endsWith(".json")) {
                        url = url + ".json";
                    }
                    logger.log(LOGPREFIX + "loadTopFrontPageImage: url = " + url);
                    loadChannelDataFromURL(authUrl(url, cacheBusterStore, auth), {
                        okCallback: (clientDetailCode, data) => {
                            logger.log(LOGPREFIX +
                                "loadTopFrontPageImage.okCallback: post data = " +
                                data +
                                " - " +
                                clientDetailCode);
                            try {
                                const dataObj = JSON.parse(data);
                                logger.log(LOGPREFIX +
                                    "loadTopFrontPageImage: Post Data: " +
                                    JSON.stringify(dataObj));
                                if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasImage) {
                                    let imageUrl;
                                    if (!(dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasMultipleFiles)) {
                                        imageUrl = url.replace(".json", "_512.jpg");
                                    }
                                    else {
                                        imageUrl = url.replace(".json", "_" + (dataObj === null || dataObj === void 0 ? void 0 : dataObj.multipleFileCount) + "_512.jpg");
                                    }
                                    callbacks.okCallback(imageUrl);
                                }
                                else {
                                    callbacks.okCallback(undefined);
                                }
                            }
                            catch (err) {
                                logger.error(LOGPREFIX +
                                    "loadTopFrontPageImage: post data parse error: " +
                                    err);
                                callbacks.errorCallback(err.message);
                            }
                        },
                        errorCallback: (clientDetailCode, errorMessage) => {
                            logger.error(LOGPREFIX +
                                "loadTopFrontPageImage: post data error: " +
                                errorMessage +
                                " (" +
                                clientDetailCode +
                                ")");
                            let message = errorMessage;
                            if (!message) {
                                message = "Unknown error";
                            }
                            callbacks.errorCallback(message + " (" + clientDetailCode + ")");
                        },
                    }, cacheBusterStore);
                }
            }
            catch (err) {
                logger.error(LOGPREFIX + "loadTopFrontPageImage: error = " + err);
                callbacks.errorCallback(err.message);
                return;
            }
        }
        else {
            const errorMessage = message
                ? message
                : "Request Failed - Status = " + status;
            callbacks.errorCallback(errorMessage);
        }
    };
    const url = getContentURL() +
        PUBLIC_HOME_DISCOVERY_FILENAME +
        cacheBusterStore.getCacheBuster();
    sendGETRequest(apiCallback, url, cacheBusterStore);
};
export const loadLatestPersonalized = (callbacks, navigate, cacheBusterStore, logger, auth, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
deDupeMap) => {
    if (!auth || !auth.sequence) {
        throw new Error("Missing sequence number in auth - " + JSON.stringify(auth));
    }
    const payload = {
        operation: "getLatestPersonalized",
        sequence: auth.sequence,
    };
    const apiCallback = (ok, status, message, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cacheBusterStore) => {
        var _a;
        logger.log(LOGPREFIX +
            "loadLatestPersonalized: ok = " +
            ok +
            ", status = " +
            status +
            ", message = " +
            message);
        if (!ok || status < 200 || status > 299) {
            logger.error(LOGPREFIX + "status = " + status + ", message = " + message);
            const errorMessage = message
                ? message
                : "Request Failed - Status = " + status;
            callbacks.errorCallback(errorMessage);
            return;
        }
        let response;
        try {
            response = JSON.parse(message);
        }
        catch (err) {
            logger.error(LOGPREFIX + "loadLatestPersonalized: error = " + err);
            callbacks.errorCallback(err.message);
            return;
        }
        if (auth && auth.isSignedIn()) {
            if (response.sequence && response.bearer) {
                auth.setBearerAndSequence(response.bearer, response.sequence);
            }
        }
        const entries = [];
        (_a = response === null || response === void 0 ? void 0 : response.postIds) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
            const parts = entry.split("/");
            const channelOwnerId = parts[1];
            const channelId = parts[2];
            const postId = parts[3];
            if (deDupeMap[postId]) {
                return;
            }
            deDupeMap[postId] = 1;
            entries.push({
                channelOwnerId,
                channelId,
                postId,
            });
        });
        callbacks.okCallback(entries);
        return;
    };
    sendAjaxRequest(apiCallback, "discovery", payload, auth.bearer, cacheBusterStore);
};
