import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
export function TopButtonMenu(props) {
    const navigate = useNavigate();
    const buttonStyle = { paddingLeft: "8px", paddingRight: "8px" };
    const buttons = (_jsx(Stack, Object.assign({ direction: "row", sx: {
            width: "auto",
            display: "flex",
            justifyContent: "center",
            margin: "3px",
            marginBottom: "0",
        } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "outlined", "aria-label": "Basic button group" }, { children: [_jsx(Button, Object.assign({ variant: props.current === "latest" ? "contained" : "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Latest");
                    } }, { children: "Latest" })), _jsx(Button, Object.assign({ variant: props.current === "feed" ? "contained" : "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Feed");
                    } }, { children: "Feed" })), _jsx(Button, Object.assign({ variant: props.current === "channels" ? "contained" : "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Channels");
                    } }, { children: "Channels" })), _jsx(Button, Object.assign({ variant: props.current === "friends" ? "contained" : "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Friends");
                    } }, { children: "Friends" })), _jsx(Button, Object.assign({ variant: props.current === "profile" ? "contained" : "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Profile");
                    } }, { children: "Profile" }))] })) })));
    return buttons;
}
