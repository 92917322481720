import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import getTheme from "./Theme";
import { Avatar, Button, Card, CardActions, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, LinearProgress, Link, Stack, TextField, ThemeProvider, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { useEffect, useRef, useState } from "react";
import { getChannelViewBaseURL, getContentURL } from "./ContentAPI";
import { ContentCard } from "./components/ContentCard";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { scaleAndUploadImages, scaleDownImage, updateTypesBestEffort, } from "./ImageUtils";
import { deletePost, listPosts, loadChannelData, saveChannelData, saveChannelPost, } from "./DataUtils";
import { AddAPhoto, 
//  PersonAdd,
//  Place,
Edit, IosShare, } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { PostCard } from "./components/PostCard";
import { LoadMoreButton } from "./components/LoadMoreButton";
import { UserAvatar } from "./components/UserAvatar";
const cameraImg = "./img/camera_512.jpg";
const questionImg = "./img/question_512.jpg";
const unlockedImg = "./img/unlocked_512.jpg";
const channelsImg = "./img/channels_512.jpg";
export function Feed() {
    const [forceRender, setForceRender] = useState(new Date().getTime());
    const [helpVisible, setHelpVisible] = useState(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [feedDataLoaded, setFeedDataLoaded] = useState(false);
    const [feedTitleText, setFeedTitleText] = useState("");
    const [feedTitleTextModified, setFeedTitleTextModified] = useState(false);
    const [feedTitleTextError, setFeedTitleTextError] = useState("");
    const [feedDescriptionText, setFeedDescriptionText] = useState("Welcome to My Feed!");
    const [feedDescriptionTextError, setFeedDescriptionTextError] = useState("");
    const [feedImageUrl, setFeedImageUrl] = useState(cameraImg);
    const [feedSaving, setFeedSaving] = useState(false);
    const [feedSavedOk, setFeedSavedOk] = useState(false);
    const [feedEverSaved, setFeedEverSaved] = useState(false);
    const [feedSaveError, setFeedSaveError] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    const [feedImageDataUrl, setFeedImageDataUrl] = useState("");
    const [fileInputList, setFileInputList] = useState();
    const hiddenFileInput = useRef(null);
    const feedTitleTextFieldRef = useRef(null);
    const feedDescriptionTextFieldRef = useRef(null);
    const canvasRef = useRef(null);
    const [addPostText, setAddPostText] = useState("");
    const [addPostHelperText, setAddPostHelperText] = useState(undefined);
    const [addPostImageUrl, setAddPostImageUrl] = useState("");
    const [addPostSaving, setAddPostSaving] = useState(false);
    const [addPostSavedOk, setAddPostSavedOk] = useState(false);
    const [addPostSaveError, setAddPostSaveError] = useState(undefined);
    const hiddenFileInputAddPost = useRef(null);
    const [fileInputListAddPost, setFileInputListAddPost] = useState();
    const [addPostImageDataUrl, setAddPostImageDataUrl] = useState("");
    const [postIds, setPostIds] = useState();
    const [hasMorePosts, setHasMorePosts] = useState(false);
    const [permissionsAdultContent, setPermissionsAdultContent] = useState(false);
    const [permissionsRequiresSignIn, setPermissionsRequiresSignIn] = useState(false);
    const [permissionsRequiresFriend, setPermissionsRequiresFriend] = useState(false);
    const [permissionsAllowsComments, setPermissionsAllowsComments] = useState(false);
    //  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string>("");
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    //  const profileLoader = useProfileStore((state) => state);
    const validateAddPostText = (text) => {
        setAddPostSaveError(undefined);
        setAddPostSavedOk(false);
        setAddPostSaving(false);
        if (!text) {
            setAddPostHelperText(undefined);
            return;
        }
        if (text.includes("<")) {
            setAddPostHelperText("Text cannot contain '<' character.");
        }
        else {
            setAddPostHelperText(undefined);
        }
    };
    const validateFeedFields = () => {
        console.log("validateFeedFields - title = " + feedTitleText);
        console.log("validateFeedFields - description = " + feedDescriptionText);
        const titleValue = feedTitleText === null || feedTitleText === void 0 ? void 0 : feedTitleText.trim();
        if (feedTitleTextModified && !titleValue) {
            setFeedTitleTextError("Title cannot be empty.");
        }
        else {
            setFeedTitleTextError(undefined);
        }
        const descValue = feedDescriptionText === null || feedDescriptionText === void 0 ? void 0 : feedDescriptionText.trim();
        if (!descValue) {
            setFeedDescriptionTextError("Description cannot be empty.");
        }
        else {
            setFeedDescriptionTextError(undefined);
        }
        // TODO: Validation
    };
    const loadMorePosts = () => {
        console.log("loadMorePosts");
        if (!postIds || postIds.length <= 0 || !hasMorePosts) {
            console.log("loadMorePosts: No posts exist.");
            return;
        }
        if (!auth || !auth.isSignedIn() || !auth.userId) {
            console.log("not signed in");
            return;
        }
        const lastPostId = postIds.at(postIds.length - 1);
        console.log("loadMorePosts: lastPostId = " + lastPostId);
        setLoadingMore(true);
        listPosts({
            operation: "listPosts",
            userId: auth.userId,
            ownerId: auth.userId,
            channelId: auth.userId,
            postId: lastPostId,
        }, {
            okCallback: (clientDetailCode, data) => {
                console.log("Feed: loadMorePosts: " + data);
                setLoadingMore(false);
                const results = JSON.parse(data);
                if (results === null || results === void 0 ? void 0 : results.postIds) {
                    results.postIds.forEach((entry) => {
                        if (postIds.includes(entry)) {
                            console.error("Feed: loadMorePosts: DUPLICATE: " + entry);
                        }
                        else {
                            console.log("Feed: loadMorePosts: ADDING: " + entry);
                            postIds.push(entry);
                        }
                    });
                }
                if (results === null || results === void 0 ? void 0 : results.hasMorePosts) {
                    setHasMorePosts(true);
                }
                else {
                    setHasMorePosts(false);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                // TODO
                console.log("Feed: listPosts: ERROR = " + errorMessage);
                setLoadingMore(false);
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearer(bearer);
                auth.setSequence(sequence);
            },
        });
        // TODO: Get more post IDs to load.
    };
    const deletePostParent = (basePath, postJson) => {
        console.log("deletePostParent: " + basePath + " " + postJson);
        if (!(auth === null || auth === void 0 ? void 0 : auth.userId)) {
            // TODO: Error
            return;
        }
        deletePost({
            operation: "deletePost",
            userId: auth.userId,
            ownerId: auth.userId,
            channelId: auth.userId,
            postId: postJson,
        }, {
            okCallback: (clientDetailCode) => {
                console.log("deletePost: OK " + clientDetailCode);
                // Force reload
                setPostIds(undefined);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                console.log("deletePost: ERROR " + clientDetailCode + " " + errorMessage);
                // TODO
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearer(bearer);
                auth.setSequence(sequence);
            },
        });
    };
    const dataLoaderCallbacks = {
        okCallback: (clientDetailCode, data) => {
            var _a, _b, _c, _d;
            console.log("Feed: okCallback: " + clientDetailCode + " " + data);
            setHelpVisible(false);
            setPrivacyVisible(false);
            try {
                // Load Channel Data from JSON File
                const dataObj = JSON.parse(data);
                if (dataObj.title) {
                    setFeedTitleText(dataObj.title);
                }
                if (dataObj.description) {
                    setFeedDescriptionText(dataObj.description);
                }
                if ((_a = dataObj.permissions) === null || _a === void 0 ? void 0 : _a.permissionsAdultContent) {
                    setPermissionsAdultContent(true);
                }
                else {
                    setPermissionsAdultContent(false);
                }
                if ((_b = dataObj.permissions) === null || _b === void 0 ? void 0 : _b.permissionsRequiresSignIn) {
                    setPermissionsRequiresSignIn(true);
                }
                else {
                    setPermissionsRequiresSignIn(false);
                }
                if ((_c = dataObj.permissions) === null || _c === void 0 ? void 0 : _c.permissionsRequiresFriend) {
                    setPermissionsRequiresFriend(true);
                }
                else {
                    setPermissionsRequiresFriend(false);
                }
                if ((_d = dataObj.permissions) === null || _d === void 0 ? void 0 : _d.permissionsAllowsComments) {
                    setPermissionsAllowsComments(true);
                }
                else {
                    setPermissionsAllowsComments(false);
                }
                const cb = feedEverSaved ? "?cb=" + new Date().getTime() : "";
                const imageUrl = getContentURL() +
                    "/u/" +
                    auth.userId +
                    "/" +
                    auth.userId +
                    "/feedImage_512.jpg" +
                    cb;
                setFeedDataLoaded(true);
                setForceRender(forceRender + 1);
                setFeedImageUrl(imageUrl);
            }
            catch (err) {
                console.error("Feed.dataLoaderCallbacks: okCallback: error = " + err);
                // TODO
            }
        },
        errorCallback: (clientDetailCode, errorMessage) => {
            console.log("Feed.dataLoaderCallbacks: errorCallback: " +
                clientDetailCode +
                " " +
                errorMessage);
            // TODO
            setHelpVisible(true);
            setPrivacyVisible(true);
            setSettingsVisible(true);
        },
    };
    const loadFeedData = () => {
        if (feedDataLoaded) {
            console.log("loadFeedData - skip");
            return;
        }
        console.log("loadFeedData - start");
        if (!auth || !auth.userId) {
            dataLoaderCallbacks.errorCallback(0, "Missing user ID.");
            return;
        }
        setSettingsVisible(false);
        // While managing self-feed, always reload latest.
        const cb = "" + new Date().getTime();
        const userId = auth.userId;
        loadChannelData("u", userId, userId, "feedData", "json", cb, dataLoaderCallbacks);
    };
    const doPublish = () => {
        const postId = newPostId();
        console.log("doPublish: " + postId);
        const handleError = (errorMessage) => {
            setAddPostSaveError(errorMessage);
            setAddPostSavedOk(false);
            setAddPostSaving(false);
        };
        const handleOk = () => {
            setAddPostSaveError(undefined);
            setAddPostSavedOk(true);
            setAddPostSaving(false);
            setAddPostText("");
            setAddPostImageUrl(undefined);
            setAddPostImageDataUrl("");
            setPostIds(undefined);
        };
        setAddPostSaveError(undefined);
        setAddPostSavedOk(false);
        setAddPostSaving(true);
        if (!auth.userId) {
            console.error("User not signed in.");
            handleError("User is not signed in.");
            return;
        }
        const inputData = {
            text: addPostText,
            imageDataUrl: addPostImageDataUrl,
            userId: auth.userId,
            channelId: auth.userId,
            postId,
            timestamp: new Date().getTime(),
            hasImage: false,
            hasVideo: false,
        };
        if (!(canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current)) {
            console.error("Invalid canvas reference.");
            handleError("Invalid canvas reference.");
            return;
        }
        const canvas = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current;
        const imageFiles = fileInputListAddPost;
        if (!imageFiles || imageFiles.length <= 0) {
            console.log("doPublish: no images");
            inputData.hasImage = false;
            inputData.hasVideo = false;
            saveChannelPost(inputData, {
                okCallback: (clientDetailCode) => {
                    console.log("okCallback: " + clientDetailCode);
                    handleOk();
                    return;
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    handleError(errorMessage + " (" + clientDetailCode + ")");
                    return;
                },
                authCallback: {
                    isSignedIn: () => auth.isSignedIn(),
                    bearer: () => auth.bearer,
                    sequence: () => auth.sequence,
                    setBearerAndSequence: (bearer, sequence) => {
                        auth.setBearer(bearer);
                        auth.setSequence(sequence);
                    },
                },
            });
            return;
        }
        if (imageFiles[0].name.endsWith(".mov") ||
            imageFiles[0].name.endsWith(".mp4")) {
            console.log("doPublish: video upload");
            inputData.hasImage = false;
            inputData.hasVideo = true;
            // TODO: Video upload
            return;
        }
        inputData.hasImage = true;
        inputData.hasVideo = false;
        const partitionId = "u/" + auth.userId + "/" + auth.userId;
        const inputImageData = {
            canvas,
            filesList: imageFiles,
            targetParameters: [
                {
                    contentId: postId + "_1024",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 1024,
                    newHeight: 768,
                },
                {
                    contentId: postId + "_512",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 512,
                    newHeight: 384,
                },
                {
                    contentId: postId + "_256",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 256,
                    newHeight: 256,
                },
            ],
        };
        scaleAndUploadImages(inputImageData, {
            okCallback: (clientDetailCode) => {
                console.log("Feed: Image save done (" +
                    clientDetailCode +
                    "). Saving post data...");
                setFileInputListAddPost(undefined);
                updateTypesBestEffort(auth, partitionId, postId);
                saveChannelPost(inputData, {
                    okCallback: (clientDetailCode) => {
                        console.log("okCallback: " + clientDetailCode);
                        handleOk();
                        return;
                    },
                    errorCallback: (clientDetailCode, errorMessage) => {
                        handleError(errorMessage + " (" + clientDetailCode + ")");
                        return;
                    },
                    authCallback: {
                        isSignedIn: () => auth.isSignedIn(),
                        bearer: () => auth.bearer,
                        sequence: () => auth.sequence,
                        setBearerAndSequence: (bearer, sequence) => {
                            auth.setBearer(bearer);
                            auth.setSequence(sequence);
                        },
                    },
                });
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                handleError(errorMessage + " (" + clientDetailCode + ")");
                return;
            },
            authCallback: {
                isSignedIn: () => auth.isSignedIn(),
                bearer: () => auth === null || auth === void 0 ? void 0 : auth.bearer,
                sequence: () => auth === null || auth === void 0 ? void 0 : auth.sequence,
                userId: () => auth.userId,
                setBearerAndSequence: (bearer, sequence) => {
                    auth.setBearer(bearer);
                    auth.setSequence(sequence);
                },
            },
        });
    };
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                console.log("User NOT logged in - navigating...");
                navigate("/Login");
            }
        }
        if (!feedDataLoaded) {
            loadFeedData();
        }
        return () => { };
    }, [auth, feedDataLoaded]);
    useEffect(() => {
        console.log("useEffect: mount - feedTitleText = " + feedTitleText);
        validateFeedFields();
        return () => {
            console.log("useEffect: unmount - feedTitleText");
        };
    }, [feedTitleText]);
    useEffect(() => {
        console.log("useEffect: mount - feedDescriptionText = " + feedDescriptionText);
        validateFeedFields();
        return () => {
            console.log("useEffect: unmount - feedDescriptionText");
        };
    }, [feedDescriptionText]);
    useEffect(() => {
        console.log("useEffect: mount - feedImageUrl = " + feedImageUrl);
        return () => {
            console.log("useEffect: unmount - feedImageUrl");
        };
    }, [feedImageUrl]);
    useEffect(() => {
        console.log("useEffect: mount - feedSavedOk = " + feedSavedOk);
        if (feedSavedOk) {
            setFeedEverSaved(true);
            setTimeout(() => {
                setFeedSavedOk(false);
            }, 3000);
        }
        return () => {
            console.log("useEffect: unmount - feedSavedOk");
        };
    }, [feedSavedOk]);
    useEffect(() => {
        console.log("useEffect: addPostText = " + addPostText);
        validateAddPostText(addPostText);
        return () => { };
    }, [addPostText]);
    useEffect(() => {
        console.log("useEffect: mount - addPostSavedOk = " + addPostSavedOk);
        if (addPostSavedOk) {
            setAddPostSavedOk(true);
            setTimeout(() => {
                setAddPostSavedOk(false);
                setAddPostText("");
                setAddPostImageUrl(undefined);
            }, 3000);
        }
        return () => {
            console.log("useEffect: unmount - addPostSavedOk");
        };
    }, [addPostSavedOk]);
    useEffect(() => {
        console.log("useEffect: mount - addPostImageDataUrl = " + !!addPostImageDataUrl);
        setAddPostSaveError(undefined);
        setAddPostSavedOk(false);
        setAddPostSaving(false);
        setForceRender(forceRender + 1);
        return () => {
            console.log("useEffect: unmount - addPostImageDataUrl");
        };
    }, [addPostImageDataUrl]);
    useEffect(() => {
        if (feedDataLoaded && auth.userId && !postIds) {
            listPosts({
                operation: "listPosts",
                userId: auth.userId,
                ownerId: auth.userId,
                channelId: auth.userId,
            }, {
                okCallback: (clientDetailCode, data) => {
                    // TODO
                    console.log("Feed: listPosts: " + data);
                    const results = JSON.parse(data);
                    if (results === null || results === void 0 ? void 0 : results.postIds) {
                        setPostIds(results.postIds);
                    }
                    if (results === null || results === void 0 ? void 0 : results.hasMorePosts) {
                        setHasMorePosts(true);
                    }
                    else {
                        setHasMorePosts(false);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    // TODO
                    console.log("Feed: listPosts: ERROR = " + errorMessage);
                },
            }, {
                isSignedIn: () => auth.isSignedIn(),
                bearer: () => auth.bearer,
                sequence: () => auth.sequence,
                setBearerAndSequence: (bearer, sequence) => {
                    auth.setBearer(bearer);
                    auth.setSequence(sequence);
                },
            });
        }
        return () => { };
    }, [feedDataLoaded, postIds]);
    const feedImageFilenameChanged = () => {
        var _a, _b, _c;
        console.log("feedImageFilenameChanged");
        if ((_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputList((_b = hiddenFileInput.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInput.current) === null || _c === void 0 ? void 0 : _c.files[0];
            console.log("feedImageFilenameChanged: filename = " + filename);
            const imageUrl = URL.createObjectURL(filename);
            console.log("feedImageFilenameChanged: imageUrl = " + imageUrl);
            setFeedImageUrl(imageUrl);
            const img = new Image();
            img.onload = () => {
                console.log("New feed image loaded.");
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, 720, 720);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    console.log("imageDataUrl = " + imageDataUrl);
                    console.log("imageDataUrl len = " + imageDataUrl.length);
                    setFeedImageDataUrl(imageDataUrl);
                    setForceRender(forceRender + 1);
                }
            };
            img.onerror = (error) => {
                console.error("Unable to load image : " + imageUrl + " - " + error);
            };
            img.crossOrigin = "anonymous";
            img.src = imageUrl;
        }
    };
    const addPostImageFilenameChanged = () => {
        var _a, _b, _c;
        console.log("addPostImageFilenameChanged");
        if ((_a = hiddenFileInputAddPost.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputListAddPost((_b = hiddenFileInputAddPost.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInputAddPost.current) === null || _c === void 0 ? void 0 : _c.files[0];
            console.log("addPostImageFilenameChanged: filename = " + filename.name);
            console.log("addPostImageFilenameChanged: file type = " + filename.type);
            console.log("addPostImageFilenameChanged: file size = " + filename.size);
            if (filename.type != "image/jpeg" &&
                filename.type != "image/png" &&
                filename.type != "video/mp4" &&
                filename.type != "video/quicktime") {
                // TODO: ERROR
                return;
            }
            const mediaUrl = URL.createObjectURL(filename);
            console.log("addPostImageFilenameChanged: mediaUrl = " + mediaUrl);
            if (filename.type == "video/mp4") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            if (filename.type == "video/quicktime") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            setAddPostImageUrl(mediaUrl);
            const img = new Image();
            img.onload = () => {
                console.log("New add post image loaded.");
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, 1024, 1024);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    console.log("imageDataUrl = " + imageDataUrl);
                    console.log("imageDataUrl len = " + imageDataUrl.length);
                    setAddPostImageDataUrl(imageDataUrl);
                }
            };
            img.onerror = (error) => {
                console.error("Unable to load image : " + mediaUrl + " - " + error);
                // TODO: ERROR
            };
            img.crossOrigin = "anonymous";
            img.src = mediaUrl;
        }
    };
    const saveFeedData = () => {
        console.log("saveFeedData");
        let title = feedTitleText;
        if (!feedTitleTextModified && !title) {
            title = auth.displayName + "'s Feed";
        }
        if (!title || !title.trim()) {
            setFeedSaveError("Feed title is not set.");
            return;
        }
        if (!feedDescriptionText || !feedDescriptionText.trim()) {
            setFeedSaveError("Feed description is not set.");
            return;
        }
        if (!auth.isSignedIn() || !auth.userId) {
            setFeedSaveError("Feed description is not set.");
            return;
        }
        const input = {
            title: title,
            description: feedDescriptionText,
            imageDataUrl: feedImageDataUrl,
            userId: auth.userId,
            channelId: auth.userId,
            permissions: {
                permissionsAdultContent,
                permissionsRequiresSignIn,
                permissionsRequiresFriend,
                permissionsAllowsComments,
            },
        };
        setFeedSaving(true);
        setFeedSaveError("");
        setFeedSavedOk(false);
        const saveChannelImage = () => {
            const canvas = canvasRef.current;
            if (!canvas) {
                console.error("no canvas");
                return;
            }
            const files = fileInputList;
            if (!files || files.length <= 0) {
                console.log("no file list");
                setFeedSaving(false);
                setFeedSavedOk(true);
                return;
            }
            console.log("saveChannelImage: files = " + JSON.stringify(files));
            const input = {
                canvas,
                filesList: files,
                targetParameters: [
                    {
                        contentId: "feedImage_1024",
                        fileType: "jpg",
                        partition: "u/" + auth.userId + "/" + auth.userId,
                        newWidth: 1024,
                        newHeight: 768,
                    },
                    {
                        contentId: "feedImage_512",
                        fileType: "jpg",
                        partition: "u/" + auth.userId + "/" + auth.userId,
                        newWidth: 512,
                        newHeight: 384,
                    },
                    {
                        contentId: "feedImage_256",
                        fileType: "jpg",
                        partition: "u/" + auth.userId + "/" + auth.userId,
                        newWidth: 256,
                        newHeight: 256,
                    },
                ],
            };
            const callbacks = {
                okCallback: (clientDetailCode) => {
                    console.log("Multi-file Upload OK: " + clientDetailCode);
                    updateTypesBestEffort(auth, "u/" + auth.userId + "/" + auth.userId, "feedImage_");
                    setFeedSaving(false);
                    setFeedSavedOk(true);
                    setFeedSaveError("");
                    setFileInputList(undefined);
                    setTimeout(() => {
                        setFeedSavedOk(false);
                    }, 2500);
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    console.log("Multi-file Upload Failed: " +
                        clientDetailCode +
                        " " +
                        errorMessage);
                    setFeedSaving(false);
                    setFeedSavedOk(false);
                    setFeedSaveError("Unable to upload image. " + errorMessage);
                },
                authCallback: {
                    isSignedIn: () => {
                        return auth.isSignedIn();
                    },
                    bearer: () => {
                        return auth.bearer;
                    },
                    sequence: () => {
                        return auth.sequence;
                    },
                    userId: () => {
                        return auth.userId;
                    },
                    setBearerAndSequence: (bearer, sequence) => {
                        auth.setSequence(sequence);
                        auth.setBearer(bearer);
                    },
                },
            };
            scaleAndUploadImages(input, callbacks);
        };
        saveChannelData(input, {
            okCallback: (clientDetailCode) => {
                console.log("okCallback: " + clientDetailCode);
                saveChannelImage();
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                console.log("errorCallback: " + clientDetailCode + " " + errorMessage);
                setFeedSaving(false);
                setFeedSaveError(errorMessage + " (" + clientDetailCode + ")");
            },
            authCallback: {
                isSignedIn: () => {
                    return auth.isSignedIn();
                },
                bearer: () => {
                    return auth.bearer;
                },
                sequence: () => {
                    return auth.sequence;
                },
                setBearerAndSequence: (bearer, sequence) => {
                    auth.setBearer(bearer);
                    auth.setSequence(sequence);
                },
            },
        });
    };
    const addPostMediaClicker = () => {
        console.log("add post - upload - click");
        if (hiddenFileInputAddPost.current) {
            console.log("add post - upload - click: Click: hiddenFileInput");
            hiddenFileInputAddPost.current.click();
        }
    };
    const newPostId = () => {
        const timeIndex = 9999999999999 - new Date().getTime();
        const newPostId = timeIndex + "-" + uuidv4();
        return newPostId;
    };
    const topLinkCard = (_jsx(Button, Object.assign({ href: getChannelViewBaseURL() + auth.displayName, target: "_blank", variant: "contained", sx: { marginLeft: "5px", marginRight: "5px", marginTop: "15px" } }, { children: "Switch to Public View" })));
    const privacyCardText = (_jsxs(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main, sx: { textAlign: "left", padding: "10px" }, gutterBottom: true }, { children: ["Feed is public. Anyone can see it. Do not post content you do not want the world to see.", _jsx("br", {}), _jsx("br", {}), "If you want to post private invite-only content, then create a", " ", _jsx(Link, Object.assign({ href: "/Channels" }, { children: "Private Channel" }), "privacy.channels.link"), ".", _jsx("br", {}), _jsx("br", {}), "You may also review our", " ", _jsx(Link, Object.assign({ href: "/privacy.txt", target: "_blank" }, { children: "Privacy Policy" }), "privacy.policy.link"), "."] }), "card.privacy.text.typography"));
    const privacyCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    navigate("/Channels");
                } }, { children: "Channels" }), "privacy.channels.button"), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                    setPrivacyVisible(false);
                } }, { children: "Hide" }), "privacy.visible.button")] })));
    const privacyCard = (_jsx(ContentCard, { keyStr: "cards.privacy", title: "Privacy Notice", mediaUrl: unlockedImg, childComponent: privacyCardText, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, actions: privacyCardActions }, "cards.privacy"));
    const helpButtonDiv = (_jsx("div", { children: _jsx(Link, Object.assign({ href: "#", onClick: () => {
                console.log("help link - onClick");
                setHelpVisible(true);
                setPrivacyVisible(true);
            } }, { children: _jsx(Typography, Object.assign({ variant: "h6", color: "primary", sx: { margin: "5px" }, gutterBottom: true }, { children: "Show Help & Privacy Notice" })) }), "help.visible.link") }));
    const feedUrl = getContentURL() + "/@" + auth.displayName;
    const feedLink = (_jsx(Link, Object.assign({ href: feedUrl, target: "_blank", fontSize: "18px" }, { children: feedUrl })));
    const helpCardText = (_jsxs(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main, sx: { textAlign: "left", padding: "10px" }, gutterBottom: true }, { children: ["Feed is your own personal blog space where you share content. Set a title, description, and image for your feed page. Post articles, images and videos. Share your feed link.", _jsx("br", {}), _jsx("br", {}), "Your feed link is:", _jsx("br", {}), feedLink, _jsx("br", {}), _jsx("br", {}), "Welcome to your Feed!"] }), "card.help.text.typography"));
    const helpCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    window.open(getContentURL() + "/@" + auth.displayName);
                } }, { children: "View My Feed" }), "help.preview.button"), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                    if (helpVisible) {
                        setHelpVisible(false);
                    }
                    else {
                        setHelpVisible(true);
                    }
                } }, { children: "Hide" }), "help.visible.button")] })));
    const helpCard = (_jsx(ContentCard, { keyStr: "cards.help", title: "Feed - What is this?", mediaUrl: questionImg, childComponent: helpCardText, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, actions: helpCardActions }, "cards.help"));
    const addPostSavingProgress = (_jsxs("div", Object.assign({ style: { width: "100%" } }, { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] })));
    let settingsProgress = (_jsxs("div", { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] }));
    if (!feedSaving) {
        settingsProgress = _jsx("div", {});
        if (feedSaveError) {
            settingsProgress = (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "SAVE FAILED" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: feedSaveError }))] }, "feed.saving.error.content") }), "feed.saving.error"));
        }
        else if (feedSavedOk) {
            settingsProgress = (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", padding: "4" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "SAVED" })), _jsx(Typography, Object.assign({ variant: "body2", color: "black" }, { children: "Your changes have been saved." }))] }, "feed.saved.ok.card.content") }), "feed.saved.ok.card"));
        }
    }
    const settingsCardContent = (_jsxs("div", Object.assign({ style: { padding: "10px" } }, { children: [_jsxs(Typography, Object.assign({ variant: "body1", color: "black", textAlign: "left" }, { children: ["Set the title, description, and image. These will appear at the top of your Feed page.", _jsx("br", {}), _jsx("br", {})] })), _jsx(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: "TITLE" }), "cards.settings.cardcontent.type.title"), _jsx(TextField, { ref: feedTitleTextFieldRef, variant: "outlined", label: "Title", size: "small", sx: { width: "300px", marginLeft: "10px", marginRight: "10px" }, color: "black", value: feedTitleText ? feedTitleText : auth.displayName + "'s Feed", onChange: (event) => {
                    const value = event.target.value;
                    console.log("feed title onChange - " + value);
                    setFeedTitleText(value);
                    setFeedSavedOk(false);
                    setFeedTitleTextModified(true);
                }, error: !!feedTitleTextError, helperText: feedTitleTextError, disabled: feedSaving }, "cards.settings.cardcontent.textfield.title"), _jsxs(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: [_jsx("br", {}), "DESCRIPTION"] }), "cards.settings.cardcontent.type.description"), _jsx(TextField, { id: "outlined-multiline-static", label: "Description", multiline: true, rows: 5, fullWidth: true, value: feedDescriptionText ? feedDescriptionText : "Welcome to my Feed!", color: "black", ref: feedDescriptionTextFieldRef, onChange: (event) => {
                    const value = event.target.value;
                    console.log("feed desc onChange - " + value);
                    setFeedDescriptionText(value);
                    setFeedSavedOk(false);
                }, error: !!feedDescriptionTextError, helperText: feedDescriptionTextError, disabled: feedSaving }, "cards.settings.cardcontent.textfield.description"), _jsxs(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: [_jsx("br", {}), "PERMISSIONS"] }), "cards.settings.cardcontent.type.permissions"), _jsx(Stack, Object.assign({ direction: "column", sx: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                } }, { children: _jsx(FormControl, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsxs(FormGroup, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(FormControlLabel, { label: "Feed contains adult content.", control: _jsx(Checkbox, { checked: permissionsAdultContent, onChange: (event) => {
                                        console.log("permissionsAdultContent - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAdultContent(event.target.checked);
                                        if (event.target.checked) {
                                            setPermissionsRequiresSignIn(true);
                                        }
                                    } }) }), _jsx(FormControlLabel, { label: "Feed can be viewed ONLY by signed-in users.", control: _jsx(Checkbox, { checked: permissionsRequiresSignIn, onChange: (event) => {
                                        console.log("permissionsRequiresSignIn - checked/unchecked: " +
                                            event.target.checked);
                                        if (permissionsAdultContent) {
                                            setPermissionsRequiresSignIn(true);
                                        }
                                        else {
                                            setPermissionsRequiresSignIn(event.target.checked);
                                        }
                                    } }) }), _jsx(FormControlLabel, { label: "Feed can be viewed ONLY by friends.", control: _jsx(Checkbox, { checked: permissionsRequiresFriend, onChange: (event) => {
                                        console.log("permissionsRequiresFriend - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsRequiresFriend(event.target.checked);
                                    } }) }), _jsx(FormControlLabel, { label: "Viewers can comment and like Feed posts.", control: _jsx(Checkbox, { checked: permissionsAllowsComments, onChange: (event) => {
                                        console.log("permissionsAllowsComments - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAllowsComments(event.target.checked);
                                    } }) })] })) })) })), settingsProgress] }), "settings.card.content." + forceRender));
    let settingsCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    saveFeedData();
                } }, { children: "Save" }), "settings.card.action.save.button"), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                    setSettingsVisible(false);
                } }, { children: "Hide" }), "settings.card.action.hide.button")] }), "settings.card.actions"));
    if (feedSaving) {
        settingsCardActions = _jsx(CardActions, {});
    }
    let mediaClicker = undefined;
    if (!feedSaving) {
        mediaClicker = () => {
            console.log("settings - upload - click");
            if (hiddenFileInput.current) {
                console.log("settings - upload - click: Click: hiddenFileInput");
                hiddenFileInput.current.click();
            }
        };
    }
    const settingsCard = (_jsx(ContentCard, { keyStr: "settings.card." + forceRender, title: "Feed Settings", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, childComponent: settingsCardContent, actions: settingsCardActions, mediaUrl: feedImageUrl, mediaFallbackUrl: cameraImg, onMediaClick: mediaClicker, onMediaLoad: () => {
            console.log("Settings Card - media loaded OK");
        }, onMediaLoadError: () => {
            console.log("Settings Card - media load failed");
        }, maxWidth: 0.5, overlayIcon: _jsx(Avatar, Object.assign({ sx: {
                marginLeft: "auto",
                backgroundColor: getTheme().palette.violet.light,
            } }, { children: _jsx(Edit, {}) }), "settings.overlay.edit.button") }, "settings.card." + forceRender));
    const makeSettingsVisible = () => {
        setSettingsVisible(true);
    };
    const channelCardActions = (_jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: makeSettingsVisible }, { children: "Edit" })) })));
    const userAvatar = (_jsx(UserAvatar, { userId: auth.userId ? auth.userId : "", keyStr: auth.userId + ".avatar", onUserClicked: () => {
            navigate("/ProfileView?userId=" + auth.userId);
        }, userName: "" }, auth.userId + ".avatar"));
    const channelCardChildContent = (_jsxs(Typography, Object.assign({ whiteSpace: "pre-line", variant: "body1", color: "#444444", sx: { textAlign: "left", padding: "10px", wordBreak: "break-word" } }, { children: [feedDescriptionText ? feedDescriptionText : "", (permissionsAdultContent ||
                permissionsAllowsComments ||
                permissionsRequiresFriend ||
                permissionsRequiresSignIn) && (_jsxs("div", Object.assign({ style: {
                    padding: 0,
                    margin: 0,
                    paddingTop: "15px",
                    paddingBottom: "10px",
                    color: "#999999",
                    fontSize: "small",
                } }, { children: [_jsx(Divider, {}), permissionsAllowsComments && (_jsxs("span", { children: ["- Viewers can comment and like Feed posts.", _jsx("br", {})] })), permissionsRequiresSignIn && (_jsxs("span", { children: ["- Feed can be viewed ONLY by signed-in users.", _jsx("br", {})] })), permissionsRequiresSignIn && (_jsxs("span", { children: ["- Feed can be viewed ONLY by friends.", _jsx("br", {})] })), permissionsAdultContent && (_jsxs("span", { children: ["- Feed contains adult content.", _jsx("br", {})] }))] })))] }), "channel.card." + feedImageUrl + forceRender + "-child-typography"));
    const channelCard = (_jsx(ContentCard, { keyStr: "channel.card." + feedImageUrl + forceRender, title: feedTitleText ? feedTitleText : auth.displayName + "'s Feed", titleAvatar: userAvatar, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, mediaUrl: feedImageUrl, mediaFallbackUrl: channelsImg, onMediaLoad: () => {
            console.log("Channel Card - media loaded OK");
        }, onMediaLoadError: () => {
            console.log("Channel Card - media load failed");
        }, maxWidth: 0.5, actions: channelCardActions, childComponent: channelCardChildContent }, "channel.card." + feedImageUrl + forceRender));
    const addPostCardContent = (_jsxs(Stack, Object.assign({ style: { padding: "10px" } }, { children: [_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static", label: "Write your post here...", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add post text onChange - " + value);
                    setAddPostText(value);
                }, error: !!addPostHelperText, helperText: addPostHelperText, disabled: addPostSaving, value: addPostText }, "cards.addpost.cardcontent.textfield"), _jsxs(Stack, Object.assign({ direction: "row", width: "100%", sx: { display: "flex", justifyContent: "center", marginTop: "5px" }, spacing: 1 }, { children: [addPostSaving && addPostSavingProgress, !addPostSaving && (_jsx(Button, Object.assign({ variant: addPostImageUrl ? "outlined" : "contained", endIcon: _jsx(AddAPhoto, {}), onClick: () => {
                            addPostMediaClicker();
                        } }, { children: "ADD PHOTO" }), "cards.addpost.button.photo"))] }), "add-post-stack-2"), addPostSaveError && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "SAVE FAILED" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: addPostSaveError }))] }, "add-post-error-card-content") }), "add-post-error-card")), addPostSavedOk && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "SAVED" })), _jsx(Typography, Object.assign({ variant: "body2", color: "black" }, { children: "Your changes have been saved." }))] }, "add-post-saved-ok-card-content") }), "add-post-saved-ok-card"))] }), "add-post-stack-" + forceRender));
    const addPostCardActions = (_jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center", padding: 0, margin: 0 } }, { children: !addPostSaving && (_jsx(Button, Object.assign({ endIcon: _jsx(IosShare, {}), variant: "contained", onClick: () => {
                doPublish();
            }, sx: { margin: "0.5em" }, disabled: !!addPostHelperText ||
                (!addPostText &&
                    (!fileInputListAddPost || fileInputListAddPost.length <= 0)) }, { children: "Publish" }), "cards.addpost.button.publish")) }), "add-post-card-actions"));
    const addPostCard = (_jsx(ContentCard, { keyStr: "content.card.addpost", title: "New Post", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: addPostCardContent, actions: addPostCardActions, mediaUrl: addPostImageDataUrl }, "content.card.addpost"));
    const cardsList = [];
    if (helpVisible) {
        cardsList.push(helpCard);
    }
    if (privacyVisible) {
        cardsList.push(privacyCard);
    }
    if (settingsVisible) {
        cardsList.push(settingsCard);
    }
    else {
        cardsList.push(addPostCard);
        cardsList.push(channelCard);
    }
    if (auth.userId && postIds && postIds.length > 0) {
        for (let i = 0; i < postIds.length; ++i) {
            const entry = postIds[i];
            const basePath = getContentURL() + "/u/" + auth.userId + "/" + auth.userId;
            const postId = entry.substring(0, entry.lastIndexOf("."));
            cardsList.push(_jsx(PostCard, { ownerId: auth.userId, channelId: auth.userId, postId: postId, keyStr: entry + forceRender, basePath: basePath, postJson: entry, enableEditButtons: true, deletePost: deletePostParent, onClick: () => {
                    navigate("/PostView?ownerId=" +
                        auth.userId +
                        "&channelId=" +
                        auth.userId +
                        "&postId=" +
                        entry.substring(0, entry.lastIndexOf(".")));
                } }, entry + forceRender));
        }
    }
    const columnStack = _jsx(ContentCardStacks, { cards: cardsList }, "cards.stack");
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [_jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInput, style: { display: "none" }, onChange: feedImageFilenameChanged }, "feed-hidden-file-input"), _jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInputAddPost, style: { display: "none" }, onChange: addPostImageFilenameChanged }, "add-post-hidden-file-input"), _jsx("canvas", { ref: canvasRef, style: { display: "none" } }, "canvas"), _jsx(Typography, Object.assign({ variant: "h5", component: "div", color: "primary", gutterBottom: true, sx: { textAlign: "center" } }, { children: "Feed" }), "page.title"), columnStack, hasMorePosts && (_jsx("div", Object.assign({ style: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                } }, { children: _jsx(LoadMoreButton, { onClick: loadMorePosts, loading: loadingMore }) }))), topLinkCard, !helpVisible && helpButtonDiv] }), "content"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }, maxWidth: false }, { children: content }), "feed.container." + forceRender), _jsx(Footer, {})] })) }), "page.div." + forceRender));
    return output;
}
