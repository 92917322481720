import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Card, CardContent, Container, Stack, ThemeProvider, Typography, } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
const backgroundImg = "./img/wordcloud_512.jpg";
const videoURL = "./background.mov";
export function About() {
    const layout = getLayoutInfo();
    const backgroundVideo = (_jsxs("video", Object.assign({ id: "background-video", loop: true, autoPlay: true, style: {
            textAlign: "center",
            verticalAlign: "middle",
            width: layout.videoWidth,
            display: "inline-flex",
        }, poster: backgroundImg }, { children: [_jsx("source", { src: videoURL, type: "video/quicktime" }), _jsx("source", { src: videoURL, type: "video/mp4" }), _jsx("source", { src: videoURL, type: "video/ogg" })] }), "background-video"));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: { textAlign: "center", width: "100%" } }, { children: "About Us" })), _jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFFFFF",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsx(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: _jsxs(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Pardon our mess" })), _jsx(EngineeringIcon, { fontSize: "large" }), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                    textAlign: "left",
                                    paddingLeft: layout.cardContentPadding,
                                    paddingRight: layout.cardContentPadding,
                                } }, { children: "Welcome to the MediaShare.link social media network. You may find some features are missing or coming soon. We appreciate your patience. MediaShare.link is an unfunded project built by a single software engineer. New features will be added as available. We hope you enjoy the capabilities that work. The word cloud below represents our vision for our completed site. We appreciate you joining us on this journey. Thank you for your patience." })), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Welcome to the MediaShare.link social media network!" })), _jsx("br", {}), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "caption", color: "violet", fontSize: "medium" }, { children: "Our Vision" })), _jsx("div", Object.assign({ style: {
                                    width: "100%",
                                    height: "100%",
                                    display: "inline-block",
                                    textAlign: "center",
                                } }, { children: backgroundVideo }))] })) })) }))] })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF", padding: 0, margin: 0 } }, { children: content })), _jsx(Footer, {})] })) })));
    return output;
}
