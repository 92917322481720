import { sendAjaxRequest, sendGETRequest } from "./API";
import { getContentURL, getUploadURL, } from "./ContentAPI";
/* TODO: Save for messaging
import {
  RsaImportableKey,
  RawRsaKeyringWebCrypto,
  buildClient,
  CommitmentPolicy,
} from '@aws-crypto/client-browser'

const { encrypt, decrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
)

const publicRsaJwkKey: RsaImportableKey = {
  alg: 'RSA-OAEP-256',
  e: 'AQAB',
  ext: true,
  key_ops: ['wrapKey'],
  kty: 'RSA',
  n: '6k_jrxg7mpz7CzgAr6eRqJr1VlvjJ9uQY71hadkDZkLLZHiMl7hz73lqq3w2MfHCa3Pf3BVo5TCXGYuxKOlPb7bH0WWpMeAzOKR_X27UqfA8MBVGb4YO5HXqw0jup8-I-Zi3CQAmP87uE6GDuh7xzeAcwpGD5xE0N74-uWq3YS92PFHCavtryx-ad9VGTgfAbkV3k1-RSxIiZjzbAt3exBAn5EjMfF6FMI70_HYqO-5xGv_aAPSa1OMc_buK5QACN7gmFwqHBzw98v93iyGUc4_XJNL-jPzKNP4AT1zMc6p6RxF3SYytNq7iXIjUmm-oY8fvCSmT1F13XKdzv7DLOw',
}

const privateKey = RawRsaKeyringWebCrypto.importPrivateKey(
  privateRsaJwKKey
)
const publicKey = RawRsaKeyringWebCrypto.importPublicKey(
  publicRsaJwkKey
)

const keyNamespace = 'HSM_01'
const keyName = 'RSA_2048_06'

const keyring = new RawRsaKeyringWebCrypto({
  keyName,
  keyNamespace,
  publicKey,
  privateKey,
})
*/
export const DATA_UTIL_CLIENT_OK = 20000;
export const DATA_UTIL_CLIENT_SKIP_IMAGE_UPLOAD = 20001;
export const DATA_UTIL_CLIENT_ERROR_MISSING_BEARER = 20002;
export const DATA_UTIL_CLIENT_ERROR_MISSING_SEQUENCE = 20003;
export const DATA_UTIL_CLIENT_ERROR_GET_IMAGE_URL_FAILED = 20004;
export const DATA_UTIL_CLIENT_ERROR_GET_JSON_URL_FAILED = 20005;
export const DATA_UTIL_CLIENT_ERROR_IMAGE_UPLOAD_FAILED = 20006;
export const DATA_UTIL_CLIENT_ERROR_DATA_UPLOAD_FAILED = 20007;
export const DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED = 20008;
export const DATA_UTIL_CLIENT_ERROR_DELETE_POST_FAILED = 20009;
export const DATA_UTIL_CLIENT_ERROR_ENCRYPTION_FAILED = 20010;
/*

export const encryptChannelDataInput = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  input: ChannelDataInput,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publicKey: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  privateKey: string,
): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    // TODO: Encrypt data
    // TODO: Add encrypted private key to content
    console.log('TODO: Encrypt Channel Data');

    const { result } = encrypt(keyring, plaintext);

//    importPublicKeyAndEncrypt(publicKey, input.description).then(() => {
//      console.log('importPublicKeyAndEncrypt: returned OK');
  //    resolve();
    //});
  });
};
*/
export const saveChannelPost = (input, callbacks) => {
    const bearer = callbacks.authCallback.bearer();
    if (!bearer) {
        callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "Missing access token.");
        return;
    }
    const sequence = callbacks.authCallback.sequence();
    if (!sequence) {
        callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_SEQUENCE, "Missing access token sequence number.");
        return;
    }
    const step2OKCallback = (clientDetailCode) => {
        console.log("DataUtils.step.2.a: ok - " + clientDetailCode);
        // Step 3 - Get Presigned URL to Put JSON Data
        const getUploadURLStep3Callback = (ok, status, response) => {
            // Step 3.b - Check Step 3 Results
            if (!ok) {
                // Step 3.b - Failed
                console.log("DataUtils.step.3.b: failed - status = " + status);
                let errorMessage = "Unable to get data writer URL. (" + status + ").";
                let clientDetailCode = DATA_UTIL_CLIENT_ERROR_GET_JSON_URL_FAILED;
                if (response) {
                    if (response.error) {
                        errorMessage = response.error;
                    }
                    if (response.clientDetailCode) {
                        clientDetailCode = response.clientDetailCode;
                    }
                }
                callbacks.errorCallback(clientDetailCode, errorMessage);
                return;
            }
            else {
                // Step.3.b - Success
                console.log("DataUtils.step.3.b: ok");
                const step4OKCallback = (clientDetailCode) => {
                    console.log("DataUtils.step.4.a: ok - " + clientDetailCode);
                    callbacks.okCallback(DATA_UTIL_CLIENT_OK);
                };
                if (response) {
                    callbacks.authCallback.setBearerAndSequence(response.bearer, response.sequence);
                }
                console.log("DataUtils.step.3.b: ok - url = " + (response === null || response === void 0 ? void 0 : response.url));
                const dataUploadUrl = response === null || response === void 0 ? void 0 : response.url;
                if (!dataUploadUrl) {
                    callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_GET_JSON_URL_FAILED, "Unable to get data writer URL; missing result.");
                    return;
                }
                // Step.3.b. - Complete
                // Step 4 - Upload Application Data
                input.imageDataUrl = undefined;
                const data = JSON.stringify(input);
                uploadApplicationData(dataUploadUrl, data, step4OKCallback, (clientDetailCode, errorMessage) => {
                    console.error("DataUtils.step.4.a: failed - " +
                        clientDetailCode +
                        " " +
                        errorMessage);
                    callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_DATA_UPLOAD_FAILED, "Failed to save data. " + errorMessage);
                    return;
                });
                return;
            }
        };
        const bearer = callbacks.authCallback.bearer();
        if (!bearer) {
            callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "Missing access token.");
            return;
        }
        const sequence = callbacks.authCallback.sequence();
        if (!sequence) {
            callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_SEQUENCE, "Missing access token sequence number.");
            return;
        }
        getUploadURL(getUploadURLStep3Callback, input.userId, input.postId, "u/" + input.userId + "/" + input.userId, "json", bearer, sequence);
    };
    step2OKCallback(DATA_UTIL_CLIENT_SKIP_IMAGE_UPLOAD);
};
export const saveChannelData = (input, callbacks) => {
    const bearer = callbacks.authCallback.bearer();
    if (!bearer) {
        callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "Missing access token.");
        return;
    }
    const sequence = callbacks.authCallback.sequence();
    if (!sequence) {
        callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_SEQUENCE, "Missing access token sequence number.");
        return;
    }
    const step2OKCallback = (clientDetailCode) => {
        console.log("DataUtils.step.2.a: ok - " + clientDetailCode);
        // Step 3 - Get Presigned URL to Put JSON Data
        const getUploadURLStep3Callback = (ok, status, response) => {
            // Step 3.b - Check Step 3 Results
            if (!ok) {
                // Step 3.b - Failed
                console.log("DataUtils.step.3.b: failed - status = " + status);
                let errorMessage = "Unable to get data writer URL. (" + status + ").";
                let clientDetailCode = DATA_UTIL_CLIENT_ERROR_GET_JSON_URL_FAILED;
                if (response) {
                    if (response.error) {
                        errorMessage = response.error;
                    }
                    if (response.clientDetailCode) {
                        clientDetailCode = response.clientDetailCode;
                    }
                }
                callbacks.errorCallback(clientDetailCode, errorMessage);
                return;
            }
            else {
                // Step.3.b - Success
                console.log("DataUtils.step.3.b: ok");
                const step4OKCallback = (clientDetailCode) => {
                    console.log("DataUtils.step.4.a: ok - " + clientDetailCode);
                    callbacks.okCallback(DATA_UTIL_CLIENT_OK);
                };
                if (response) {
                    callbacks.authCallback.setBearerAndSequence(response.bearer, response.sequence);
                }
                console.log("DataUtils.step.3.b: ok - url = " + (response === null || response === void 0 ? void 0 : response.url));
                const dataUploadUrl = response === null || response === void 0 ? void 0 : response.url;
                if (!dataUploadUrl) {
                    callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_GET_JSON_URL_FAILED, "Unable to get data writer URL; missing result.");
                    return;
                }
                // Step.3.b. - Complete
                // Step 4 - Upload Application Data
                input.imageDataUrl = undefined;
                const data = JSON.stringify(input);
                uploadApplicationData(dataUploadUrl, data, step4OKCallback, (clientDetailCode, errorMessage) => {
                    console.error("DataUtils.step.4.a: failed - " +
                        clientDetailCode +
                        " " +
                        errorMessage);
                    callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_DATA_UPLOAD_FAILED, "Failed to save data. " + errorMessage);
                    return;
                });
                return;
            }
        };
        const bearer = callbacks.authCallback.bearer();
        if (!bearer) {
            callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "Missing access token.");
            return;
        }
        const sequence = callbacks.authCallback.sequence();
        if (!sequence) {
            callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_SEQUENCE, "Missing access token sequence number.");
            return;
        }
        getUploadURL(getUploadURLStep3Callback, input.userId, "feedData", "u/" + input.userId + "/" + input.userId, "json", bearer, sequence);
    };
    step2OKCallback(DATA_UTIL_CLIENT_SKIP_IMAGE_UPLOAD);
};
export const uploadApplicationData = (s3UploadUrl, data, okCallback, errorCallback) => {
    console.log("uploadApplicationData: uploadUrl = " + s3UploadUrl + ", data = " + data);
    const blob = new Blob([data], {
        type: "application/json",
    });
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/octet-stream");
    const request = new Request(s3UploadUrl, {
        method: "PUT",
        body: blob,
        headers: myHeaders,
    });
    fetch(request)
        .then(function (res) {
        console.log("uploadImageData: RESULT = " + JSON.stringify(res));
        okCallback(DATA_UTIL_CLIENT_OK); // TODO
    })
        .catch(function (err) {
        console.error("uploadImageData: ERROR = " + err);
        errorCallback(DATA_UTIL_CLIENT_ERROR_DATA_UPLOAD_FAILED, "Failed to save data. " + err);
    });
};
export const loadChannelData = (partitionId, ownerId, channelId, contentId, fileType, cacheBuster, callbacks) => {
    console.log("loadChannelData");
    const path = getContentURL() +
        "/" +
        partitionId +
        "/" +
        ownerId +
        "/" +
        channelId +
        "/" +
        contentId +
        "." +
        fileType +
        "?cb=" +
        cacheBuster;
    console.log("loadChannelData: path = " + path);
    loadChannelDataFromURL(path, callbacks);
};
export const loadChannelDataFromURL = (url, callbacks) => {
    console.log("loadChannelDataFromURL: url = " + url);
    const apiCallback = (ok, status, message) => {
        console.log("loadChannelDataFromURL: " + ok + " " + status + " " + message);
        if (ok && status >= 200 && status < 300) {
            callbacks.okCallback(DATA_UTIL_CLIENT_OK, message);
        }
        else {
            callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED, message);
        }
    };
    sendGETRequest(apiCallback, url);
};
export const listPosts = (input, callbacks, authCallback) => {
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("listPosts: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("listPosts: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("listPosts: apiCallback OK - bearer and sequence set");
                authCallback.setBearerAndSequence(obj.bearer, obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            callbacks.okCallback(obj.clientDetailCode, message);
        }
        else {
            console.error("listPosts: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "channel";
    const payload = input;
    payload.sequence = authCallback.sequence();
    console.log("listPosts: " + JSON.stringify(payload));
    sendAjaxRequest(apiCallback, path, payload, authCallback.bearer());
};
export const deletePost = (input, callbacks, authCallback) => {
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("deletePost: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("deletePost: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("deletePost: apiCallback OK - bearer and sequence set");
                authCallback.setBearerAndSequence(obj.bearer, obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            callbacks.okCallback(obj.clientDetailCode, message);
        }
        else {
            console.error("deletePost: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_DELETE_POST_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "channel";
    const payload = input;
    payload.sequence = authCallback.sequence();
    sendAjaxRequest(apiCallback, path, payload, authCallback.bearer());
};
