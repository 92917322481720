import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDeviceInfo } from "./DeviceInfo";
import Copyright from "./Copyright";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "./Theme";
import PortraitIcon from "@mui/icons-material/PhoneAndroid";
import LandscapeIcon from "@mui/icons-material/StayCurrentLandscape";
import DesktopIcon from "@mui/icons-material/LaptopChromebook";
import { Link, Stack, Tooltip } from "@mui/material";
import { getSiteBaseURL } from "./ContentAPI";
export default function Footer(props) {
    const deviceInfo = getDeviceInfo();
    let footerProps = props;
    if (!footerProps) {
        footerProps = {
            stickToBottom: false,
        };
    }
    let layoutIcon = (_jsx(Tooltip, Object.assign({ title: "Desktop Layout" }, { children: _jsx(DesktopIcon, { sx: { padding: "2px" } }) })));
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
        //    console.log("Resolution: Big Screen / Laptop");
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
            //      console.log("Resolution: Mobile Portrait");
            layoutIcon = (_jsx(Tooltip, Object.assign({ title: "Portrait Layout" }, { children: _jsx(PortraitIcon, { sx: { padding: "2px" } }) })));
        }
        else {
            // Landscape Mobile
            //      console.log("Resolution: Mobile Landscape");
            layoutIcon = (_jsx(Tooltip, Object.assign({ title: "Portrait Layout" }, { children: _jsx(LandscapeIcon, { sx: { padding: "2px" } }) })));
        }
    }
    const theme = getTheme();
    const output = (_jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsxs("div", Object.assign({ style: {
                width: "100%",
                paddingBottom: "3px",
                paddingTop: "3px",
                margin: 0,
                backgroundColor: theme.palette.backgrounds.light,
                display: "inline-block",
                position: footerProps.stickToBottom ? "absolute" : "relative",
                verticalAlign: "middle",
                //          lineHeight: "24px",
                bottom: 0,
                left: 0,
                textAlign: "center",
            }, id: "footerOuter" }, { children: [_jsxs(Stack, Object.assign({ sx: {
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5px",
                    }, direction: "row" }, { children: [_jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=4be1a4fa-e1a9-4c75-854d-365b3cbf93f1", color: "violet.main", sx: {
                                textDecoration: "none",
                                marginLeft: "15px",
                                marginRight: "10px",
                            }, target: "_blank", variant: "body2" }, { children: "Cookie Policy" })), _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=426bfff1-769a-4e7b-a7ff-23c76382b689", color: "violet.main", sx: {
                                textDecoration: "none",
                                marginLeft: "10px",
                                marginRight: "10px",
                            }, target: "_blank", variant: "body2" }, { children: "Terms of Use" })), _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=27991f50-d1d3-4aa9-a83a-41a121bc371c", color: "violet.main", sx: {
                                textDecoration: "none",
                                marginLeft: "10px",
                                marginRight: "10px",
                            }, target: "_blank", variant: "body2" }, { children: "Privacy Notice" })), _jsx(Link, Object.assign({ href: getSiteBaseURL() + "Contact", color: "violet.main", sx: {
                                textDecoration: "none",
                                marginLeft: "10px",
                                marginRight: "15px",
                            }, target: "_blank", variant: "body2" }, { children: "Contact Us" }))] })), layoutIcon, _jsx(Copyright, {})] })) })));
    return output;
}
