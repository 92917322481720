var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sendAjaxRequest } from "../API";
import { DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED, DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, } from "../DataUtils";
export const listComments = (postOwnerId, channelId, postId, callbacks, auth) => __awaiter(void 0, void 0, void 0, function* () {
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("listComments: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("listComments: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("listComments: apiCallback OK - bearer and sequence set");
                auth.setBearer(obj.bearer);
                auth.setSequence(obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            try {
                const result = JSON.parse(message);
                if (!result || !result.postComments) {
                    throw new Error("Unable to decode list comments response.");
                }
                callbacks.okCallback(obj.clientDetailCode, result.postComments);
            }
            catch (err) {
                console.error("listComments: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to load comments.");
                }
            }
        }
        else {
            console.error("listComments: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "comments";
    const input = {
        operation: "listComments",
        postOwnerId,
        channelId,
        postId,
        sequence: auth.sequence,
    };
    console.log("listPosts: " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer);
});
