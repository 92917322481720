import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED_SESSION_EXPIRED, loadChannelDataFromURL, } from "../DataUtils";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Button, ButtonGroup, CardActions, CardHeader, Divider, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Stack, TextField, Typography, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { DEFAULT_REASON, submitReport } from "../Store/ReportStore";
import { authUrl, useCacheBusterStore } from "../UrlUrils";
import { getChannelViewBaseURL, getSiteBaseURL } from "../ContentAPI";
import { DeleteForever } from "@mui/icons-material";
import { useLogger } from "../logger";
import { forceLogin } from "../Auth/AuthUtils";
import { useNavigate } from "react-router-dom";
const shareImg = "./img/shareicon_192_glow.png";
const LOGPREFIX = "ChannelCard: ";
export const ChannelCard = (props) => {
    const [channelTitle, setChannelTitle] = useState("");
    const [channelSubtitle, setChannelSubtitle] = useState("");
    const [channelText, setChannelText] = useState("");
    const [channelOwnerId, setChannelOwnerId] = useState();
    const [channelId, setChannelId] = useState();
    const [channelUrl, setChannelUrl] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [enableEditButtons, setEnableEditButtons] = useState(props.enableEditButtons);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [reportVisible, setReportVisible] = useState(false);
    const [reportReason, setReportReason] = useState();
    const [reportSaving, setReportSaving] = useState(false);
    const [reportSavedOK, setReportSavedOK] = useState(false);
    const [reportSaveError, setReportSaveError] = useState(undefined);
    const [menuVisible, setMenuVisible] = useState(false);
    const [shareCardVisible, setShareCardVisible] = useState(false);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const auth = useProfileAuthStore((state) => state);
    const logger = useLogger((state) => state);
    const navigate = useNavigate();
    const doClick = () => {
        const channelViewUrl = getChannelViewBaseURL() + encodeURI(channelTitle);
        window.location.href = channelViewUrl;
    };
    const shareUrl = getSiteBaseURL() +
        "share?channelOwnerId=" +
        channelOwnerId +
        "&channelId=" +
        channelId;
    const doShareClicked = () => {
        logger.log(LOGPREFIX + "doShareClicked");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataObj = {
            title: channelTitle,
            text: channelText,
            url: shareUrl,
        };
        if (navigator.canShare && navigator.canShare(dataObj)) {
            navigator.share(dataObj);
        }
        else {
            logger.log(LOGPREFIX + "Browser does NOT support native share.");
            setShareCardVisible(true);
        }
    };
    const reportChannel = () => {
        logger.log(LOGPREFIX + "reportChannel: " + reportReason);
        if (!channelOwnerId || !channelId || !auth.userId || !reportReason) {
            setReportVisible(false);
            return;
        }
        setReportSaving(true);
        submitReport({
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            postOwnerId: undefined,
            postId: undefined,
            reportedByUserId: auth.userId,
            reportedByDisplayName: auth.displayName,
            reason: reportReason,
        }, {
            okCallback: (clientDetailCode) => {
                logger.error(LOGPREFIX + "submitReport: ok - " + clientDetailCode);
                setReportReason(undefined);
                setReportSaving(false);
                setReportSavedOK(true);
            },
            errorCallback: (clientDetailCode, message) => {
                setReportReason(undefined);
                setReportSaving(false);
                setReportSavedOK(false);
                const errorMessage = message ? message : "Unknown failure";
                logger.error(LOGPREFIX + "submitReport: error = " + errorMessage);
                setReportSaveError(errorMessage + " (" + clientDetailCode + ")");
            },
        }, auth, cacheBusterStore);
    };
    useEffect(() => {
        if (reportSaveError) {
            setTimeout(() => {
                setReportSaveError(undefined);
                setReportVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSaveError]);
    useEffect(() => {
        if (reportSavedOK) {
            setTimeout(() => {
                setReportSavedOK(false);
                setReportVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSavedOK]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: mount - channelUrl");
        const url = authUrl(props.basePath + "/" + props.channelJson, cacheBusterStore, auth);
        if (!channelUrl) {
            setChannelUrl(url);
        }
        else {
            logger.log(LOGPREFIX + "loading channel data from URL...");
            setLoading(true);
            loadChannelDataFromURL(channelUrl, {
                okCallback: (clientDetailCode, data) => {
                    logger.log(LOGPREFIX +
                        "okCallback: channel data = " +
                        data +
                        " - " +
                        clientDetailCode);
                    try {
                        const dataObj = JSON.parse(data);
                        logger.log(LOGPREFIX + "Channel Data: " + JSON.stringify(dataObj));
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.title) {
                            logger.log(LOGPREFIX + "Channel Title : " + dataObj.title);
                            setChannelTitle(dataObj.title);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.subtitle) {
                            logger.log(LOGPREFIX + "Channel Subtitle : " + dataObj.subtitle);
                            setChannelSubtitle(dataObj.subtitle);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.text) {
                            logger.log(LOGPREFIX + "Channel Text : " + dataObj.text);
                            setChannelText(dataObj.text);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.channelOwnerId) {
                            logger.log(LOGPREFIX + "Channel Owner ID : " + dataObj.channelOwnerId);
                            setChannelOwnerId(dataObj.channelOwnerId);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.channelId) {
                            logger.log(LOGPREFIX + "Channel ID : " + dataObj.channelId);
                            setChannelId(dataObj.channelId);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasImage) {
                            logger.log(LOGPREFIX + "Channel Has Image : true");
                            if (props.maxResolution) {
                                setMediaUrl(authUrl(url.replace("channelData.json", "channelImage_1024.jpg"), cacheBusterStore, auth));
                            }
                            else {
                                setMediaUrl(authUrl(url.replace("channelData.json", "channelImage_512.jpg"), cacheBusterStore, auth));
                            }
                        }
                        else {
                            logger.log(LOGPREFIX + "Channel Has Image : false");
                            setMediaUrl("/img/channels_512.jpg");
                        }
                    }
                    catch (err) {
                        logger.error(LOGPREFIX + "channel data parse error: " + err);
                        setErrorMessage(err.message);
                    }
                    finally {
                        setLoading(false);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    logger.error(LOGPREFIX +
                        "channel data error: " +
                        errorMessage +
                        " (" +
                        clientDetailCode +
                        ")");
                    if (errorMessage && errorMessage.includes("NoSuchKey")) {
                        setErrorMessage("No channel exists with that key. It may have been deleted. Check the URL and try again.");
                    }
                    else {
                        setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
                    }
                    setLoading(false);
                    if (clientDetailCode ==
                        DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED_SESSION_EXPIRED) {
                        logger.error("Content Authorizer indicates SESSION EXPIRED");
                        forceLogin(navigate);
                        return;
                    }
                },
            }, cacheBusterStore);
        }
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - postUrl");
        };
    }, [channelUrl]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: media url = " + mediaUrl);
        return () => { };
    }, [mediaUrl]);
    const commentElements = [];
    if (reportVisible) {
        const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        reportChannel();
                    } }, { children: "Submit" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                        setReportVisible(false);
                        setReportReason(undefined);
                    } }, { children: "Cancel" }))] })));
        if (reportSaveError) {
            commentElements.push(_jsx(ContentCard, { keyStr: "report.save.error." + channelId, title: "ERROR", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: reportSaveError })), textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: 0.9, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, "report.save.error." + channelId));
        }
        else if (reportSavedOK) {
            commentElements.push(_jsx(ContentCard, { keyStr: "report.saved.ok." + channelId, title: "SUCCESS", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: "Your report has been submitted." })), textColor: getTheme().palette.black.main, backgroundColor: "#EEFFEE", maxWidth: 0.9, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, "report.saved.ok." + channelId));
        }
        else if (reportSaving) {
            logger.log(LOGPREFIX + "render: reportSaving");
            commentElements.push(_jsx(LinearProgress, { sx: { width: "100%" } }, "report.saving.progress." + channelId));
        }
        else {
            commentElements.push(_jsx(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(ContentCard, { title: "REPORT CHANNEL", keyStr: "content.card.channel.confirm.report." + channelId, childComponent: _jsxs(Stack, Object.assign({ direction: "column", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Typography, Object.assign({ sx: { display: "flex", justifyContent: "center" }, variant: "body1" }, { children: "Why do you want to report this channel?" })), _jsx(FormControl, { children: _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "report-buttons-group-label", defaultValue: DEFAULT_REASON, name: "radio-buttons-group", sx: { margin: "auto" }, onChange: (event) => {
                                        setReportReason(event.target.value);
                                    } }, { children: [_jsx(FormControlLabel, { value: "ads", control: _jsx(Radio, {}), label: "Channel advertises for a business." }), _jsx(FormControlLabel, { value: "fraud", control: _jsx(Radio, {}), label: "Channel is fraudulent." }), _jsx(FormControlLabel, { value: "hate", control: _jsx(Radio, {}), label: "Channel advocates for violence or hate." }), _jsx(FormControlLabel, { value: "illegal", control: _jsx(Radio, {}), label: "Channel is illegal." }), _jsx(FormControlLabel, { value: "inappropriate", control: _jsx(Radio, {}), label: "Channel is inappropriate." }), _jsx(FormControlLabel, { value: "other", control: _jsx(Radio, {}), label: "Other" })] })) })] }), "report.sub.stack.menu." + channelId), actions: actions, backgroundColor: "#FFFFFF", textColor: "#333333" }, "content.card.channel.confirm.report." + channelId) }), "report.menu.stack." + channelId));
        }
    }
    let text = channelText;
    const menuButtonsList = [];
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            setReportVisible(true);
        } }, { children: "REPORT" }), "report.button.channel.report." + channelId));
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            setReportVisible(false);
            setReportReason(DEFAULT_REASON);
        } }, { children: "HIDE" }), "report.button.post.hide." + channelId));
    const menuButtons = (_jsx(Stack, Object.assign({ direction: "row", display: "flex", justifyContent: "right" }, { children: _jsx(ButtonGroup, Object.assign({ orientation: "vertical", variant: "outlined", sx: { margin: "10px" } }, { children: menuButtonsList }), menuVisible + ".post.menu." + channelId) }), "menu.buttons.stack." + channelId));
    if (shareCardVisible) {
        const shareContent = (_jsxs(Stack, Object.assign({ direction: "column", spacing: 1, sx: { padding: "10px" } }, { children: [_jsx(Typography, Object.assign({ variant: "body1" }, { children: "Link" })), _jsx(Stack, Object.assign({ direction: "row", sx: { width: "100%", display: "flex", justifyContent: "center" }, spacing: 1 }, { children: _jsx(TextField, { value: shareUrl, multiline: true, sx: { width: "90%" }, onClick: () => {
                            navigator.clipboard.writeText(shareUrl);
                            alert("Copied Link : " + shareUrl);
                        } }) })), _jsxs(Stack, Object.assign({ direction: "row", sx: { width: "100%", display: "flex", justifyContent: "center" }, spacing: 1 }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                                navigator.clipboard.writeText(shareUrl);
                                alert("Copied Link : " + shareUrl);
                            }, endIcon: _jsx(ContentCopyIcon, {}) }, { children: "COPY TO CLIPBOARD" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                                setShareCardVisible(false);
                            }, endIcon: _jsx(CloseIcon, {}) }, { children: "CLOSE" }))] }))] })));
        return (_jsx(ContentCard, { childComponent: shareContent, title: "SHARE", keyStr: "share.card", textColor: "main", backgroundColor: "#FFFFFF" }));
    }
    const childComponent = (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [loading && _jsx(LinearProgress, { sx: { width: "100%" } }), !!text && (_jsx(Typography, Object.assign({ onClick: doClick, whiteSpace: "pre-line", variant: "body1", color: getTheme().palette.black.main, sx: {
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    wordBreak: "break-word",
                    cursor: "pointer",
                } }, { children: text ? text : undefined }), props.keyStr + "-typography")), _jsx(Divider, { orientation: "horizontal", flexItem: true }), menuVisible && menuButtons, enableEditButtons &&
                !deleteClicked &&
                !deleting &&
                enableEditButtons && (_jsx(Stack, Object.assign({ direction: "row", sx: {
                    padding: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                } }, { children: _jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                        setDeleteClicked(true);
                    }, endIcon: _jsx(DeleteForever, {}) }, { children: "Delete Channel" })) }), channelUrl + "stack.editbuttons." + channelId)), enableEditButtons && deleteClicked && !deleting && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx("hr", { style: { width: "100%" } }), _jsx(Typography, Object.assign({ variant: "h6", color: getTheme().palette.violet.main }, { children: "DELETE CHANNEL" })), _jsx(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main }, { children: "Are you sure?" })), _jsxs(Stack, Object.assign({ direction: "row", sx: {
                            padding: "5px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        } }, { children: [_jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                                    if (props.deleteChannel) {
                                        props.deleteChannel(props.basePath, props.channelJson);
                                        setDeleteClicked(false);
                                        setDeleting(true);
                                    }
                                } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "outlined", sx: { margin: "5px" }, onClick: () => {
                                    setDeleteClicked(false);
                                } }, { children: "No" }))] }), props.keyStr + ".button.stack.row")] }), channelUrl + "stack.deletebutton." + channelId)), enableEditButtons && deleting && _jsx(LinearProgress, {}), commentElements && commentElements.length > 0 && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, {}), _jsx("div", { style: { margin: 0, padding: 0, marginTop: "2px" } }), commentElements, _jsx("div", { style: { margin: 0, padding: 0, marginBottom: "5px" } })] }), "comments.stack.column." + channelId))] }), channelUrl + "stack.column"));
    text = undefined;
    if (errorMessage) {
        const errorChildComponent = (_jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.channelJson + mediaUrl, title: "ERROR", childComponent: errorChildComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.channelJson + mediaUrl));
    }
    let actionHeight = "36px";
    if (channelTitle && channelSubtitle) {
        actionHeight = "48px";
    }
    const shareButton = (_jsx(CardActions, Object.assign({ sx: {
            flexBasis: "auto",
            position: "relative",
            flexgrow: "1",
            flex: "0 1 auto",
            display: "flex",
            height: actionHeight,
            paddingRight: "0",
            marginRight: "0",
        } }, { children: _jsx(Button, Object.assign({ size: "small", sx: {
                minWidth: "40px",
                minHeight: "36px",
                height: "36px",
                padding: 0,
            }, onClick: () => {
                doShareClicked();
                return true;
            } }, { children: _jsx("img", { src: shareImg, width: "32px", height: "32px" }) })) })));
    let header;
    if (channelTitle || channelSubtitle) {
        header = (_jsx(CardHeader, { title: channelTitle, subheader: channelSubtitle, sx: {
                margin: 0,
                padding: 0,
                marginLeft: "5px",
                marginRight: "5px",
                cursor: "pointer",
            }, titleTypographyProps: { variant: "h6", marginLeft: "18px" }, subheaderTypographyProps: { marginLeft: "18px" }, onClick: (event) => {
                const target = event.target;
                if (target instanceof HTMLSpanElement) {
                    doClick();
                    return;
                }
            }, action: shareButton }));
    }
    const elm = (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.channelJson + mediaUrl, title: "", header: header, childComponent: childComponent, mediaUrl: !errorMessage && mediaUrl
            ? authUrl(mediaUrl, cacheBusterStore, auth)
            : undefined, textColor: getTheme().palette.black.main, backgroundColor: "#FFFFFF", onMediaClick: doClick, maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.channelJson + mediaUrl));
    return elm;
};
