import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Grid, IconButton, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { getDeviceInfo } from "./DeviceInfo";
import getTheme from "./Theme";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { doLogoutRedirect } from "./Auth/AuthUtils";
import { UserAvatar } from "./components/UserAvatar";
import { useFriendsStore } from "./Store/FriendStore";
import { useCacheBusterStore } from "./UrlUrils";
import { useLogger } from "./logger";
import { newNotificationsCount, requestNotificationPermission, useNotificationsStore, } from "./Store/NotificationsStore";
import { getAPIEndpoint } from "./API";
import { getContentURL } from "./ContentAPI";
const logoImg = "./img/logo.svg";
const logoShorterImg = "./img/logo1_shorter_3.svg";
const LOGPREFIX = "Heading: ";
export default function Heading(props) {
    const [forceRender, setForceRender] = useState(0);
    const theme = getTheme();
    const deviceInfo = getDeviceInfo();
    const [unseenCount, setUnseenCount] = useState(0);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const navigate = useNavigate();
    const [navMenuVisible, setNavMenuVisible] = useState(false);
    const [workerRegistered, setWorkerRegistered] = useState(false);
    const authStore = useProfileAuthStore();
    const friendsStore = useFriendsStore();
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const notificationsStore = useNotificationsStore((state) => state);
    const logger = useLogger((state) => state);
    let userId;
    let displayName;
    useEffect(() => {
        const retVal = () => { };
        if (authStore.isSignedIn()) {
            if (!notificationsStore.ok &&
                !notificationsStore.loading &&
                !notificationsStore.error &&
                !notificationsStore.done) {
                notificationsStore.load(authStore, cacheBusterStore, {
                    handleUpdate: () => {
                        console.log(LOGPREFIX + "notification store updated");
                    },
                });
            }
            else if (notificationsStore.ok) {
                const unseenCount = newNotificationsCount(notificationsStore);
                console.log(LOGPREFIX + "notification store unseen count = " + unseenCount);
                setUnseenCount(unseenCount);
                setNotificationsCount(notificationsStore.notifications.length);
            }
        }
        return retVal;
    }, [notificationsStore, authStore, cacheBusterStore]);
    useEffect(() => {
        const retVal = () => { };
        if (!authStore.isSignedIn()) {
            if (!window.location.href.toLocaleLowerCase().includes("auth")) {
                restoreSession(authStore);
            }
            else {
                logger.log(LOGPREFIX + "Skipping restore session - on Auth url...");
            }
        }
        else if (!workerRegistered) {
            // Prompt for notifications
            // Register service worker
            logger.log(LOGPREFIX + "SvcWork - request push permissions");
            requestNotificationPermission()
                .then((registered) => {
                logger.log(LOGPREFIX +
                    "SvcWork - request push permissions - granted = " +
                    registered);
                if (registered) {
                    setWorkerRegistered(true);
                    // TODO: Move into notification store
                    if ("serviceWorker" in navigator && "PushManager" in window) {
                        logger.log(LOGPREFIX + "SvcWork - registering service worker");
                        navigator.serviceWorker
                            .register("/serviceWorker.js")
                            .then((registration) => {
                            logger.log(LOGPREFIX + "SvcWork - got registration");
                            return registration.active;
                        })
                            .then((registration) => {
                            logger.log(LOGPREFIX + "SvcWork - registration active");
                            // Service worker is active and ready
                            logger.log("SvcWork - Service worker is active:" + (registration === null || registration === void 0 ? void 0 : registration.state));
                            navigator.serviceWorker.ready.then(() => {
                                var _a, _b;
                                logger.log("SvcWork - service worker ready");
                                if ((_a = navigator.serviceWorker.controller) === null || _a === void 0 ? void 0 : _a.postMessage) {
                                    (_b = navigator.serviceWorker.controller) === null || _b === void 0 ? void 0 : _b.postMessage({
                                        type: "configureServiceWorker",
                                        data: JSON.stringify({
                                            svcEndpoint: getAPIEndpoint(),
                                            bearer: authStore.bearer,
                                            sequence: authStore.sequence,
                                            userId: authStore.userId,
                                            siteUrl: getContentURL() + "/Notifications",
                                        }),
                                    });
                                    logger.log(LOGPREFIX + "SvcWork - Posted Message OK");
                                }
                                else {
                                    logger.error(LOGPREFIX + "SvcWork - No service worker controller.");
                                }
                            });
                        })
                            .catch((err) => {
                            logger.error(LOGPREFIX +
                                "SvcWork - Service Worker registration failed : " +
                                err);
                        });
                    }
                    else {
                        logger.log(LOGPREFIX + "SvcWork - Push not supported.");
                    }
                }
                else {
                    logger.log(LOGPREFIX + "SvcWork - Not registered.");
                }
            })
                .catch((err) => {
                logger.error(LOGPREFIX + "SvcWork - error : " + err);
            });
        }
        return retVal;
    }, [authStore, setWorkerRegistered]);
    useEffect(() => {
        const retVal = () => { };
        if (!authStore.isSignedIn() || !authStore.userId) {
            return retVal;
        }
        if (!friendsStore.done &&
            !friendsStore.error &&
            !friendsStore.ok &&
            !friendsStore.loading) {
            friendsStore
                .load(authStore.userId, authStore, cacheBusterStore)
                .then(() => {
                setForceRender(forceRender + 1);
            });
        }
        return retVal;
    }, [authStore, friendsStore]);
    if (authStore.isSignedIn()) {
        userId = authStore.userId;
        displayName = authStore.displayName;
    }
    const handleNavMenuClick = () => {
        logger.log(LOGPREFIX + "handleOpenNavMenu");
        setNavMenuVisible(!navMenuVisible);
    };
    let logoWidth = "260px";
    let loginTopPadding = "6px";
    let loginRightPadding = "0";
    let navMenuTop = "38px";
    const logoTopPadding = "5px";
    let menuPaddingLeft = "0";
    let menuPaddingRight = "0";
    let nameElement = (_jsx(Tooltip, Object.assign({ title: "Profile Settings", sx: { padding: 0, margin: 0, marginRight: "12px" } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: displayName })) })));
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
        //    logger.log(LOGPREFIX+"Resolution: Big Screen / Laptop");
        logoWidth = "310px";
        navMenuTop = "44px";
        menuPaddingLeft = "10px";
        menuPaddingRight = "10px";
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
            //    logger.log(LOGPREFIX+"Resolution: Mobile Portrait");
            logoWidth = "260px";
            loginTopPadding = "2px";
            loginRightPadding = "0";
            nameElement = undefined;
            menuPaddingLeft = "4px";
            menuPaddingRight = "8px";
        }
        else {
            // Landscape Mobile
            //  logger.log(LOGPREFIX+"Resolution: Mobile Landscape");
            logoWidth = "260px";
            loginTopPadding = "2px";
            loginRightPadding = "2px";
            menuPaddingLeft = "10px";
            menuPaddingRight = "10px";
        }
    }
    if (!userId) {
        return (_jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx("div", Object.assign({ id: "theme.provider.div." + forceRender, style: {
                    backgroundColor: theme.palette.backgrounds.light,
                    display: "flex",
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx("a", Object.assign({ href: "/", style: { margin: 0, padding: 0 } }, { children: _jsx("img", { src: logoImg, width: logoWidth, style: {
                                        margin: 0,
                                        paddingTop: logoTopPadding,
                                        paddingLeft: "10px",
                                    } }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: !(props === null || props === void 0 ? void 0 : props.disableNav) && (_jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx(Button, Object.assign({ sx: {
                                        margin: 0,
                                        paddingTop: loginTopPadding,
                                        paddingRight: loginRightPadding,
                                        paddingBottom: 0,
                                    }, onClick: () => {
                                        navigate("/Login");
                                    } }, { children: _jsx(PersonIcon, { color: "primary", fontSize: "large" }) })) }))) }))] }), "header.grid.container." + forceRender) }), "theme.provider.div." + forceRender) }), "theme.provider." + forceRender));
    }
    let navMenu = undefined;
    if (navMenuVisible) {
        navMenu = (_jsx("div", Object.assign({ style: {
                position: "absolute",
                top: navMenuTop,
                right: "1px",
                padding: "0",
                margin: "0",
                backgroundColor: "#FFFFFF",
                zIndex: 10,
            } }, { children: _jsxs(ButtonGroup, Object.assign({ orientation: "vertical", variant: "outlined" }, { children: [_jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Home");
                        } }, { children: "Home" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Notifications");
                        } }, { children: "Notifications" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Latest");
                        } }, { children: "Latest" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Feed");
                        } }, { children: "Feed" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Channels");
                        } }, { children: "Channels" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Messaging");
                        } }, { children: "Messaging" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Friends");
                        } }, { children: "Friends" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/Profile");
                        } }, { children: "Profile" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/ProfileView?userId=71fe1c6a-bfa6-43ac-93c0-f7b0d60d8849");
                        } }, { children: "Support" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            navigate("/About");
                        } }, { children: "About Us" })), "\u00A0\u00A0\u00A0", _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                            handleNavMenuClick();
                            doLogoutRedirect();
                        } }, { children: "Sign Out" })), "\u00A0\u00A0\u00A0"] })) })));
    }
    if (props === null || props === void 0 ? void 0 : props.disableNav) {
        return (_jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx("div", Object.assign({ style: {
                    backgroundColor: theme.palette.backgrounds.light,
                    display: "flex",
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx("img", { src: logoImg, width: logoWidth, style: { margin: 0, paddingTop: "2px", paddingLeft: "10px" } }) })), _jsx(Grid, Object.assign({ item: true, xs: 6, sx: { display: "table" } }, { children: _jsx("div", { style: {
                                    textAlign: "right",
                                    verticalAlign: "middle",
                                    height: "100%",
                                    display: "table-cell",
                                } }) }))] })) })) }), "theme.provider.header." + forceRender));
    }
    const friendInvitesExist = friendsStore &&
        friendsStore.ok &&
        friendsStore.invites &&
        friendsStore.invites.length > 0;
    const notificationsExist = friendInvitesExist || unseenCount > 0 || notificationsCount > 0;
    let imageUrl = logoImg;
    if (deviceInfo.isPortrait &&
        deviceInfo.isTabletOrMobile &&
        notificationsExist) {
        imageUrl = logoShorterImg;
        logoWidth = "200px";
    }
    const unseenIcon = (_jsx(Tooltip, Object.assign({ title: "New Notifications" }, { children: _jsx(Button, Object.assign({ size: "medium", sx: { padding: 0, margin: 0, minWidth: "36px" }, onClick: () => {
                navigate("/Notifications");
            } }, { children: _jsx(NotificationImportantIcon, { fontSize: "large" }) }), "header.new.notifications.button." + forceRender) }), "header.new.notifications.button.tooltip." + forceRender));
    const seenIcon = (_jsx(Tooltip, Object.assign({ title: "Notifications" }, { children: _jsx(Button, Object.assign({ size: "medium", sx: { padding: 0, margin: 0, minWidth: "36px" }, onClick: () => {
                navigate("/Notifications");
            } }, { children: _jsx(NotificationsNoneIcon, { fontSize: "medium" }) }), "header.old.notifications.button." + forceRender) }), "header.new.notifications.button.tooltip." + forceRender));
    let notifIcon;
    if (unseenCount > 0) {
        notifIcon = unseenIcon;
    }
    else if (notificationsCount > 0) {
        notifIcon = seenIcon;
    }
    return (_jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx("div", Object.assign({ id: "theme.provider.header.div." + forceRender, style: {
                backgroundColor: theme.palette.backgrounds.light,
                margin: 0,
                padding: 0,
                display: "flex",
            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 0, sx: {
                    display: "flex-grow",
                    marginTop: "-6px",
                    padding: 0,
                    height: "52px",
                } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6, sx: {
                            display: "flex",
                            margin: "auto",
                            paddingTop: "4px",
                            marginLeft: 0,
                        } }, { children: _jsx("div", Object.assign({ style: {
                                textAlign: "left",
                                flexBasis: "auto",
                                position: "relative",
                                flexGrow: "1",
                                flex: "0 1 auto",
                                display: "flex",
                                paddingRight: "0",
                                marginRight: "0",
                                justifyContent: "left",
                                paddingLeft: "0",
                            } }, { children: _jsx("a", Object.assign({ href: "/", style: { textDecoration: "none" } }, { children: _jsx("img", { src: imageUrl, width: logoWidth, style: {
                                        paddingLeft: "10px",
                                    } }) })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 6, sx: { display: "flex-grow" } }, { children: _jsxs("div", Object.assign({ style: {
                                textAlign: "right",
                                margin: 0,
                                flexBasis: "auto",
                                position: "relative",
                                flexGrow: "1",
                                flex: "0 1 auto",
                                display: "flex",
                                paddingRight: "0",
                                marginRight: "0",
                                justifyContent: "right",
                            } }, { children: [friendInvitesExist && (_jsx(Tooltip, Object.assign({ title: "Friend Requests Pending" }, { children: _jsx(Button, Object.assign({ size: "medium", sx: { minWidth: "36px" }, onClick: () => {
                                            navigate("/Friends");
                                        } }, { children: _jsx(PersonAddIcon, { fontSize: "large" }) }), "header.pending.friend.requests.button." + forceRender) }), "header.pending.friend.requests.button.tooltip." +
                                    forceRender)), !friendInvitesExist && notifIcon, _jsxs(Button, Object.assign({ sx: {
                                        margin: 0,
                                        //                  paddingTop: 0,
                                        paddingRight: 0,
                                        //                  paddingBottom: 0,
                                    }, onClick: () => {
                                        navigate("/Profile");
                                    } }, { children: [nameElement, (authStore === null || authStore === void 0 ? void 0 : authStore.userId) && (authStore === null || authStore === void 0 ? void 0 : authStore.displayName) && (_jsx(UserAvatar, { keyStr: "heading.avatar." + authStore.userId, userId: authStore.userId, userNameVisible: false, userName: authStore.displayName, onUserClicked: () => {
                                                navigate("/Profile");
                                            }, tooltip: "Profile Settings", sx: {
                                                marginRight: 0,
                                                marginLeft: 0,
                                                //                      marginTop: "3px",
                                                //                      marginBottom: "3px",
                                            }, auth: authStore }))] })), _jsx(IconButton, Object.assign({ size: "large", onClick: () => {
                                        handleNavMenuClick();
                                    }, color: "primary", sx: {
                                        margin: 0,
                                        //                  paddingTop: 0,
                                        //                  paddingBottom: 0,
                                        paddingLeft: menuPaddingLeft,
                                        paddingRight: menuPaddingRight,
                                    } }, { children: _jsx(MenuIcon, {}) })), navMenu] })) }), "header.rightgrid." + forceRender)] }), "header.grid.container." + forceRender) }), "theme.provider.header.div." + forceRender) }), "theme.provider.header." + forceRender));
}
