import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import getTheme from "./Theme";
import { Button, Card, CardContent, Container, Divider, LinearProgress, Stack, TextField, ThemeProvider, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore, } from "./Store/ProfileStore";
import { useEffect, useState } from "react";
import { ContentCardStacks } from "./components/ContentCardStacks";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IosShareIcon from "@mui/icons-material/IosShare";
import { loadFriendChannels, useFriendsStore, } from "./Store/FriendStore";
import { UserAvatar } from "./components/UserAvatar";
import { SearchBar } from "./components/SearchBar";
import { sendAjaxRequest } from "./API";
import { forceLogin } from "./Auth/AuthUtils";
import { ChannelCard } from "./components/ChannelCard";
import { ContentCard } from "./components/ContentCard";
import { getContentURL, getSiteBaseURL } from "./ContentAPI";
import { useCacheBusterStore } from "./UrlUrils";
import { useLogger } from "./logger";
import { TopButtonMenu } from "./components/TopButtonMenu";
const subHeadingColor = "#999999";
let timer = undefined;
let timerStartText = "";
const LOGPREFIX = "Friends: ";
export function Friends() {
    const cacheBusterStore = useCacheBusterStore((state) => state);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [forceRender, setForceRender] = useState(cacheBusterStore.getCacheBusterNumber());
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchBarText, setSearchBarText] = useState();
    const [searching, setSearching] = useState(false);
    const [searchError, setSearchError] = useState("");
    const [searchResults, setSearchResults] = useState();
    const [completedMessage, setCompletedMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [friendChannelsLoading, setFriendChannelsLoading] = useState();
    const [friendChannelsLoadError, setFriendChannelsLoadError] = useState();
    const [friendChannelIds, setFriendChannelIds] = useState([]);
    const [friendInviteCodeVisible, setFriendInviteCodeVisible] = useState(false);
    const [friendInviteCodeLink, setFriendInviteCodeLink] = useState("");
    const [friendInviteCodeError, setFriendInviteCodeError] = useState("");
    const [friendInviteDataObj, setFriendInviteDataObj] = useState(undefined);
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    const friendsStore = useFriendsStore((state) => state);
    const logger = useLogger((state) => state);
    const doLoadFriendChannels = () => {
        logger.log(LOGPREFIX + "doLoadFriendChannels");
        if (friendChannelsLoading ||
            (friendChannelIds && friendChannelIds.length > 0)) {
            logger.log(LOGPREFIX + "doLoadFriendChannels - skipping...");
            return;
        }
        loadFriendChannels(friendsStore, {
            setFriendChannelIds: (channelIds) => {
                logger.log(LOGPREFIX +
                    "doLoadFriendChannels: setFriendChannelIds = " +
                    JSON.stringify(channelIds));
                setFriendChannelIds(channelIds);
            },
            getFriendChannelIds: () => {
                return friendChannelIds;
            },
            setLoading: (loading) => {
                setFriendChannelsLoading(loading);
            },
            isLoading: () => {
                return friendChannelsLoading ? true : false;
            },
            setErrorMessage: function (message) {
                setFriendChannelsLoadError(message);
            },
            auth: function () {
                return auth;
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        const retVal = () => { };
        if (friendInviteDataObj) {
            if (navigator.canShare && navigator.canShare(friendInviteDataObj)) {
                logger.log(LOGPREFIX +
                    "Launching navigator share for dataObj : " +
                    JSON.stringify(friendInviteDataObj));
                navigator.share(friendInviteDataObj);
            }
            else {
                logger.log(LOGPREFIX + "Navigator share is NOT enabled.");
            }
        }
        return retVal;
    }, [friendInviteDataObj]);
    useEffect(() => {
        const retVal = () => { };
        if (friendInviteCodeVisible &&
            !friendInviteCodeLink &&
            !friendInviteCodeError &&
            !errorMessage) {
            logger.log(LOGPREFIX + "Requesting friend invite code...");
            friendsStore
                .createInviteCode(auth, cacheBusterStore)
                .then((inviteCode) => {
                logger.log(LOGPREFIX + "Received Invite Code = " + inviteCode);
                const inviteLink = getSiteBaseURL() + "FriendInvite?inviteId=" + inviteCode;
                setFriendInviteCodeLink(inviteLink);
                logger.log(LOGPREFIX + "Invite Link = " + inviteLink);
                const dataObj = {
                    title: "MediaShare.Link Friend Invite (from " + auth.displayName + ")",
                    text: auth.displayName +
                        " sent you an invite to become friends on MediaShare.Link. Click the following link to review the request. ",
                    url: inviteLink,
                };
                setFriendInviteDataObj(dataObj);
                logger.log(LOGPREFIX + "Invite Data Object = " + dataObj);
            })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((err) => {
                logger.error(err);
                let errorMessage = "" + err;
                if (!errorMessage) {
                    errorMessage =
                        "Unable to get friend invite code. Please try again, later.";
                }
                logger.error(errorMessage);
                setFriendInviteCodeError(errorMessage);
            });
        }
        return retVal;
    }, [friendInviteCodeVisible]);
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            logger.log(LOGPREFIX + "User is not signed in");
            if (!restoreSession(auth)) {
                logger.log(LOGPREFIX + "User NOT logged in - navigating...");
                forceLogin(navigate);
            }
            else {
                logger.log(LOGPREFIX + "User session restored");
                setDataLoaded(false);
            }
        }
        else if (!dataLoaded) {
            logger.log(LOGPREFIX + "Data not loaded - store = " + JSON.stringify(friendsStore));
            if (!friendsStore.done &&
                !friendsStore.loading &&
                !friendsStore.error &&
                !friendsStore.ok) {
                logger.log(LOGPREFIX + "Loading data...");
                friendsStore.load(auth.userId, auth, cacheBusterStore).then(() => {
                    logger.log(LOGPREFIX + "Friends load completed");
                    setDataLoaded(true);
                });
            }
            else {
                logger.log(LOGPREFIX + "Data loaded. Loading friend channels...");
                doLoadFriendChannels();
            }
        }
        else {
            logger.log(LOGPREFIX + "Data loaded. Loading friend channels...");
            doLoadFriendChannels();
        }
        return () => { };
    }, [auth, dataLoaded]);
    useEffect(() => {
        if (completedMessage) {
            setTimeout(() => {
                logger.log(LOGPREFIX + "Clearing completed message.");
                setCompletedMessage(undefined);
            }, 3000);
        }
        return () => { };
    }, [completedMessage]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: friends = " + JSON.stringify(friendsStore));
        const callback = {
            handleUpdate: (state) => {
                logger.log(LOGPREFIX + "handleUpdate: " + JSON.stringify(state));
                setLoading(state.loading);
            },
        };
        friendsStore.callback = callback;
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount friends");
            friendsStore.callback = undefined;
        };
    }, [friendsStore]);
    const userButtonClicked = (userId) => {
        logger.log(LOGPREFIX + "userButtonClicked: " + userId);
        navigate("/ProfileView?userId=" + userId);
    };
    const acceptInvite = (userId) => {
        logger.log(LOGPREFIX + "acceptInvite: " + userId);
        friendsStore
            .acceptInvite(userId, auth, cacheBusterStore)
            .then(() => {
            logger.log(LOGPREFIX + "acceptInvite: resolved");
            logger.log(LOGPREFIX +
                "Set Completed Message: Friend invited has been accepted.");
            setCompletedMessage("Friend invite has been accepted.");
            if (auth.userId) {
                friendsStore.load(auth.userId, auth, cacheBusterStore);
            }
        })
            .catch((err) => {
            setErrorMessage(err.message);
        });
    };
    const declineInvite = (userId) => {
        logger.log(LOGPREFIX + "declineInvite: " + userId);
        friendsStore
            .declineInvite(userId, auth, cacheBusterStore)
            .then(() => {
            logger.log(LOGPREFIX + "declineInvite: resolved");
            logger.log(LOGPREFIX +
                "Set Completed Message: Friend invited has been declined.");
            setCompletedMessage("Friend invite has been declined.");
        })
            .catch((err) => {
            setErrorMessage(err.message);
        });
    };
    const doSearch = (searchText) => {
        logger.log(LOGPREFIX + "doSearch: " + searchText);
        if (!searchText || !searchText.trim()) {
            logger.log(LOGPREFIX + "Empty search text");
            return;
        }
        setSearching(true);
        const apiCallback = (ok, status, message) => {
            var _a;
            setSearching(false);
            setSearchError("");
            logger.log(LOGPREFIX + "searchCallback: " + message);
            try {
                if (ok) {
                    const result = JSON.parse(message);
                    if (result.bearer && result.sequence) {
                        auth.setBearerAndSequence(result.bearer, result.sequence);
                    }
                    const searchEntries = [];
                    (_a = result.entries) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                        if (entry.userId != auth.userId) {
                            searchEntries.push(entry);
                        }
                    });
                    setSearchResults(searchEntries);
                }
                else {
                    const result = JSON.parse(message);
                    setSearchError(result.error + " (" + result.clientDetailCode + ")");
                }
            }
            catch (err) {
                logger.error(LOGPREFIX + "ERROR: " + err);
                setSearchError(err.message);
            }
        };
        const payload = {
            searchText: timerStartText,
            sequence: auth.sequence,
            operation: "searchForUser",
        };
        sendAjaxRequest(apiCallback, "search", payload, auth.bearer, cacheBusterStore);
    };
    const searchBarChange = (searchText) => {
        logger.log(LOGPREFIX + "searchBarChange: " + searchText);
        setSearchBarText(searchText);
        if (timer) {
            logger.log(LOGPREFIX + "searchBarChange: cancelled timer");
            window.clearTimeout(timer);
            timer = undefined;
        }
        timerStartText = searchText;
        timer = window.setTimeout(() => {
            logger.log(LOGPREFIX +
                "searchBarChange: timeout " +
                searchText +
                " ?= " +
                timerStartText);
            if (searchText == timerStartText) {
                doSearch(searchText);
            }
        }, 1500);
    };
    const searchBarClicked = () => {
        logger.log(LOGPREFIX + "searchBarClicked");
        if (timer) {
            logger.log(LOGPREFIX + "searchBarChange: cancelled timer");
            window.clearTimeout(timer);
            timer = undefined;
        }
        doSearch(timerStartText);
    };
    if (friendsStore.done) {
        if (friendsStore.ok) {
            logger.log(LOGPREFIX + "useEffect.friends OK");
            logger.log(LOGPREFIX + "Friends List = " + JSON.stringify(friendsStore.friends));
            logger.log(LOGPREFIX + "Invites List = " + JSON.stringify(friendsStore.invites));
        }
        else if (friendsStore.error) {
            logger.log(LOGPREFIX + "useEffect.friends ERROR = " + friendsStore.error);
        }
    }
    const searchBar = (_jsx(SearchBar, { label: "Find Friends", onSearchChanged: searchBarChange, onSearchClicked: searchBarClicked }, "searchBar"));
    const searchResultsEntries = [];
    if (searchResults) {
        searchResults.forEach((entry) => {
            if (!entry.userId || !entry.displayName) {
                return;
            }
            searchResultsEntries.push(_jsx(UserAvatar, { keyStr: entry.userId + ".search.entry.avatar", userId: entry.userId, userName: entry.displayName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, entry.userId + ".search.entry.avatar"));
        });
    }
    const searchResultsStack = (_jsx(ContentCardStacks, { cards: searchResultsEntries, columnCount: 4 }, "search.results.stack"));
    const searchArea = !loading && !friendInviteCodeVisible && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Search" })), searchBar, _jsx(Divider, { orientation: "horizontal" }), searchError && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: searchError }))] }, "friends-search-error-card-content") }), "friends-search-error-card")), !searchError && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [searching && _jsx(LinearProgress, { sx: { width: "100%" } }), !searching && searchBarText && (_jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                            width: "100%",
                            textAlign: "left",
                            marginBottom: 0,
                            paddingBottom: 0,
                            marginLeft: "5px",
                        } }, { children: "Search Results" }))), !searching &&
                        searchBarText &&
                        searchResultsEntries.length > 0 &&
                        searchResultsStack, !searching && searchBarText && searchResultsEntries.length <= 0 && (_jsx(Typography, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "20px",
                        } }, { children: "No search results." })))] })))] }), "searchArea"));
    const friendsList = [];
    if (friendsStore.friends) {
        friendsStore.friends.forEach((entry) => {
            const friendUserId = entry.userId;
            const friendDisplayName = entry.displayName;
            if (!friendUserId || !friendDisplayName) {
                return;
            }
            friendsList.push(_jsx(UserAvatar, { keyStr: friendUserId + ".friend.avatar", userId: friendUserId, userName: friendDisplayName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, friendUserId + ".friend.avatar"));
        });
    }
    const friendsStack = (_jsx(ContentCardStacks, { cards: friendsList, columnCount: 6 }, "friends.stack"));
    const friendsArea = !friendInviteCodeVisible && (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Friends" })), friendsStack, !friendInviteCodeVisible && (_jsx(Button, Object.assign({ variant: "contained", sx: {
                    marginTop: "15px",
                    marginBottom: "5px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    width: "auto",
                }, onClick: () => {
                    setFriendInviteCodeVisible(true);
                }, endIcon: _jsx(IosShareIcon, {}) }, { children: "SEND FRIEND INVITE LINK" })))] }), "friends.area.stack"));
    const invitesList = [];
    if (friendsStore.invites) {
        friendsStore.invites.forEach((entry) => {
            const inviteUserId = entry.userId;
            const inviteUserName = entry.displayName;
            if (!inviteUserId || !inviteUserName) {
                return;
            }
            invitesList.push(_jsxs(Stack, Object.assign({ direction: "row", padding: "5px", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(UserAvatar, { keyStr: inviteUserId + ".invite.entry.avatar", userId: inviteUserId, userName: inviteUserName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, inviteUserId + ".invite.entry.avatar"), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "success", onClick: () => {
                            acceptInvite(inviteUserId);
                        } }, { children: "ACCEPT" })), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "error", onClick: () => {
                            declineInvite(inviteUserId);
                        } }, { children: "DECLINE" }))] }), inviteUserId + ".stack.invite"));
        });
    }
    const invitesStack = (_jsx(Stack, Object.assign({ direction: "column", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: invitesList }), "invite.stack"));
    const invitesArea = (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Invites" })), invitesStack] }), "invites.area"));
    const friendChannelsList = [];
    if (friendChannelsLoadError) {
        friendChannelsList.push(_jsx(ContentCard, { keyStr: "content.card.error.friendschannelsmessage", title: "ERROR", backgroundColor: "#FFEEEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: friendChannelsLoadError })) }, "content.card.error.friendschannelsmessage"));
    }
    else if (friendChannelsLoading) {
        friendChannelsList.push(_jsx(LinearProgress, {}, "friends.channels.loading.spinner"));
    }
    else {
        friendChannelIds === null || friendChannelIds === void 0 ? void 0 : friendChannelIds.forEach((channelId) => {
            friendChannelsList.push(_jsx(ChannelCard, { keyStr: "channelcard.mychannels." + channelId, enableEditButtons: false, basePath: getContentURL(), channelJson: channelId, maxWidth: 0.22, maxResolution: true }, "channelcard.mychannels." + channelId));
        });
    }
    const friendChannelsStack = (_jsx(ContentCardStacks, { cards: friendChannelsList, columnCount: 4 }));
    const friendChannelsArea = !loading && !friendInviteCodeVisible && (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Friends Channels" })), friendChannelsStack] }), "friends.area"));
    const invitesCard = !loading && (_jsxs(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center", marginTop: "5px" } }, { children: [friendInviteCodeVisible &&
                !friendInviteCodeError &&
                !friendInviteCodeLink && _jsx(LinearProgress, {}), friendInviteCodeVisible && friendInviteCodeError && (_jsxs(Typography, Object.assign({ variant: "body1", color: "error", sx: { display: "flex", justifyContent: "center", width: "100%" } }, { children: ["ERROR: Unable to get friend invite code. ", friendInviteCodeError] }))), friendInviteCodeVisible && friendInviteCodeLink && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                            width: "100%",
                            textAlign: "left",
                            marginBottom: 0,
                            paddingBottom: 0,
                            marginLeft: "5px",
                        } }, { children: "Invite Link" })), _jsx(Stack, Object.assign({ direction: "row", sx: { width: "100%", display: "flex", justifyContent: "center" }, spacing: 1 }, { children: _jsx(TextField, { value: friendInviteCodeLink, multiline: true, sx: { width: "90%" }, onClick: () => {
                                navigator.clipboard.writeText(friendInviteCodeLink);
                                alert("Copied Link : " + friendInviteCodeLink);
                            } }) })), _jsxs(Stack, Object.assign({ direction: "row", sx: {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            padding: "10px",
                        }, spacing: 1 }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                                    navigator.clipboard.writeText(friendInviteCodeLink);
                                    alert("Copied Link : " + friendInviteCodeLink);
                                }, endIcon: _jsx(ContentCopyIcon, {}) }, { children: "COPY TO CLIPBOARD" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                                    setFriendInviteCodeVisible(false);
                                }, endIcon: _jsx(CloseIcon, {}) }, { children: "CLOSE" }))] }))] })))] })));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [_jsx(TopButtonMenu, { current: "friends" }), completedMessage && (_jsx(Card, Object.assign({ elevation: 0, sx: {
                    backgroundColor: "#EEFFEE",
                    padding: "4",
                    marginTop: "0.5em",
                }, color: "text.main" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "info", sx: { textAlign: "center" }, gutterBottom: true }, { children: "SUCCESS" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: completedMessage }))] }, "profile-completed-card-content") }), "profile-completed-card-" + loading)), friendsStore.error && (_jsx(Card, Object.assign({ elevation: 0, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: "4",
                    marginTop: "0.5em",
                }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: errorMessage }))] }, "profile-error-card-content") }), "profile-error-card-" + loading)), loading && (_jsx(LinearProgress, { sx: {
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "2px",
                } })), friendsStore.error && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: friendsStore.error }))] }, "friends-error-card-content") }), "friends-error-card")), !friendsStore.error &&
                !friendChannelsLoading &&
                !friendChannelsLoadError &&
                invitesCard, invitesList.length > 0 && invitesArea, friendsList.length > 0 && friendsArea, searchArea, (friendChannelIds || friendChannelsLoading || friendChannelsLoadError) &&
                friendChannelsArea] }), "content"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsxs(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }, maxWidth: false }, { children: [content, _jsx("br", {}), _jsx("br", {})] }), "friends.container." + forceRender), _jsx(Footer, { stickToBottom: false })] })) }), "page.div." + forceRender + loading));
    return output;
}
