var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from "zustand";
import { sendAjaxRequest } from "../API";
import { checkForSessionExpiry } from "../DataUtils";
const LOGPREFIX = "NotifStore: ";
export var ClientDetailCode;
(function (ClientDetailCode) {
    ClientDetailCode[ClientDetailCode["SUCCESS"] = 1000] = "SUCCESS";
    ClientDetailCode[ClientDetailCode["MISSING_AUTH_HEADER"] = 4001] = "MISSING_AUTH_HEADER";
    ClientDetailCode[ClientDetailCode["INVALID_AUTH_HEADER"] = 4002] = "INVALID_AUTH_HEADER";
    ClientDetailCode[ClientDetailCode["MISSING_EVENT_BODY"] = 4003] = "MISSING_EVENT_BODY";
    ClientDetailCode[ClientDetailCode["INVALID_OPERATION"] = 4004] = "INVALID_OPERATION";
    ClientDetailCode[ClientDetailCode["INVALID_SEQUENCE"] = 4005] = "INVALID_SEQUENCE";
    //  MISSING_POST_OWNER_ID = 5001,
    //  MISSING_CHANNEL_ID = 5002,
    //  MISSING_POST_ID = 5003,
    //  MISSING_COMMENT_ID = 5004,
    //  MISSING_CHANNEL_OWNER_ID = 5005,
    ClientDetailCode[ClientDetailCode["USER_LACKS_PERMISSIONS"] = 36001] = "USER_LACKS_PERMISSIONS";
    ClientDetailCode[ClientDetailCode["MISSING_RECEIVER_USER_ID"] = 36002] = "MISSING_RECEIVER_USER_ID";
    ClientDetailCode[ClientDetailCode["NOTIFICATION_QUERY_FAILED"] = 66002] = "NOTIFICATION_QUERY_FAILED";
    ClientDetailCode[ClientDetailCode["UNEXPECTED_ERROR"] = 7001] = "UNEXPECTED_ERROR";
})(ClientDetailCode || (ClientDetailCode = {}));
export const queryNotifications = (state, auth, cacheBusterStore, callback) => __awaiter(void 0, void 0, void 0, function* () {
    console.log(LOGPREFIX + "queryNotifications");
    return new Promise((resolve, reject) => {
        if (!auth || !auth.userId || !auth.bearer) {
            console.error(LOGPREFIX + "queryNotifications - user not signed in");
            reject(new Error("User is not signed in"));
            return;
        }
        try {
            const payload = {
                receiverUserId: auth.userId,
                sequence: auth.sequence,
                operation: "listNotifications",
                newerThan: 0, // TODO
            };
            console.log(LOGPREFIX +
                "queryNotifications - ajax input = " +
                JSON.stringify(payload));
            sendAjaxRequest((ok, status, message) => {
                console.log(LOGPREFIX +
                    "queryNotifications - result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearerAndSequence(response.bearer, response.sequence);
                        }
                        if (callback) {
                            callback.handleUpdate({
                                ok: true,
                                loading: false,
                                done: true,
                                notifications: response.records,
                                load: state.load,
                                reset: state.reset,
                            });
                        }
                        console.log(LOGPREFIX +
                            "queryNotifications - resolving to = " +
                            JSON.stringify(response));
                        resolve(response);
                        return;
                    }
                    else {
                        console.error(LOGPREFIX + "queryNotifications - " + message);
                        const response = JSON.parse(message);
                        checkForSessionExpiry(auth, response === null || response === void 0 ? void 0 : response.clientDetailCode);
                        if (callback) {
                            callback.handleUpdate({
                                ok: false,
                                loading: false,
                                done: true,
                                error: response.error,
                                notifications: [],
                                load: state.load,
                                reset: state.reset,
                            });
                        }
                        reject(new Error("Unable to query notifications. " +
                            response.clientDetailCode +
                            " " +
                            response.error));
                        return;
                    }
                }
                catch (err) {
                    console.error(LOGPREFIX + err);
                    if (callback) {
                        callback.handleUpdate({
                            ok: false,
                            loading: false,
                            done: true,
                            error: "" + err,
                            notifications: [],
                            load: state.load,
                            reset: state.reset,
                        });
                    }
                    reject(err);
                    return;
                }
            }, "/notifications", payload, auth.bearer, cacheBusterStore);
        }
        catch (err) {
            console.error(LOGPREFIX + err);
            if (callback) {
                callback.handleUpdate({
                    ok: false,
                    loading: false,
                    done: true,
                    error: "" + err,
                    notifications: [],
                    load: state.load,
                    reset: state.reset,
                });
            }
            reject(err);
            return;
        }
    });
});
export const useNotificationsStore = create()((set, get) => ({
    ok: false,
    loading: false,
    done: false,
    error: undefined,
    clientDetailCode: undefined,
    notifications: [],
    load: (auth, cacheBusterStore, callback) => __awaiter(void 0, void 0, void 0, function* () {
        set({ loading: true });
        const state = get();
        queryNotifications(state, auth, cacheBusterStore, callback)
            .then((response) => {
            set({
                loading: false,
                ok: true,
                notifications: response.records,
                error: undefined,
                done: true,
            });
        })
            .catch((err) => {
            set({
                loading: false,
                ok: false,
                notifications: [],
                error: "" + err,
                done: true,
            });
        });
    }),
    reset: () => {
        set({
            ok: false,
            loading: false,
            done: false,
            error: undefined,
            clientDetailCode: undefined,
            notifications: [],
        });
    },
}));
const LOCAL_STORAGE_KEY = "lastSeenNotificationTime";
export const setLastSeenNotificationTime = (notificationCreatedAt) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "" + notificationCreatedAt);
};
export const getLastSeenNotificationTime = () => {
    try {
        const lastSeen = Number(localStorage.getItem(LOCAL_STORAGE_KEY));
        return lastSeen;
    }
    catch (err) {
        console.error("Last seen notification timestamp is invalid - " + err);
        return 0;
    }
};
export const newNotificationsCount = (state) => {
    if (!state.ok) {
        return 0;
    }
    if (!state.notifications || state.notifications.length <= 0) {
        return 0;
    }
    const lastSeen = getLastSeenNotificationTime();
    const unseen = [];
    state.notifications.forEach((entry) => {
        if (!entry.createdAt) {
            return;
        }
        if (entry.createdAt > lastSeen) {
            unseen.push(entry);
        }
    });
    return unseen.length;
};
export const checkNotificationsCompatible = () => {
    if (!("serviceWorker" in navigator)) {
        console.error(LOGPREFIX + "No support for service worker!");
        return false;
    }
    if (!("Notification" in window)) {
        console.error(LOGPREFIX + "No support for notification API");
        return false;
    }
    if (!("PushManager" in window)) {
        console.error(LOGPREFIX + "No support for Push API");
        return false;
    }
    return true;
};
// eslint-dsiable-next-line @typescript-eslint/no-unused-vars
export const requestNotificationPermission = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!checkNotificationsCompatible()) {
        return false;
    }
    const permission = yield Notification.requestPermission();
    if (permission !== "granted") {
        console.error(LOGPREFIX + "Notification permission not granted");
        return false;
    }
    else {
        console.log(LOGPREFIX + "Notification permissions granted.");
        return true;
    }
});
