import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Container, Divider, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import Heading from "./Heading";
import Footer from "./Footer";
import getTheme from "./Theme";
import { useEffect, useState } from "react";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { ContentCard } from "./components/ContentCard";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { restoreSession, useProfileAuthStore, } from "./Store/ProfileStore";
import { useNavigate } from "react-router-dom";
import { NewChannelCard } from "./components/NewChannelCard";
import { channelOperation, listChannelsByOwner, } from "./DataUtils";
import { ChannelCard } from "./components/ChannelCard";
import { getChannelViewBaseURL, getContentURL } from "./ContentAPI";
import { loadFriendChannels, useFriendsStore } from "./Store/FriendStore";
import { forceLogin } from "./Auth/AuthUtils";
import { loadFrontPageDiscovery } from "./DiscoveryStore";
import { useCacheBusterStore } from "./UrlUrils";
import { useLogger } from "./logger";
import { TopButtonMenu } from "./components/TopButtonMenu";
const LOGPREFIX = "Channels: ";
export const Channels = () => {
    const [loading, setLoading] = useState();
    const [myChannelsLoading, setMyChannelsLoading] = useState();
    const [myChannelsLoaded, setMyChannelsLoaded] = useState();
    const [myChannelsLoadError, setMyChannelsLoadError] = useState();
    const [myChannelIds, setMyChannelIds] = useState();
    const [friendChannelsLoading, setFriendChannelsLoading] = useState();
    const [friendChannelsLoadError, setFriendChannelsLoadError] = useState();
    const [friendChannelIds, setFriendChannelIds] = useState([]);
    const [memberChannelIds, setMemberChannelIds] = useState([]);
    const [addNewChannelFormVisible, setAddNewChannelFormVisible] = useState();
    const [discoveryCards, setDiscoveryCards] = useState([]);
    const auth = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const friendsStore = useFriendsStore();
    const navigate = useNavigate();
    const logger = useLogger((state) => state);
    const loadMemberChannels = () => {
        logger.log(LOGPREFIX + "Channels:loadMemberChannels");
        channelOperation({
            operation: "listChannelsMembership",
        }, {
            okCallback: (clientDetailCode, data) => {
                logger.log(LOGPREFIX +
                    "Channels:loadMemberChannels: " +
                    clientDetailCode +
                    " " +
                    data);
                setLoading(false);
                const results = JSON.parse(data);
                logger.log(LOGPREFIX +
                    "Channels:loadMemberChannels results = " +
                    JSON.stringify(results));
                if (results.channelIds) {
                    results.channelIds.forEach((channelId) => {
                        if (!memberChannelIds.includes(channelId)) {
                            memberChannelIds.push(channelId);
                        }
                    });
                    setMemberChannelIds(memberChannelIds);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.error(LOGPREFIX +
                    "Channels: loadFriendChannels: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                // TODO
                // setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const doLoadFriendChannels = () => {
        loadFriendChannels(friendsStore, {
            setFriendChannelIds: (channelIds) => {
                setFriendChannelIds(channelIds);
            },
            getFriendChannelIds: () => {
                return friendChannelIds;
            },
            setLoading: (loading) => {
                setFriendChannelsLoading(loading);
            },
            isLoading: () => {
                return friendChannelsLoading ? true : false;
            },
            setErrorMessage: function (message) {
                setFriendChannelsLoadError(message);
            },
            auth: function () {
                return auth;
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        const retVal = () => { };
        loadFrontPageDiscovery({
            okCallback: (elements) => {
                setDiscoveryCards(elements.slice(0, 8));
            },
            errorCallback: (errorMessage) => {
                logger.error(LOGPREFIX +
                    "Public.useEffect.discovery load failed: " +
                    errorMessage);
            },
        }, true, navigate, cacheBusterStore, logger, auth);
        return retVal;
    }, [setDiscoveryCards]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: friendsStore");
        if (friendsStore.ok &&
            friendsStore.friends &&
            friendsStore.friends.length > 0) {
            logger.log(LOGPREFIX + "useEffect: friendsStore - loading friend channels...");
            doLoadFriendChannels();
        }
        else {
            logger.log(LOGPREFIX + "useEffect: friendsStore - not loaded");
        }
        return () => { };
    }, [friendsStore]);
    const loadMyChannels = () => {
        logger.log(LOGPREFIX + "loadMyChannels");
        if (!auth.userId) {
            logger.error(LOGPREFIX + "Missing user ID.");
            setMyChannelsLoading(false);
            setMyChannelsLoaded(true);
            return;
        }
        const input = {
            channelOwnerId: auth.userId,
            operation: "listChannelsByOwner",
        };
        const callbacks = {
            okCallback: function (clientDetailCode, data) {
                setMyChannelsLoading(false);
                setMyChannelsLoaded(true);
                try {
                    const dataObj = JSON.parse(data);
                    setMyChannelIds(dataObj === null || dataObj === void 0 ? void 0 : dataObj.channelIds);
                    logger.log(LOGPREFIX +
                        "My Channel IDs: " +
                        JSON.stringify(dataObj === null || dataObj === void 0 ? void 0 : dataObj.channelIds));
                }
                catch (err) {
                    logger.error(LOGPREFIX + err);
                    setMyChannelsLoadError(err.message);
                }
            },
            errorCallback: function (clientDetailCode, errorMessage) {
                const message = errorMessage
                    ? errorMessage + " (" + clientDetailCode + ")"
                    : "Unknown error (" + clientDetailCode + ")";
                setMyChannelsLoadError(message);
                setMyChannelsLoading(false);
                setMyChannelsLoaded(true);
            },
        };
        const authCallback = {
            isSignedIn: function () {
                return auth.isSignedIn();
            },
            bearer: function () {
                return auth.bearer;
            },
            sequence: function () {
                return auth.sequence;
            },
            setBearerAndSequence: function (bearer, sequence) {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        };
        listChannelsByOwner(input, callbacks, authCallback, cacheBusterStore);
    };
    useEffect(() => {
        const retVal = () => { };
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                logger.log(LOGPREFIX + "User NOT logged in - navigating...");
                forceLogin(navigate);
                return retVal;
            }
        }
        else {
            if (!myChannelsLoaded && !myChannelsLoading) {
                setMyChannelsLoading(true);
                loadMyChannels();
                loadMemberChannels();
            }
            if (!friendsStore.ok) {
                friendsStore.load(auth.userId, auth, cacheBusterStore).then(() => {
                    doLoadFriendChannels();
                });
            }
        }
        return retVal;
    }, [auth]);
    useEffect(() => {
        if (myChannelsLoadError) {
            setTimeout(() => {
                setMyChannelsLoadError(undefined);
            }, 4000);
        }
        return () => { };
    }, [myChannelsLoadError]);
    //  const cardsList: ReactElement[] = [];
    const addMyChannelButton = (_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
            logger.log(LOGPREFIX + "addMyChannelButton");
            setAddNewChannelFormVisible(true);
        }, sx: { margin: "8px" } }, { children: _jsxs(Stack, Object.assign({ sx: { display: "flex", alignItems: "center" } }, { children: [_jsx(AddToQueueIcon, { fontSize: "large" }), _jsx("br", {}), _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Create New Channel" }))] })) }), "add.channel.button"));
    const myChannelButtons = [];
    const memberChannelButtons = [];
    const friendChannelButtons = [];
    if (myChannelsLoadError) {
        myChannelButtons.push(_jsx(ContentCard, { keyStr: "content.card.error.mychannelsmessage", title: "ERROR", backgroundColor: "#FFEEEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: myChannelsLoadError })) }, "content.card.error.mychannelsmessage"));
    }
    else {
        myChannelIds === null || myChannelIds === void 0 ? void 0 : myChannelIds.forEach((channelId) => {
            myChannelButtons.push(_jsx(ChannelCard, { keyStr: "channelcard.mychannels." + channelId, enableEditButtons: false, basePath: getContentURL(), channelJson: channelId, maxWidth: 0.22 }, "channelcard.mychannels." + channelId));
        });
        myChannelButtons.push(addMyChannelButton);
        memberChannelIds === null || memberChannelIds === void 0 ? void 0 : memberChannelIds.forEach((channelId) => {
            memberChannelButtons.push(_jsx(ChannelCard, { keyStr: "channelcard.memberchannels." + channelId, enableEditButtons: false, basePath: getContentURL(), channelJson: channelId, maxWidth: 0.22 }, "channelcard.memberchannels." + channelId));
        });
        if (friendChannelsLoadError) {
            friendChannelButtons.push(_jsx(ContentCard, { keyStr: "content.card.error.friendchannelsmessage", title: "ERROR", backgroundColor: "#FFEEEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: myChannelsLoadError })) }, "content.card.error.friendchannelsmessage"));
        }
        else if (friendChannelsLoading) {
            friendChannelButtons.push(_jsx(LinearProgress, {}, "friendchannels.loading"));
        }
        else {
            friendChannelIds === null || friendChannelIds === void 0 ? void 0 : friendChannelIds.forEach((channelId) => {
                friendChannelButtons.push(_jsx(ChannelCard, { keyStr: "channelcard.friendchannels." + channelId, enableEditButtons: false, basePath: getContentURL(), channelJson: channelId, maxWidth: 0.22 }, "channelcard.friendchannels." + channelId));
            });
        }
    }
    let myChannelsContents = (_jsxs(Stack, Object.assign({ sx: {
            marginBottom: "10px",
            marginTop: "5px",
            marginLeft: "5px",
            marginRight: "5px",
        } }, { children: [_jsx(Divider, {}), _jsx(Typography, Object.assign({ variant: "h6", sx: { paddingLeft: "5px", paddingRight: "5px" } }, { children: "My Channels" })), _jsx(ContentCardStacks, { cards: myChannelButtons, columnCount: 4 })] }), "mychannels.contents"));
    const friendChannelsContents = (_jsxs(Stack, Object.assign({ sx: {
            marginBottom: "10px",
            marginTop: "5px",
            marginLeft: "5px",
            marginRight: "5px",
        } }, { children: [_jsx(Divider, {}), _jsx(Typography, Object.assign({ variant: "h6", sx: { paddingLeft: "5px", paddingRight: "5px" } }, { children: "Friends Channels" })), _jsx(ContentCardStacks, { cards: friendChannelButtons, columnCount: 4 })] })));
    const earlyAddButton = (_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
            logger.log(LOGPREFIX + "addMyChannelButton");
            setAddNewChannelFormVisible(true);
        }, sx: { margin: "8px" }, endIcon: _jsx(AddToQueueIcon, {}) }, { children: _jsx(Stack, Object.assign({ sx: { display: "flex", alignItems: "center" } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Create New Channel" })) })) }), "add.channel.button.early"));
    const discoveryChannelsContents = (_jsxs(Stack, Object.assign({ sx: {
            marginBottom: "10px",
            marginTop: "5px",
            marginLeft: "5px",
            marginRight: "5px",
        } }, { children: [_jsx(Divider, {}), _jsx(Typography, Object.assign({ variant: "h6", sx: { paddingLeft: "5px", paddingRight: "5px" } }, { children: "Trending Channels" })), _jsx(ContentCardStacks, { cards: discoveryCards, columnCount: 4 })] })));
    const memberChannelsContents = (_jsxs(Stack, Object.assign({ sx: {
            marginBottom: "10px",
            marginTop: "5px",
            marginLeft: "5px",
            marginRight: "5px",
        } }, { children: [_jsx(Divider, {}), _jsx(Typography, Object.assign({ variant: "h6", sx: { paddingLeft: "5px", paddingRight: "5px" } }, { children: "Member Channels" })), _jsx(ContentCardStacks, { cards: memberChannelButtons, columnCount: 4 })] })));
    if (myChannelsLoading) {
        myChannelsContents = _jsx(LinearProgress, {}, "mychannels.loading");
    }
    if (addNewChannelFormVisible && auth.userId) {
        myChannelsContents = (_jsx(NewChannelCard, { channelOwnerId: auth.userId, auth: auth, created: (newChannelName) => {
                window.location.href =
                    getChannelViewBaseURL() + encodeURIComponent(newChannelName);
            }, dismiss: () => {
                setAddNewChannelFormVisible(false);
            } }));
    }
    logger.log(LOGPREFIX +
        "Channels.render: memberChannelIds = " +
        JSON.stringify(memberChannelIds));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [_jsx(TopButtonMenu, { current: "channels" }), loading && (_jsx(LinearProgress, { sx: { width: "100%", margin: "2px" } }, "content.loading.spinner")), !loading && !addNewChannelFormVisible && earlyAddButton, !addNewChannelFormVisible && discoveryChannelsContents, myChannelsContents, !addNewChannelFormVisible &&
                memberChannelIds &&
                memberChannelIds.length > 0 &&
                memberChannelsContents, !addNewChannelFormVisible &&
                friendChannelIds &&
                friendChannelIds.length > 0 &&
                friendChannelsContents] }), "content"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }, maxWidth: false }, { children: content }), "channels.container"), _jsx(Footer, {})] })) }), "page.div.channels"));
    return output;
};
