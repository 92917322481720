import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { loadChannelDataFromURL } from "../DataUtils";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Button, Divider, LinearProgress, Stack, Typography, } from "@mui/material";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { listComments, } from "../Store/CommentsStore";
import { useProfileAuthStore } from "../Store/ProfileStore";
export const getEntryTimestampString = (entry) => {
    console.log("entry: " + entry);
    const entryTime = +entry.substring(0, entry.indexOf("-"));
    const timeIndex = 9999999999999 - entryTime;
    console.log("entry timestamp: " + timeIndex);
    const now = new Date().getTime();
    const deltaMillis = now - timeIndex;
    const deltaSecs = Math.trunc(deltaMillis / 1000);
    console.log("entry timestamp delta: " + deltaSecs);
    const deltaMins = Math.trunc(deltaSecs / 60);
    console.log("entry timestamp delta mins: " + deltaMins);
    const deltaHours = Math.trunc(deltaMins / 60);
    console.log("entry timestamp delta hours: " + deltaHours);
    const deltaDays = Math.trunc(deltaHours / 24);
    console.log("entry timestamp delta days: " + deltaDays);
    const deltaMonths = Math.trunc(deltaDays / 30);
    console.log("entry timestamp delta months: " + deltaMonths);
    let deltaStr = "";
    if (deltaMonths == 1) {
        deltaStr = "1 month";
    }
    else if (deltaMonths > 1) {
        deltaStr = Math.trunc(deltaMonths) + " months";
    }
    else if (deltaDays == 1) {
        deltaStr = Math.trunc(deltaDays) + " day";
    }
    else if (deltaDays > 1) {
        deltaStr = Math.trunc(deltaDays) + " days";
    }
    else if (deltaHours == 1) {
        deltaStr = Math.trunc(deltaHours) + " hour";
    }
    else if (deltaHours > 1) {
        deltaStr = Math.trunc(deltaHours) + " hours";
    }
    else if (deltaMins == 1) {
        deltaStr = Math.trunc(deltaMins) + " min";
    }
    else if (deltaMins > 1) {
        deltaStr = Math.trunc(deltaMins) + " mins";
    }
    else {
        deltaStr = "now";
    }
    console.log("entry timestamp delta string = " + deltaStr);
    return deltaStr;
};
export const PostCard = (props) => {
    const [postText, setPostText] = useState("");
    const [postUrl, setPostUrl] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [enableEditButtons, setEnableEditButtons] = useState(props.enableEditButtons);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [postDateString, setPostDateString] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const auth = useProfileAuthStore((state) => state);
    const loadComments = () => {
        const callbacks = {
            okCallback: (clientDetailCode, comments) => {
                console.log("loadComments: " + JSON.stringify(comments));
                setLoading(false);
            },
            errorCallback: (clientDetailCode, message) => {
                console.error("loadComments: " + message);
                setErrorMessage(message);
                setLoading(false);
            },
        };
        listComments(props.ownerId, props.channelId, props.postId, callbacks, auth);
    };
    useEffect(() => {
        console.log("useEffect: mount - postUrl");
        const url = props.basePath + "/" + props.postJson;
        if (!postUrl) {
            setPostUrl(url);
        }
        else {
            console.log("loading channel data from URL...");
            setLoading(true);
            loadChannelDataFromURL(postUrl, {
                okCallback: (clientDetailCode, data) => {
                    console.log("okCallback: post data = " + data + " - " + clientDetailCode);
                    setPostDateString(getEntryTimestampString(props.postJson));
                    try {
                        const dataObj = JSON.parse(data);
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.text) {
                            setPostText(dataObj.text);
                        }
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasImage) {
                            if (props.maxResolution) {
                                setMediaUrl(url.replace(".json", "_1024.jpg"));
                            }
                            else {
                                setMediaUrl(url.replace(".json", "_512.jpg"));
                            }
                        }
                        loadComments();
                    }
                    catch (err) {
                        console.error("post data parse error: " + err);
                        setErrorMessage(err.message);
                        setLoading(false);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    console.error("post data error: " + errorMessage + " (" + clientDetailCode + ")");
                    setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
                    setLoading(false);
                },
            });
        }
        return () => {
            console.log("useEffect: unmount - postUrl");
        };
    }, [postUrl]);
    useEffect(() => {
        console.log("useEffect: media url = " + mediaUrl);
        return () => { };
    }, [mediaUrl]);
    let text = postText;
    const postEmojis = props.emojis;
    const childComponent = (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [loading && _jsx(LinearProgress, { sx: { width: "100%" } }), !!text && (_jsx(Typography, Object.assign({ onClick: props.onClick, whiteSpace: "pre-line", variant: "body1", color: getTheme().palette.black.main, sx: {
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    wordBreak: "break-word",
                    cursor: props.onClick != null ? "pointer" : undefined,
                } }, { children: text ? text : undefined }), props.keyStr + "-typography")), _jsx(Divider, { orientation: "horizontal", flexItem: true }), _jsxs(Stack, Object.assign({ direction: "row", sx: {
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "1px",
                    paddingBottom: 0,
                    verticalAlign: "middle",
                    display: "flex",
                } }, { children: [postEmojis && postEmojis.length > 0 && (_jsxs(Typography, Object.assign({ fontSize: "large" }, { children: ["\u00A0", postEmojis[0].emoji] }))), postEmojis && postEmojis.length > 0 && (_jsxs(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", postEmojis[0].count] }))), postEmojis && postEmojis.length > 1 && (_jsxs(Typography, Object.assign({ fontSize: "large" }, { children: ["\u00A0\u00A0\u00A0", postEmojis[1].emoji] }))), postEmojis && postEmojis.length > 1 && (_jsxs(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", postEmojis[1].count] }))), postEmojis && postEmojis.length > 2 && (_jsxs(Typography, Object.assign({ fontSize: "large" }, { children: ["\u00A0\u00A0\u00A0", postEmojis[2].emoji] }))), postEmojis && postEmojis.length > 2 && (_jsxs(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", postEmojis[2].count] }))), postEmojis && postEmojis.length > 3 && (_jsxs(Typography, Object.assign({ fontSize: "large" }, { children: ["\u00A0\u00A0\u00A0", postEmojis[3].emoji] }))), postEmojis && postEmojis.length > 3 && (_jsxs(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", postEmojis[3].count] }))), postEmojis && postEmojis.length > 4 && (_jsxs(Typography, Object.assign({ fontSize: "large" }, { children: ["\u00A0\u00A0\u00A0", postEmojis[4].emoji] }))), postEmojis && postEmojis.length > 4 && (_jsxs(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", postEmojis[4].count] }))), postEmojis && postEmojis.length > 5 && (_jsx(Typography, Object.assign({ fontSize: "large" }, { children: "\u00A0\u00A0\u00A0..." }))), postEmojis && (_jsxs(Button, Object.assign({ size: "small", sx: { padding: 0, margin: 0, marginTop: "-1px" } }, { children: [_jsx(AddReactionIcon, { sx: {
                                    paddingLeft: "8px",
                                    paddingTop: "0",
                                    lineHeight: 2.2,
                                    fontSize: "20px",
                                    color: "#AAAAAA",
                                } }), _jsx(ExpandMoreIcon, { sx: {
                                    paddingLeft: "0",
                                    paddingTop: "0",
                                    lineHeight: 2.2,
                                    fontSize: "20px",
                                    color: "#AAAAAA",
                                } })] }))), _jsx(Stack, Object.assign({ sx: { flexGrow: 3, textAlign: "right" } }, { children: _jsx(Typography, Object.assign({ fontSize: "small", sx: { lineHeight: 1.9, paddingRight: "2px" }, color: "#aaaaaa" }, { children: postDateString })) }))] })), enableEditButtons &&
                !deleteClicked &&
                !deleting &&
                enableEditButtons && (_jsx(Stack, Object.assign({ direction: "row", sx: {
                    padding: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                } }, { children: _jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                        setDeleteClicked(true);
                    } }, { children: "Delete" })) }))), enableEditButtons && deleteClicked && !deleting && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx("hr", { style: { width: "100%" } }), _jsx(Typography, Object.assign({ variant: "h6", color: getTheme().palette.violet.main }, { children: "DELETE POST" })), _jsx(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main }, { children: "Are you sure?" })), _jsxs(Stack, Object.assign({ direction: "row", sx: {
                            padding: "5px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        } }, { children: [_jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                                    if (props.deletePost) {
                                        props.deletePost(props.basePath, props.postJson);
                                        setDeleteClicked(false);
                                        setDeleting(true);
                                    }
                                } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "outlined", sx: { margin: "5px" }, onClick: () => {
                                    setDeleteClicked(false);
                                } }, { children: "No" }))] }), props.keyStr + ".button.stack.row")] }))), enableEditButtons && deleting && _jsx(LinearProgress, {})] })));
    // TODO: Clean up
    //  if (enableEditButtons) {
    text = undefined;
    //  } else {
    //    childComponent = undefined;
    //  }
    if (errorMessage) {
        const errorChildComponent = (_jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.postJson + mediaUrl, title: "ERROR", childComponent: errorChildComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.postJson + mediaUrl));
    }
    const elm = (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.postJson + mediaUrl, title: "", childComponent: childComponent, mediaUrl: mediaUrl ? mediaUrl : undefined, textColor: getTheme().palette.black.main, backgroundColor: "#FFFFFF", onMediaClick: props.onClick, maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.postJson + mediaUrl));
    return elm;
};
