import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getContentURL } from "../ContentAPI";
const profileImg = "./img/profile_256.jpg";
export const UserAvatar = (props) => {
    const [imageUrl, setImageUrl] = useState();
    useEffect(() => {
        if (!imageUrl) {
            console.log("UserAvatar: checking image...");
            const imageSource = getContentURL() + "/u/" + props.userId + "/profileImage_256.jpg";
            const img = new Image();
            img.onload = () => {
                console.log("UserAvatar: image loaded");
                setImageUrl(imageSource);
            };
            img.onerror = () => {
                console.log("UserAvatar: image load failed");
                setImageUrl(profileImg);
            };
            img.src = imageSource;
        }
        return () => { };
    }, [imageUrl]);
    return (_jsxs(Stack, Object.assign({ direction: "row", marginLeft: "8px", marginRight: "8px", marginTop: "5px", marginBottom: "5px", paddingLeft: "0", paddingRight: "0", width: "auto" }, { children: [_jsx(Avatar, { alt: props.userName, src: imageUrl, sx: {
                    cursor: "pointer",
                    width: "36px",
                    height: "36px",
                    lineHeight: "40px",
                }, onClick: () => {
                    console.log("UserAvatar: onClick");
                    props.onUserClicked(props.userId);
                } }, props.keyStr + ".avatar"), props.userName && (_jsx(Typography, Object.assign({ variant: "body1", fontSize: "large", sx: {
                    lineHeight: "35px",
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                }, onClick: () => {
                    console.log("UserAvatar: onClick");
                    props.onUserClicked(props.userId);
                } }, { children: props.userName })))] }), props.keyStr + ".stack"));
};
