import { getDeviceInfo } from "./DeviceInfo";
export const getLayoutInfo = () => {
    const deviceInfo = getDeviceInfo();
    let cardMargin = "35px";
    let cardPadding = "50px";
    let cardContentMargin = "20px";
    let cardContentPadding = "10px";
    let videoWidth = "70%";
    let sidePadding = "60px";
    if (deviceInfo.isDesktopOrLaptop || deviceInfo.isBigScreen) {
        // Big Screen
        cardMargin = "10px";
        cardPadding = "10px";
        cardContentMargin = "20px";
        cardContentPadding = "10px";
        videoWidth = "70%";
        sidePadding = "10%";
    }
    else if (deviceInfo.isTabletOrMobile) {
        if (deviceInfo.isPortrait) {
            // Portrait Mobile
            cardMargin = "5px";
            cardPadding = "5px";
            cardContentMargin = "0px";
            cardContentPadding = "5px";
            videoWidth = "100%";
            sidePadding = "10px";
        }
        else {
            // Landscape Mobile
            cardMargin = "10px";
            cardPadding = "10px";
            cardContentMargin = "10px";
            cardContentPadding = "10px";
            videoWidth = "80%";
            sidePadding = "5%";
        }
    }
    return {
        cardMargin,
        cardPadding,
        cardContentMargin,
        cardContentPadding,
        videoWidth,
        sidePadding,
    };
};
