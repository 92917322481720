import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Button, Container, ThemeProvider } from "@mui/material";
import getTheme from "./Theme";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { getContentURL } from "./ContentAPI";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { ContentCard } from "./components/ContentCard";
import { getLastFriendImageUrl } from "./Store/FriendStore";
const feedDefaultImage = "./img/feed_512.jpg";
const profileDefaultImage = "./img/profile_512.jpg";
const channelsImg = "./img/channels_512.jpg";
const friendsImg = "./img/friends_512.jpg";
const messagingImg = "./img/messaging_512.jpg";
const wordcloudImg = "./img/wordcloud_512.jpg";
export function Home() {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [width, setWidth] = useState(0);
    const [feedImage, setFeedImage] = useState();
    const [profileImage, setProfileImage] = useState();
    const auth = useProfileAuthStore((state) => state);
    const [forceRender, setForceRender] = useState(new Date().getTime());
    useEffect(() => {
        if (!feedImage) {
            setFeedImage(getContentURL() +
                "/u/" +
                auth.userId +
                "/" +
                auth.userId +
                "/feedImage_512.jpg");
            setForceRender(forceRender + 1);
        }
        return () => { };
    }, [feedImage, auth]);
    useEffect(() => {
        if (!profileImage) {
            setProfileImage(getContentURL() + "/u/" + auth.userId + "/profileImage_512.jpg");
            setForceRender(forceRender + 1);
        }
        return () => { };
    }, [profileImage, auth]);
    useEffect(() => {
        //    console.log("useEffect: auth - mount");
        if (auth.isSignedIn() && auth.userId) {
            console.log("useEffect: auth - user signed in - " + auth.userId);
            // TODO: Need hi-res image to replace profile stock photo
            //      if (profileImageRef.current) {
            //        profileImageRef.current.src = getContentURL() + "/u/" + auth.userId + "/feedImage_512.jpg";
            //      }
        }
        return () => {
            //    console.log("useEffect: auth - unmount");
        };
    }, [auth]);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setWidth]);
    const feedButton = (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0 }, onClick: () => {
            navigate("/Feed");
        } }, { children: _jsx(ContentCard, { keyStr: "home.feedbutton.card", title: "Feed", backgroundColor: "#FFFFFF", mediaUrl: feedImage, mediaFallbackUrl: feedDefaultImage, textColor: getTheme().palette.violet.main }, "home.feedbutton.card") }), "home.feedbutton." + forceRender));
    let friendsButtonImage = getLastFriendImageUrl();
    if (!friendsButtonImage) {
        friendsButtonImage = friendsImg;
    }
    const friendsButton = (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0 }, onClick: () => {
            navigate("/Friends");
        } }, { children: _jsx(ContentCard, { keyStr: "home.friendsbutton.card", backgroundColor: "#FFFFFF", textColor: "#FFFFFF", mediaUrl: friendsButtonImage, mediaFallbackUrl: friendsImg, title: "Friends" }, "home.friendsbutton.card") }), "home.friendsbutton"));
    const channelsButton = (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0 }, onClick: () => {
            navigate("/Channels");
        } }, { children: _jsx(ContentCard, { keyStr: "home.channelsbutton.card", mediaUrl: channelsImg, title: "Channels", textColor: "#FFFFFF", backgroundColor: "#FFFFFF" }, "home.channelsbutton.card") }), "home.channelsbutton"));
    const messagingButton = (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0 }, onClick: () => {
            navigate("/Messaging");
        } }, { children: _jsx(ContentCard, { keyStr: "home.messagingbutton.card", backgroundColor: "#FFFFFF", textColor: "#FFFFFF", mediaUrl: messagingImg, title: "Messaging" }, "home.messagingbutton.card") }), "home.messagingbutton"));
    const profileButton = (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0 }, onClick: () => {
            navigate("/Profile");
        } }, { children: _jsx(ContentCard, { keyStr: "home.profilebutton.card", backgroundColor: "#FFFFFF", textColor: "#FFFFFF", mediaUrl: profileImage, mediaFallbackUrl: profileDefaultImage, title: "Profile" }, "home.profilebutton.card") }), "home.profilebutton." + forceRender));
    const aboutButton = (_jsx(Button, Object.assign({ sx: { textTransform: "none", margin: 0, padding: 0 }, onClick: () => {
            navigate("/About");
        } }, { children: _jsx(ContentCard, { keyStr: "home.aboutbutton.card", backgroundColor: "#FFFFFF", textColor: "#FFFFFF", mediaUrl: wordcloudImg, title: "About Us" }, "home.aboutbutton.card") }), "home.aboutbutton"));
    const cards = [
        feedButton,
        channelsButton,
        messagingButton,
        friendsButton,
        profileButton,
        aboutButton,
    ];
    const content = _jsx(ContentCardStacks, { cards: cards }, "home.stack");
    const output = (_jsx("div", Object.assign({ style: { width: "100%", height: "100%", padding: 0, margin: 0 } }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    }, maxWidth: false }, { children: content })), _jsx(Footer, {})] })) }), "main.div." + forceRender));
    return output;
}
