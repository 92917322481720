import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Button, Card, CardActions, CardContent, Divider, LinearProgress, Stack, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getLastSeenNotificationTime, setLastSeenNotificationTime, useNotificationsStore, } from "../Store/NotificationsStore";
import { UserAvatar } from "./UserAvatar";
import { getDeltaTimestampString } from "../DateTimeUtils";
import { getLayoutInfo } from "../LayoutInfo";
import { getContentURL } from "../ContentAPI";
const LOGPREFIX = "NotifCards: ";
export const NotificationsCard = (props) => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [notifications, setNotifications] = useState();
    const notificationsStore = useNotificationsStore((state) => state);
    const navigate = useNavigate();
    useEffect(() => {
        const retVal = () => { };
        console.log(LOGPREFIX + "useEffect");
        if (!notificationsStore.ok &&
            !notificationsStore.done &&
            !notificationsStore.loading) {
            console.log(LOGPREFIX + "useEffect - store requires load. Loading...");
            notificationsStore.load(props.auth, props.cb, {
                handleUpdate: (state) => {
                    console.log(LOGPREFIX + "useEffect - store update = " + JSON.stringify(state));
                    if (state.ok) {
                        setNotifications(state.notifications);
                    }
                    else {
                        setErrorMessage(state.error);
                    }
                },
            });
            return retVal;
        }
        if (notificationsStore.ok) {
            console.log(LOGPREFIX + "useEffect - store ok - setting notifications");
            setNotifications(notificationsStore.notifications);
            notificationsStore.notifications.forEach((entry) => {
                if (!entry.notificationId) {
                    return;
                }
                if (entry.notificationType == "comment") {
                    let imageUrl = getContentURL() +
                        "/u/" +
                        entry.channelOwnerId +
                        "/" +
                        entry.channelId +
                        "/" +
                        entry.postId;
                    if (imageUrl.endsWith(".json")) {
                        imageUrl = imageUrl.replace(".json", "_512.jpg");
                    }
                    else {
                        imageUrl = imageUrl + "_512.jpg";
                    }
                }
            });
        }
        if (notificationsStore.error) {
            console.log(LOGPREFIX + "useEffect - store error - setting error");
            setErrorMessage(notificationsStore.error);
        }
        return () => { };
    }, [notificationsStore]);
    const notifElements = [];
    let text = "";
    notifications === null || notifications === void 0 ? void 0 : notifications.forEach((entry) => {
        let newNotification = false;
        if (entry.createdAt && entry.createdAt > getLastSeenNotificationTime()) {
            newNotification = true;
            setLastSeenNotificationTime(entry.createdAt);
        }
        let imageUrl;
        let navUrl;
        switch (entry.notificationType) {
            case "comment": {
                text = entry.fromUserDisplayName + " commented on your post.";
                imageUrl =
                    getContentURL() +
                        "/u/" +
                        entry.channelOwnerId +
                        "/" +
                        entry.channelId +
                        "/" +
                        entry.postId;
                if (imageUrl.endsWith(".json")) {
                    imageUrl = imageUrl.replace(".json", "_512.jpg");
                }
                else {
                    imageUrl = imageUrl + "_512.jpg";
                }
                navUrl =
                    "/PostView?channelOwnerId=" +
                        entry.channelOwnerId +
                        "&channelId=" +
                        entry.channelId +
                        "&postOwnerId=" +
                        entry.postOwnerId +
                        "&postId=" +
                        entry.postId;
                break;
            }
            case "commentReply": {
                text = entry.fromUserDisplayName + " also commented on a post.";
                imageUrl =
                    getContentURL() +
                        "/u/" +
                        entry.channelOwnerId +
                        "/" +
                        entry.channelId +
                        "/" +
                        entry.postId;
                if (imageUrl.endsWith(".json")) {
                    imageUrl = imageUrl.replace(".json", "_512.jpg");
                }
                else {
                    imageUrl = imageUrl + "_512.jpg";
                }
                navUrl =
                    "/PostView?channelOwnerId=" +
                        entry.channelOwnerId +
                        "&channelId=" +
                        entry.channelId +
                        "&postOwnerId=" +
                        entry.postOwnerId +
                        "&postId=" +
                        entry.postId;
                break;
            }
            case "friendAccept": {
                text = entry.fromUserDisplayName + " is now your friend.";
                imageUrl = undefined;
                navUrl = "/ProfileView?userId=" + entry.fromUserId;
                break;
            }
            case "friendInvite": {
                text = entry.fromUserDisplayName + " wants to be friends.";
                imageUrl = undefined;
                navUrl = "/Friends";
                break;
            }
            default: {
                console.error("Unsupported notification type: " + entry.notificationType);
                return;
            }
        }
        const timeDelta = entry.createdAt
            ? "(" + getDeltaTimestampString(entry.createdAt) + ")"
            : "";
        notifElements.push(_jsxs(Stack, Object.assign({ direction: "column", sx: {
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: newNotification ? "#EEFFEE" : "inherit",
            } }, { children: [_jsx(Button, Object.assign({ sx: { textTransform: "none" }, onClick: () => {
                        if (navUrl) {
                            navigate(navUrl);
                        }
                    } }, { children: _jsxs(Stack, Object.assign({ direction: "row", sx: {
                            flexBasis: "auto",
                            justifyContent: "center",
                            position: "relative",
                            flexgrow: "1",
                            flex: "0 1 auto",
                            display: "flex",
                        } }, { children: [imageUrl && (_jsx("img", { src: imageUrl, height: "46px", onError: (event) => {
                                    event.target.onerror = () => { };
                                    event.target.src =
                                        "/img/channels_512.jpg";
                                } })), _jsx(UserAvatar, { keyStr: "user.avatar." + entry.notificationId, userId: entry.fromUserId, userName: entry.fromUserDisplayName ? entry.fromUserDisplayName : "", onUserClicked: function (userId) {
                                    window.open("/ProfileView?userId=" + userId, "_blank");
                                }, userNameVisible: false, auth: props.auth }), _jsx(Typography, Object.assign({ variant: "body1", sx: { marginTop: "11px" } }, { children: text })), _jsx(Typography, Object.assign({ variant: "body2", sx: { marginTop: "14px", marginLeft: "5px", fontSize: "small" }, color: "#999999" }, { children: timeDelta }))] }), "inner.notif.cell" + entry.notificationId) })), _jsx(Divider, {})] }), "outer.notif.cell" + entry.notificationId));
    });
    let childComponent = (_jsx(Stack, Object.assign({ direction: "column" }, { children: notifElements }), "notifications.list.stack"));
    /*
    const actions = (
      <CardActions sx={{ display: "inline", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/Feed");
          }}
          sx={{ whiteSpace: "nowrap", margin: "5px" }}
        >
          GO TO MY FEED
        </Button>
      </CardActions>
    );
    */
    if (errorMessage) {
        const layout = getLayoutInfo();
        childComponent = (_jsxs(Card, Object.assign({ elevation: 10, sx: {
                backgroundColor: "#FFEEEE",
                padding: layout.cardPadding,
                margin: layout.cardMargin,
                alignContent: "center",
                textAlign: "center",
            } }, { children: [_jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error" }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: errorMessage }))] }), "channelview.content.cardcontent.error"), _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                            window.location.reload();
                        } }, { children: "RETRY" })) }))] }), "content.card.error"));
    }
    else if (notificationsStore.loading) {
        childComponent = _jsx(LinearProgress, { sx: { width: "100%", margin: "2px" } });
    }
    const elm = (_jsx(ContentCard, { keyStr: "notifs.card." + props.keyStr, title: "", 
        //      header={header}
        childComponent: childComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFFFFF", 
        //      onMediaClick={doClick}
        maxWidth: 0.5, maxMediaWidth: window.innerWidth, maxMediaHeight: window.innerHeight }, "notifs.card." +
        props.keyStr +
        (notifications ? notifications.length : 0)));
    return elm;
};
