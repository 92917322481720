import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Container, ThemeProvider } from "@mui/material";
import getTheme from "./Theme";
import { ContactUs } from "./components/ContactUs";
export function ContactUsPage() {
    const content = _jsx(ContactUs, { keyStr: "contactuspage" });
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF", padding: "20px", margin: 0 }, maxWidth: false }, { children: content })), _jsx(Footer, {})] })) })));
    return output;
}
