import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Container, Divider, LinearProgress, Stack, TextField, ThemeProvider, Typography, } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { useEffect, useState } from "react";
import { getChannelId, getChannelViewBaseURL, getContentURL, } from "./ContentAPI";
import { CHANNEL_MEMBERSHIP_MEMBER, CHANNEL_MEMBERSHIP_NOT_MEMBER, DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED, deleteChannel, deletePost, channelOperation, loadChannelData, SESSION_EXPIRED_ERROR_CODE, CHANNEL_MEMBERSHIP_REQUESTED, CHANNEL_MEMBERSHIP_DENIED, } from "./DataUtils";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { ContentCard } from "./components/ContentCard";
import { PostCard } from "./components/PostCard";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { useNavigate } from "react-router-dom";
import { LoadMoreButton } from "./components/LoadMoreButton";
import { UserAvatar } from "./components/UserAvatar";
import { useFriendsStore } from "./Store/FriendStore";
import { NewPostCard } from "./components/NewPostCard";
import { setLastChannelImageUrl } from "./Store/ChannelStore";
import { authUrl, useCacheBusterStore } from "./UrlUrils";
import { AddCircleOutline, DeleteForever } from "@mui/icons-material";
import { forceLogin } from "./Auth/AuthUtils";
import { EditChannelCard } from "./components/EditChannelCard";
import { useLogger } from "./logger";
const LOGPREFIX = "ChannelView: ";
const channelsImg = "./img/channels_512.jpg";
export function ChannelView() {
    const layout = getLayoutInfo();
    const [searchName, setSearchName] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [channelOwnerId, setChannelOwnerId] = useState();
    const [channelId, setChannelId] = useState();
    const [channelTitle, setChannelTitle] = useState();
    const [channelSubtitle, setChannelSubtitle] = useState();
    const [channelDescription, setChannelDescription] = useState();
    const [channelImageUrl, setChannelImageUrl] = useState();
    const [membershipPrompt, setMembershipPrompt] = useState();
    const [postIds, setPostIds] = useState([]);
    const [hasMorePosts, setHasMorePosts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [feedRequiresFriend, setFeedRequiresFriend] = useState(false);
    const [deletePromptVisible, setDeletePromptVisible] = useState(false);
    const [deletedOK, setDeletedOK] = useState(false);
    const [postDeletedOK, setPostDeletedOK] = useState(false);
    const [editChannelVisible, setEditChannelVisible] = useState();
    const [permissionsAdultContent, setPermissionsAdultContent] = useState(false);
    const [permissionsRequiresFriend, setPermissionsRequiresFriend] = useState(false);
    const [permissionsAllowsComments, setPermissionsAllowsComments] = useState(false);
    const [permissionsAllowsPosts, setPermissionsAllowsPosts] = useState(false);
    const [needsToBeFriends, setNeedsToBeFriends] = useState(false);
    const [memberStatus, setMemberStatus] = useState(CHANNEL_MEMBERSHIP_NOT_MEMBER);
    const [channelRequiresMembership, setChannelRequiresMembership] = useState(false);
    const [joinChannelVisible, setJoinChannelVisible] = useState(false);
    const [membershipPromptAnswer, setMembershipPromptAnswer] = useState();
    const [joinChannelRequestedSuccess, setJoinChannelRequestedSuccess] = useState(false);
    const [channelMembershipUpdatedSuccess, setChannelMembershipUpdatedSuccess] = useState(false);
    const [channelRequests, setChannelRequests] = useState();
    const logger = useLogger((state) => state);
    const auth = useProfileAuthStore((state) => state);
    const friends = useFriendsStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const navigate = useNavigate();
    useEffect(() => {
        if (postDeletedOK) {
            setTimeout(() => {
                setPostDeletedOK(false);
                location.reload();
            }, 3000);
        }
        return () => { };
    }, [postDeletedOK]);
    const deletePostParent = (basePath, postJson) => {
        logger.log(LOGPREFIX + "deletePostParent: " + basePath + " " + postJson);
        if (!(auth === null || auth === void 0 ? void 0 : auth.userId)) {
            setErrorMessage(LOGPREFIX + "User is not logged in.");
            return;
        }
        if (!channelOwnerId) {
            setErrorMessage(LOGPREFIX + "Channel owner is not set.");
            return;
        }
        deletePost({
            operation: "deletePost",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            postOwnerId: auth.userId,
            postId: postJson,
        }, {
            okCallback: (clientDetailCode) => {
                logger.log(LOGPREFIX + "deletePost: OK " + clientDetailCode);
                setPostDeletedOK(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "deletePost: ERROR " +
                    clientDetailCode +
                    " " +
                    errorMessage);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const doDeleteChannel = () => {
        logger.log(LOGPREFIX + "doDeleteChannel");
        setDeletePromptVisible(false);
        setErrorMessage(undefined);
        if (!channelOwnerId || !channelId || !channelTitle) {
            logger.error(LOGPREFIX + "missing channel owner ID or channel ID or channel title.");
            return;
        }
        setLoading(true);
        deleteChannel({
            operation: "deleteChannel",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            channelData: {
                channelOwnerId: channelOwnerId,
                channelId: channelId,
                title: channelTitle,
                description: "",
            },
        }, {
            okCallback: function (clientDetailCode, data) {
                logger.log(LOGPREFIX + "doDelete: deleted ok " + clientDetailCode + " " + data);
                setLoading(false);
                setDeletedOK(true);
            },
            errorCallback: function (clientDetailCode, errorMessage) {
                logger.error(LOGPREFIX + "doDelete: " + clientDetailCode + " " + errorMessage);
                setLoading(false);
                setDeletedOK(false);
                let message = "Unknown error " + clientDetailCode;
                if (errorMessage) {
                    message = errorMessage;
                }
                setErrorMessage(message);
            },
        }, {
            isSignedIn: function () {
                return auth.isSignedIn();
            },
            bearer: function () {
                return auth.bearer;
            },
            sequence: function () {
                return auth.sequence;
            },
            setBearerAndSequence: function (bearer, sequence) {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    /*
    useEffect(() => {
      if (errorMessage) {
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 5000);
      }
  
      return () => {};
    }, [errorMessage]);
    */
    useEffect(() => {
        if (channelMembershipUpdatedSuccess) {
            setTimeout(() => {
                setChannelMembershipUpdatedSuccess(false);
                loadChannelMemberRequests();
            }, 3000);
        }
        return () => { };
    }, [channelMembershipUpdatedSuccess]);
    useEffect(() => {
        if (joinChannelRequestedSuccess) {
            setTimeout(() => {
                setJoinChannelRequestedSuccess(false);
                setMemberStatus(CHANNEL_MEMBERSHIP_REQUESTED);
                loadChannelId();
            }, 5000);
        }
        return () => { };
    }, [joinChannelRequestedSuccess]);
    const loadChannelId = () => {
        logger.log(LOGPREFIX + "loadChannelId");
        if (!searchName) {
            logger.error(LOGPREFIX + "search name not set.");
            return;
        }
        setLoading(true);
        getChannelId((ok, status, response) => {
            var _a;
            logger.log(LOGPREFIX +
                "loadChannelId.getChannelId response = " +
                ok +
                " " +
                JSON.stringify(response));
            if (!ok || !(status >= 200 && status < 300)) {
                let errorMessage = "Unable to find Channel with name " + searchName + ".";
                if (response === null || response === void 0 ? void 0 : response.error) {
                    errorMessage = response === null || response === void 0 ? void 0 : response.error;
                }
                if (response === null || response === void 0 ? void 0 : response.clientDetailCode) {
                    errorMessage += " (" + (response === null || response === void 0 ? void 0 : response.clientDetailCode) + ")";
                }
                if (searchName == auth.displayName) {
                    errorMessage =
                        "Your Feed channel will only be visible, once you save your Feed Settings.";
                }
                logger.error(LOGPREFIX + status + " " + errorMessage);
                setErrorMessage(errorMessage);
                setLoading(false);
                return;
            }
            else if (!response || !response.channelId) {
                logger.error(LOGPREFIX + status + " " + errorMessage);
                setErrorMessage(errorMessage);
                setLoading(false);
                return;
            }
            else if (!response.channelId || !response.channelId.trim()) {
                logger.error(LOGPREFIX + "Channel ID missing from response.");
                setErrorMessage("Invalid response received from server.");
                setLoading(false);
                return;
            }
            else if (!response.channelName || !response.channelName.trim()) {
                logger.error(LOGPREFIX + "Channel name missing from response.");
                setErrorMessage("Invalid response received from server.");
                setLoading(false);
                return;
            }
            else {
                logger.log(LOGPREFIX + "getChannelId OK = " + JSON.stringify(response));
                setLoading(false);
                setErrorMessage(undefined);
                setChannelId(response === null || response === void 0 ? void 0 : response.channelId);
                setChannelOwnerId(response === null || response === void 0 ? void 0 : response.channelOwnerId);
                setChannelTitle(response === null || response === void 0 ? void 0 : response.channelName);
                setChannelSubtitle(response === null || response === void 0 ? void 0 : response.subtitle);
                if (response === null || response === void 0 ? void 0 : response.private) {
                    if ((response === null || response === void 0 ? void 0 : response.channelOwnerId) == (response === null || response === void 0 ? void 0 : response.channelId) &&
                        (response === null || response === void 0 ? void 0 : response.channelId)) {
                        logger.log(LOGPREFIX + "private feed channel");
                        setChannelRequiresMembership(false);
                        let isFriend = false;
                        if (friends.ok) {
                            (_a = friends.friends) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                                if (entry.userId == (response === null || response === void 0 ? void 0 : response.channelOwnerId)) {
                                    isFriend = true;
                                }
                            });
                        }
                        if (!isFriend && auth.userId != (response === null || response === void 0 ? void 0 : response.channelOwnerId)) {
                            logger.log(LOGPREFIX + "private feed channel - not friend");
                            setFeedRequiresFriend(true);
                            setPostIds([]);
                            setHasMorePosts(false);
                            logger.log(LOGPREFIX + "init-1.5 OK");
                            return;
                        }
                        else {
                            setFeedRequiresFriend(false);
                        }
                    }
                    else {
                        setChannelRequiresMembership(true);
                    }
                }
                /*
                const postIds: string[] = [];
      
                response?.postIds?.forEach((entry) => {
                  postIds.push(entry);
                });
      
                logger.log("Post IDs = " + JSON.stringify(postIds));
                setPostIds(postIds);
      
                logger.log("Has More Posts = " + response?.hasMorePosts);
                setHasMorePosts(response?.hasMorePosts ? true : false);
                */
                logger.log(LOGPREFIX + "init-1 OK");
            }
        }, searchName, cacheBusterStore);
    };
    useEffect(() => {
        if (!searchName) {
            const path = window.location.search
                ? window.location.search
                : window.location.href;
            logger.log(LOGPREFIX + "mount: path = " + path);
            let capturedChannelOwnerId;
            let capturedChannelId;
            if (!path.includes("@")) {
                const paramString = path.substring(1, path.length);
                const params = paramString.split("&");
                params.forEach((param) => {
                    const fields = param.split("=");
                    if (fields.length == 2) {
                        const key = fields[0];
                        const value = fields[1];
                        if ("channelOwnerId" == key) {
                            setChannelOwnerId(value);
                            capturedChannelOwnerId = value;
                        }
                        else if ("channelId" == key) {
                            setChannelId(value);
                            capturedChannelId = value;
                        }
                    }
                });
                if (capturedChannelOwnerId && capturedChannelId) {
                    return () => { };
                }
                setErrorMessage("Channel name must contain '@' character. Please check the URL.");
                return () => { };
            }
            const decodedPath = decodeURI(path);
            const searchName = decodedPath.substring(decodedPath.lastIndexOf("@") + 1, decodedPath.length);
            logger.log(LOGPREFIX + "mount: searchName = " + searchName);
            setSearchName(searchName);
            logger.log(LOGPREFIX + "init-0 OK");
        }
        else {
            if (searchName && !loading && !errorMessage && !channelOwnerId) {
                loadChannelId();
            }
        }
        return () => { };
    }, [searchName, channelOwnerId]);
    const loadChannelMemberRequests = () => {
        logger.log(LOGPREFIX + "loadChannelMemberRequests");
        if (!channelOwnerId) {
            logger.error(LOGPREFIX + "chanel owner not set");
            return;
        }
        setLoading(true);
        channelOperation({
            operation: "listChannelMembersByStatus",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            membershipStatus: CHANNEL_MEMBERSHIP_REQUESTED,
        }, {
            okCallback: (clientDetailCode, data) => {
                logger.log(LOGPREFIX + "loadChannelMemberRequests: " + data);
                setLoading(false);
                const results = JSON.parse(data);
                logger.log(LOGPREFIX +
                    "loadChannelMemberRequests results = " +
                    JSON.stringify(results));
                if (results.memberRecords) {
                    setChannelRequests(results.memberRecords);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "loadChannelMemberRequests: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                setLoading(false);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        if (channelOwnerId && channelId) {
            logger.log(LOGPREFIX + "useEffect: channelOwnerId and channelId set");
            loadMorePosts();
            if (auth && auth.userId == channelOwnerId) {
                loadChannelMemberRequests();
            }
        }
        return () => { };
    }, [channelOwnerId, channelId]);
    const doJoinChannel = () => {
        setJoinChannelVisible(false);
        logger.log(LOGPREFIX + "doJoinChannel: prompt answer: " + membershipPromptAnswer);
        if (!channelOwnerId) {
            logger.error(LOGPREFIX + "channel owner ID not set.");
            return;
        }
        setLoading(true);
        channelOperation({
            operation: "setChannelMemberStatus",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            membershipStatus: CHANNEL_MEMBERSHIP_REQUESTED,
            membershipPromptAnswer: membershipPromptAnswer,
            membershipStatusTarget: auth.userId,
        }, {
            okCallback: (clientDetailCode, data) => {
                logger.log(LOGPREFIX + "doJoinChannel: " + data);
                setLoading(false);
                const results = JSON.parse(data);
                logger.log(LOGPREFIX + "doJoinChannel results = " + JSON.stringify(results));
                setJoinChannelRequestedSuccess(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "doJoinChannel: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                setLoading(false);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const doLoadChannelData = () => {
        logger.log(LOGPREFIX + "doLoadChannelData");
        const callbacks = {
            okCallback: (clientDetailCode, data) => {
                var _a, _b, _c;
                logger.log("ChannelView.doLoadChannelData: Channel Data = " + data);
                try {
                    const dataObj = JSON.parse(data);
                    const dataTitle = dataObj.title;
                    const dataSubtitle = dataObj.subtitle;
                    const dataDescription = dataObj.description;
                    const channelImageUrl = getContentURL() +
                        "/u/" +
                        channelOwnerId +
                        "/" +
                        channelId +
                        "/channelImage_512.jpg";
                    if (dataObj.private) {
                        setPermissionsRequiresFriend(true);
                    }
                    else {
                        setPermissionsRequiresFriend(false);
                    }
                    if ((_a = dataObj.permissions) === null || _a === void 0 ? void 0 : _a.permissionsAllowsComments) {
                        setPermissionsAllowsComments(true);
                    }
                    else {
                        setPermissionsAllowsComments(false);
                    }
                    if ((_b = dataObj.permissions) === null || _b === void 0 ? void 0 : _b.permissionsAllowsPosts) {
                        setPermissionsAllowsPosts(true);
                    }
                    else {
                        setPermissionsAllowsPosts(false);
                    }
                    if ((_c = dataObj.permissions) === null || _c === void 0 ? void 0 : _c.permissionsAdultContent) {
                        setPermissionsAdultContent(true);
                    }
                    else {
                        setPermissionsAdultContent(false);
                    }
                    if (dataObj.membershipPrompt) {
                        setMembershipPrompt(dataObj.membershipPrompt);
                    }
                    else {
                        setMembershipPrompt(undefined);
                    }
                    if (!auth || !auth.isSignedIn()) {
                        if (dataObj.private) {
                            logger.log("Private Channel. Redirect to login...");
                            forceLogin(navigate);
                            return;
                        }
                    }
                    setChannelImageUrl(channelImageUrl);
                    setChannelTitle(dataTitle);
                    setChannelSubtitle(dataSubtitle);
                    setChannelDescription(dataDescription);
                    if (auth.isSignedIn() &&
                        auth.userId != channelOwnerId &&
                        channelImageUrl) {
                        setLastChannelImageUrl(channelImageUrl);
                    }
                    logger.log("ChannelView: init-2 OK");
                }
                catch (err) {
                    setErrorMessage("ERROR: " + err);
                }
                finally {
                    setLoading(false);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.error(clientDetailCode + " " + errorMessage);
                if (clientDetailCode == SESSION_EXPIRED_ERROR_CODE) {
                    logger.log("Session Expired. Redirect to login...");
                    setTimeout(() => {
                        forceLogin(navigate);
                    }, 2000);
                }
                if (clientDetailCode == DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED) {
                    setErrorMessage("No posts found on this channel. Please check the URL.");
                }
                else {
                    setErrorMessage(errorMessage);
                }
                setLoading(false);
            },
        };
        if (channelId && channelOwnerId) {
            setLoading(true);
            loadChannelData("u", channelOwnerId, channelId, "channelData", "json", callbacks, auth, cacheBusterStore);
        }
    };
    useEffect(() => {
        logger.log(LOGPREFIX +
            "mount ownerId=" +
            channelOwnerId +
            ", channelId=" +
            channelId);
        if (channelId && channelOwnerId) {
            logger.log(LOGPREFIX + "mount owner and channel ID set - loading channel data...");
            doLoadChannelData();
        }
        return () => {
            logger.log(LOGPREFIX + "unmount - channelId");
        };
    }, [channelOwnerId, channelId]);
    useEffect(() => {
        logger.log(LOGPREFIX +
            "useEffect - permissionsRequiresFriend = " +
            permissionsRequiresFriend);
        if (channelOwnerId &&
            channelOwnerId == channelId &&
            permissionsRequiresFriend) {
            if (!auth.isSignedIn()) {
                logger.log(LOGPREFIX + "Private Feed Channel. Redirect to login...");
                forceLogin(navigate);
            }
            else if (auth.userId == channelOwnerId) {
                setLoading(false);
            }
            else if (!friends.ok &&
                !friends.done &&
                !friends.loading &&
                !friends.error) {
                // Friends not loaded
                logger.log(LOGPREFIX + "Loading friends of " + channelOwnerId + "...");
                friends.load(channelOwnerId, auth, cacheBusterStore).then(() => {
                    if (friends.ok && friends.friends) {
                        logger.log(LOGPREFIX + "init-3 OK");
                        let found = false;
                        friends.friends.forEach((entry) => {
                            if (entry.userId == auth.userId) {
                                found = true;
                                logger.log(LOGPREFIX + "Found match - friends");
                            }
                        });
                        if (!found) {
                            logger.log(LOGPREFIX + "NOT found match - NOT friends");
                            setNeedsToBeFriends(true);
                        }
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        throw new Error("Friends loaded ok, but invalid state.");
                    }
                });
            }
        }
        return () => {
            logger.log(LOGPREFIX + "unmount - permissionsRequiresFriend");
        };
    }, [friends, permissionsRequiresFriend, channelOwnerId]);
    //let adScript;
    //if (postIds && channelOwnerId && channelId) {
    /*
      adScript = <div>
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3680816698886183"
     crossOrigin="anonymous"></script>
  <ins className="adsbygoogle"
     style={{display:"block"}}
     data-ad-format="fluid"
     data-ad-layout-key="-6t+ed+2i-1n-4w"
     data-ad-client="ca-pub-3680816698886183"
     data-ad-slot="7412012817"></ins>
  <script>
     (adsbygoogle = window.adsbygoogle || []).push({});
  </script></div>; */
    //<Typography variant="body1">ADVERTISEMENT</Typography>
    /*
      adScript = (
        <div
          style={{ width: "auto", textAlign: "center" }}
          id="channel-view-display-adslot"
        >
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3680816698886183"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3680816698886183"
            data-ad-slot="7886826975"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div>
      );
      */
    //}
    const canUserSeePosts = () => {
        var _a;
        if (channelOwnerId == auth.userId) {
            logger.log(LOGPREFIX + "canUserSeePosts: 0 - channel owner");
            return true;
        }
        if (auth.accountType == "admin") {
            logger.log(LOGPREFIX + "canUserSeePosts: 1 - admin user");
            return true;
        }
        if (channelOwnerId == channelId) {
            logger.log(LOGPREFIX + "canUserSeePosts: feed page");
            if (!permissionsRequiresFriend) {
                logger.log(LOGPREFIX + "canUserSeePosts: 2 - open feed channel");
                return true;
            }
            if (friends && friends.ok) {
                logger.log(LOGPREFIX + "canUserSeePosts: friends ok");
                const flatMappedFriends = (_a = friends.friends) === null || _a === void 0 ? void 0 : _a.flatMap((entry) => entry.userId);
                logger.log(LOGPREFIX + "canUserSeePosts: friends = " + flatMappedFriends);
                if (flatMappedFriends === null || flatMappedFriends === void 0 ? void 0 : flatMappedFriends.includes(channelOwnerId)) {
                    logger.log(LOGPREFIX + "canUserSeePosts: 3 - friend on feed channel");
                    return true;
                }
                else {
                    logger.log(LOGPREFIX +
                        "canUserSeePosts: 4 - user " +
                        channelOwnerId +
                        " NOT IN " +
                        JSON.stringify(flatMappedFriends));
                }
            }
        }
        else {
            logger.log(LOGPREFIX + "canUserSeePosts: non-feed channel page");
            if (!channelRequiresMembership) {
                logger.log(LOGPREFIX + "canUserSeePosts: 4 - open non-feed channel");
                return true;
            }
            if (memberStatus == CHANNEL_MEMBERSHIP_MEMBER) {
                logger.log(LOGPREFIX + "canUserSeePosts: 5 - member on non-feed channel");
                return true;
            }
        }
        logger.log(LOGPREFIX + "canUserSeePosts: missed - FALSE");
        return false;
    };
    const acceptRequest = (userId) => {
        if (!channelOwnerId || !channelId) {
            logger.log(LOGPREFIX + "acceptRequest: owner/channel not set");
            return;
        }
        if (!auth || !auth.isSignedIn() || !auth.userId) {
            logger.log(LOGPREFIX +
                "acceptRequest: Private Channel. Must be signed in to administer. Redirect to login...");
            forceLogin(navigate);
            return;
        }
        setLoading(true);
        channelOperation({
            operation: "setChannelMemberStatus",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            membershipStatusTarget: userId,
            membershipStatus: CHANNEL_MEMBERSHIP_MEMBER,
        }, {
            okCallback: (clientDetailCode, data) => {
                setLoading(false);
                const results = JSON.parse(data);
                logger.log(LOGPREFIX +
                    "acceptRequest: " +
                    clientDetailCode +
                    " " +
                    JSON.stringify(results));
                setChannelMembershipUpdatedSuccess(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "acceptRequest: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                setLoading(false);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const rejectRequest = (userId) => {
        if (!channelOwnerId || !channelId) {
            logger.log(LOGPREFIX + "rejectRequest: owner/channel not set");
            return;
        }
        if (!auth || !auth.isSignedIn() || !auth.userId) {
            logger.log(LOGPREFIX +
                "rejectRequest: Private Channel. Must be signed in to administer. Redirect to login...");
            forceLogin(navigate);
            return;
        }
        setLoading(true);
        channelOperation({
            operation: "setChannelMemberStatus",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            membershipStatusTarget: userId,
            membershipStatus: CHANNEL_MEMBERSHIP_NOT_MEMBER,
        }, {
            okCallback: (clientDetailCode, data) => {
                setLoading(false);
                const results = JSON.parse(data);
                logger.log(LOGPREFIX +
                    "rejectRequest: " +
                    clientDetailCode +
                    " " +
                    JSON.stringify(results));
                setChannelMembershipUpdatedSuccess(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "rejectRequest: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                setLoading(false);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const blockRequest = (userId) => {
        if (!channelOwnerId || !channelId) {
            logger.log(LOGPREFIX + "blockRequest: owner/channel not set");
            return;
        }
        if (!auth || !auth.isSignedIn() || !auth.userId) {
            logger.log(LOGPREFIX +
                "blockRequest: Private Channel. Must be signed in to administer. Redirect to login...");
            forceLogin(navigate);
            return;
        }
        setLoading(true);
        channelOperation({
            operation: "setChannelMemberStatus",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            membershipStatusTarget: userId,
            membershipStatus: CHANNEL_MEMBERSHIP_DENIED,
        }, {
            okCallback: (clientDetailCode, data) => {
                setLoading(false);
                const results = JSON.parse(data);
                logger.log(LOGPREFIX +
                    "blockRequest: " +
                    clientDetailCode +
                    " " +
                    JSON.stringify(results));
                setChannelMembershipUpdatedSuccess(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "blockRequest: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                setLoading(false);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const loadMorePosts = () => {
        logger.log(LOGPREFIX + "loadMorePosts");
        if (!channelOwnerId || !channelId) {
            logger.log(LOGPREFIX + "loadMorePosts: owner/channel not set");
            return;
        }
        if ((permissionsRequiresFriend || permissionsAdultContent) &&
            (!auth || !auth.isSignedIn() || !auth.userId)) {
            logger.log(LOGPREFIX +
                "loadMorePosts: Must be signed in to load posts. Redirect to login...");
            forceLogin(navigate);
            return;
        }
        if (!canUserSeePosts() || feedRequiresFriend) {
            logger.log(LOGPREFIX + "user CANNOT read posts - skipping load...");
            setPostIds([]);
            return;
        }
        let lastPostId;
        if (postIds && postIds.length > 0) {
            lastPostId = postIds.at(postIds.length - 1);
            if (!hasMorePosts) {
                logger.log(LOGPREFIX + "loadMorePosts: No most posts exist.");
                return;
            }
        }
        else {
            setPostIds([]);
        }
        logger.log(LOGPREFIX + "loadMorePosts: lastPostId = " + lastPostId);
        setLoadingMore(true);
        channelOperation({
            operation: "listPosts",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            postId: lastPostId,
        }, {
            okCallback: (clientDetailCode, data) => {
                logger.log(LOGPREFIX + "loadMorePosts: " + data);
                setLoadingMore(false);
                const results = JSON.parse(data);
                const currentPostIds = postIds;
                if (results === null || results === void 0 ? void 0 : results.postIds) {
                    results.postIds.forEach((entry) => {
                        if (postIds && postIds.includes(entry)) {
                            logger.error(LOGPREFIX + "loadMorePosts: DUPLICATE: " + entry);
                        }
                        else {
                            logger.log(LOGPREFIX + "loadMorePosts: ADDING: " + entry);
                            currentPostIds === null || currentPostIds === void 0 ? void 0 : currentPostIds.push(entry);
                        }
                    });
                    setPostIds(currentPostIds);
                }
                if (results === null || results === void 0 ? void 0 : results.hasMorePosts) {
                    setHasMorePosts(true);
                }
                else {
                    setHasMorePosts(false);
                }
                if (results === null || results === void 0 ? void 0 : results.memberStatus) {
                    logger.log(LOGPREFIX + "Channel Membership Status: " + (results === null || results === void 0 ? void 0 : results.memberStatus));
                    setMemberStatus(results === null || results === void 0 ? void 0 : results.memberStatus);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "loadMorePosts: ERROR = " +
                    errorMessage +
                    " " +
                    clientDetailCode);
                setLoadingMore(false);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    let requestsArea = undefined;
    const cardsList = [];
    let userAvatar = undefined;
    if (channelTitle && channelOwnerId) {
        userAvatar = (_jsx(UserAvatar, { userId: channelOwnerId, keyStr: channelOwnerId + ".avatar", onUserClicked: () => {
                navigate("/ProfileView?userId=" + channelOwnerId);
            }, userName: "", userNameVisible: false, auth: auth, sx: { margin: 0, marginTop: "5px", marginBottom: "5px" } }, channelOwnerId + ".avatar"));
        const channelCard = (_jsx(ContentCard, { keyStr: "channel.card", title: "", header: _jsx(CardHeader, { title: channelTitle, subheader: channelSubtitle, sx: {
                    padding: 0,
                    marginLeft: "8px",
                    marginRight: "8px",
                    marginTop: "5px",
                    marginBottom: "5px",
                }, titleTypographyProps: { variant: "h6" }, avatar: userAvatar }), 
            //        titleAvatar={userAvatar}
            backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, text: channelDescription, mediaUrl: channelImageUrl
                ? authUrl(channelImageUrl, cacheBusterStore, auth)
                : undefined, 
            // TODO: Make channel image clickable
            //        onMediaClick={channelImageClickable ? () => {
            //          const channelImageUrl =
            //            getContentURL() +
            //            "/u/" +
            //            channelOwnerId +
            //            "/" +
            //            channelId +
            //            "/channelImage_1024.jpg";
            //          window.open(authUrl(channelImageUrl, auth), "_blank");
            //        } : undefined}
            mediaFallbackUrl: channelsImg, maxWidth: 0.5, maxMediaHeight: window.innerHeight - 70, actions: _jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [auth && auth.isSignedIn() && auth.userId == channelOwnerId ? (_jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                            setEditChannelVisible(true);
                        }, endIcon: _jsx(SettingsIcon, {}) }, { children: "EDIT CHANNEL SETTINGS" }))) : undefined, auth && auth.isSignedIn() && auth.userId == channelOwnerId ? (_jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                            setDeletePromptVisible(true);
                        }, endIcon: _jsx(DeleteForever, {}) }, { children: "DELETE CHANNEL" }))) : undefined, auth &&
                        auth.isSignedIn() &&
                        channelRequiresMembership &&
                        memberStatus == CHANNEL_MEMBERSHIP_NOT_MEMBER &&
                        channelOwnerId != auth.userId && (_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                            setJoinChannelVisible(true);
                        }, endIcon: _jsx(AddCircleOutline, {}) }, { children: "JOIN CHANNEL" })))] })) }, "channel.card"));
        if (!errorMessage && !deletedOK && !postDeletedOK && channelId) {
            logger.log(LOGPREFIX + "render: adding channel card");
            cardsList.push(channelCard);
            if (channelRequests && channelRequests.length > 0) {
                const requestsList = [];
                channelRequests.forEach((entry) => {
                    const requestUserId = entry.userId;
                    const requestUserName = entry.displayName;
                    if (!requestUserId || !requestUserName) {
                        return;
                    }
                    requestsList.push(_jsx(Divider, {}));
                    requestsList.push(_jsx(Stack, Object.assign({ direction: "row", padding: "5px", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(UserAvatar, { keyStr: requestUserId + ".memberrequest.entry.avatar", userId: requestUserId, userName: requestUserName, onUserClicked: () => {
                                navigate("/ProfileView?userId=" + requestUserId);
                            }, userNameVisible: true, auth: auth }, requestUserId + ".memberrequest.entry.avatar") }), requestUserId + ".stack.memberrequest"));
                    if (entry.promptAnswer) {
                        requestsList.push(_jsxs(Stack, Object.assign({ direction: "column", padding: "5px", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: "Prompt Answer:" })), _jsx(Typography, Object.assign({ variant: "body1" }, { children: entry.promptAnswer }))] }), requestUserId + ".stack.memberrequest.promptanswer"));
                    }
                    requestsList.push(_jsxs(Stack, Object.assign({ direction: "row", padding: "5px", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "success", onClick: () => {
                                    acceptRequest(requestUserId);
                                } }, { children: "ACCEPT" })), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "error", onClick: () => {
                                    rejectRequest(requestUserId);
                                } }, { children: "REJECT" })), _jsx(Button, Object.assign({ variant: "outlined", size: "medium", sx: { margin: "5px" }, color: "error", onClick: () => {
                                    blockRequest(requestUserId);
                                } }, { children: "BLOCK" }))] }), requestUserId + ".stack.memberrequest"));
                });
                const requestsStack = (_jsx(Stack, Object.assign({ direction: "column", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: requestsList }), "requests.stack"));
                requestsArea = (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center", margin: "10px" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                width: "100%",
                                textAlign: "left",
                                marginBottom: 0,
                                paddingBottom: 0,
                                marginLeft: "5px",
                            } }, { children: "Channel Membership Requests" })), requestsStack, _jsx(Divider, { orientation: "horizontal" })] }), "requests.area"));
            }
            if (auth &&
                auth.userId &&
                canUserSeePosts() &&
                (auth.accountType == "admin" ||
                    auth.userId == channelOwnerId ||
                    permissionsAllowsPosts)) {
                logger.log(LOGPREFIX + "render: adding new post card");
                cardsList.push(_jsx(NewPostCard, { keyStr: "new.post.card." + channelId, channelOwnerId: channelOwnerId, channelId: channelId, auth: auth, reload: () => {
                        loadChannelId();
                        loadMorePosts();
                    }, addPostId: (postId) => {
                        postIds === null || postIds === void 0 ? void 0 : postIds.unshift(postId);
                        setPostIds(postIds);
                    } }, "new.post.card." + channelId));
            }
            if (feedRequiresFriend) {
                cardsList.push(_jsxs(Stack, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: "10px",
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error", sx: { display: "flex", justifyContent: "center", width: "100%" } }, { children: "CONTENT RESTRICTED" })), _jsx(Typography, Object.assign({ variant: "h6", color: "main", sx: { display: "flex", justifyContent: "center", width: "100%" } }, { children: "CHANNEL IS PRIVATE" })), _jsx(Typography, Object.assign({ variant: "h6", color: "main", sx: { display: "flex", justifyContent: "center", width: "100%" } }, { children: "CONTENT IS ONLY VISIBLE TO FRIENDS" })), _jsx(Stack, Object.assign({ direction: "row", sx: { display: "flex", justifyContent: "center", width: "100%" } }, { children: userAvatar }))] }), "restricted.channel.stack.private.feed"));
            }
            if (channelOwnerId != auth.userId &&
                channelRequiresMembership &&
                memberStatus != CHANNEL_MEMBERSHIP_MEMBER) {
                cardsList.push(_jsxs(Stack, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: "10px",
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error", sx: { display: "flex", justifyContent: "center", width: "auto" } }, { children: "CONTENT RESTRICTED" })), _jsx(Typography, Object.assign({ variant: "h6", color: "main", sx: { display: "flex", justifyContent: "center", width: "auto" } }, { children: "CHANNEL IS PRIVATE" })), _jsx(Typography, Object.assign({ variant: "h6", color: "main", sx: {
                                display: "flex",
                                justifyContent: "center",
                                width: "auto",
                                textAlign: "center",
                            } }, { children: "CONTENT IS ONLY VISIBLE TO CHANNEL MEMBERS" })), _jsx(Stack, { direction: "row", sx: { display: "flex", justifyContent: "center", width: "auto" } })] }), "restricted.channel.stack.private.channel"));
            }
        }
    }
    if (deletedOK) {
        cardsList.push(_jsxs(Card, Object.assign({ elevation: 10, sx: {
                backgroundColor: "#EEFFEE",
                padding: layout.cardPadding,
                margin: layout.cardMargin,
                alignContent: "center",
                textAlign: "center",
            } }, { children: [_jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "violet" }, { children: "CHANNEL DELETED" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: "Your channel has been deleted." }))] }), "channelview.content.cardcontent.deletedok"), _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                            window.location.replace("/Channels");
                        } }, { children: "OK" })) }))] }), "channelview.content.card.deletedok"));
    }
    if (postDeletedOK) {
        cardsList.push(_jsx(Card, Object.assign({ elevation: 10, sx: {
                backgroundColor: "#EEFFEE",
                padding: layout.cardPadding,
                margin: layout.cardMargin,
                alignContent: "center",
                textAlign: "center",
            } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                    padding: layout.cardContentPadding,
                    margin: layout.cardContentMargin,
                } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "violet" }, { children: "POST DELETED" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                            textAlign: "left",
                            paddingLeft: layout.cardContentPadding,
                            paddingRight: layout.cardContentPadding,
                        } }, { children: "Your post has been deleted." }))] }), "channelview.content.cardcontent.postdeletedok") }), "channelview.content.card.postdeletedok"));
    }
    logger.log(LOGPREFIX + "render: postIds = " + JSON.stringify(postIds));
    if (!postDeletedOK &&
        !deletedOK &&
        !deletePromptVisible &&
        !errorMessage &&
        channelOwnerId &&
        channelId &&
        canUserSeePosts() &&
        postIds &&
        postIds.length > 0) {
        logger.log(LOGPREFIX + "render: adding post cards");
        postIds.forEach((entry) => {
            const basePath = getContentURL() + "/u/" + channelOwnerId + "/" + channelId;
            const postId = entry.substring(0, entry.lastIndexOf("."));
            cardsList.push(_jsx(PostCard, { channelOwnerId: channelOwnerId, channelId: channelId, postId: postId, basePath: basePath, postJson: entry, enableEditButtons: auth.accountType == "admin" || channelOwnerId == auth.userId, expandPostContent: false, keyStr: entry + ".card", maxMediaHeight: window.innerHeight - 70, onClick: () => {
                    logger.log(LOGPREFIX +
                        "post.onClick: channelId " +
                        channelId +
                        " postId " +
                        entry);
                    navigate("/PostView?channelOwnerId=" +
                        channelOwnerId +
                        "&channelId=" +
                        channelId +
                        "&postId=" +
                        entry.substring(0, entry.lastIndexOf(".")));
                }, deletePost: deletePostParent, maxResolution: true }, entry + ".card"));
        });
        /*
        if (adScript) {
          logger.log("included ad in-feed");
          cardsList.push(
            <ContentCard
              title=""
              key={"channelview.ad.infeed.contentcard." + channelId}
              keyStr={"channelview.ad.infeed.contentcard." + channelId}
              textColor={getTheme().palette.primary.main}
              backgroundColor="#FFFFFF"
              childComponent={adScript}
            />,
          );
        }
          */
    }
    //logger.log("ChannelView.render: channelOwnerId = " + channelOwnerId);
    //logger.log("ChannelView.render: channelId = " + channelId);
    //logger.log("ChannelView.render: postIds = " + JSON.stringify(postIds));
    //logger.log("ChannelView.render: cards count = " + cardsList?.length);
    const columnStack = _jsx(ContentCardStacks, { cards: cardsList }, "cards.stack");
    const buttonStyle = { paddingLeft: "15px", paddingRight: "15px" };
    const topButtons = (searchName || channelTitle) && (_jsx(Stack, Object.assign({ direction: "row", sx: {
            width: "auto",
            display: "flex",
            justifyContent: "center",
            margin: "3px",
            marginBottom: "0",
        } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "outlined", "aria-label": "Basic button group" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Channels");
                    } }, { children: "Channels" })), _jsxs(Button, Object.assign({ variant: "contained", sx: buttonStyle, onClick: () => { } }, { children: ["@", searchName ? searchName : channelTitle] }))] })) })));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [errorMessage && (_jsxs(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: [_jsxs(CardContent, Object.assign({ sx: {
                            padding: layout.cardContentPadding,
                            margin: layout.cardContentMargin,
                        } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error" }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                    textAlign: "left",
                                    paddingLeft: layout.cardContentPadding,
                                    paddingRight: layout.cardContentPadding,
                                } }, { children: errorMessage }))] }), "channelview.content.cardcontent.error"), _jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [searchName == auth.displayName && (_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                                    navigate("/Feed");
                                } }, { children: "SETUP FEED CHANNEL" }))), _jsx(Button, Object.assign({ onClick: () => {
                                    navigate("/Channels");
                                }, variant: "contained" }, { children: "EXPLORE CHANNELS" }))] }))] }), "channelview.content.card.error")), requestsArea, columnStack] }), "channelview.content.stack"));
    const needsFriendContent = (_jsx(Box, Object.assign({ sx: { width: "100%", justifyContent: "center", display: "flex" } }, { children: _jsxs(Stack, Object.assign({ direction: "column", sx: { padding: "5px" } }, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: "Not Allowed" })), _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Channel may only be viewed by friends of the owner." })), userAvatar] })) })));
    let editChannelCard;
    if (!postDeletedOK && channelOwnerId && channelId && editChannelVisible) {
        editChannelCard = (_jsx(EditChannelCard, { channelOwnerId: channelOwnerId, channelId: channelId, auth: auth, reload: (newChannelName) => {
                logger.log(LOGPREFIX + "reload: new channel name = " + newChannelName);
                if (newChannelName == channelTitle || !newChannelName) {
                    setEditChannelVisible(false);
                    loadChannelId();
                }
                else {
                    // TODO: Cache buster gets reset to zero on reload
                    const url = getChannelViewBaseURL() + encodeURI(newChannelName);
                    window.location.href = url;
                }
            }, title: channelTitle, subtitle: channelSubtitle, description: channelDescription, imageUrl: channelImageUrl, permissions: {
                permissionsAdultContent: permissionsAdultContent,
                permissionsAllowsComments: permissionsAllowsComments,
                permissionsAllowsPosts: permissionsAllowsPosts,
            }, memberPrompt: membershipPrompt, privateChannel: permissionsRequiresFriend, dismiss: () => {
                setEditChannelVisible(false);
            } }));
    }
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }, maxWidth: false }, { children: _jsxs(Stack, Object.assign({ direction: "column", sx: { width: "100%" } }, { children: [topButtons, loading && (_jsx(LinearProgress, { sx: { width: "100%", margin: "4px" } })), !postDeletedOK && editChannelCard, !postDeletedOK &&
                                !channelMembershipUpdatedSuccess &&
                                !joinChannelRequestedSuccess &&
                                !joinChannelVisible &&
                                !editChannelVisible &&
                                !deletePromptVisible &&
                                needsToBeFriends &&
                                needsFriendContent, !channelMembershipUpdatedSuccess &&
                                !joinChannelRequestedSuccess &&
                                !joinChannelVisible &&
                                !editChannelVisible &&
                                !deletePromptVisible &&
                                !needsToBeFriends &&
                                !loading &&
                                content, joinChannelRequestedSuccess && (_jsx(ContentCard, { keyStr: "join.channel.success.content.card." + channelId, title: "SUCCESS", textColor: "#333333", backgroundColor: "#EEFFEE", childComponent: _jsx(Stack, Object.assign({ direction: "column", sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "20px",
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "10px",
                                            marginTop: "30px",
                                        } }, { children: "Your request has been submitted to the channel owner." })) })), maxWidth: 0.9 }, "join.channel.success.content.card." + channelId)), channelMembershipUpdatedSuccess && (_jsx(ContentCard, { keyStr: "join.channel.updatesuccess.content.card." + channelId, title: "SUCCESS", textColor: "#333333", backgroundColor: "#EEFFEE", childComponent: _jsx(Stack, Object.assign({ direction: "column", sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "20px",
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "10px",
                                            marginTop: "30px",
                                        } }, { children: "Channel membership has been updated." })) })), maxWidth: 0.9 }, "join.channel.updatesuccess.content.card." + channelId)), joinChannelVisible && (_jsx(ContentCard, { keyStr: "join.channel.prompt.content.card." + channelId, title: "JOIN CHANNEL", textColor: "#333333", backgroundColor: "#FFFFFF", childComponent: _jsxs(Stack, Object.assign({ direction: "column", sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "20px",
                                    } }, { children: [membershipPrompt && (_jsx(Typography, Object.assign({ variant: "body1", sx: { marginBottom: "20px" } }, { children: "Please answer the following question for the channel owner." }))), membershipPrompt && (_jsx(TextField, { label: membershipPrompt, onChange: (event) => {
                                                const value = event.target.value;
                                                setMembershipPromptAnswer(value);
                                            } })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "10px",
                                                marginTop: "30px",
                                            } }, { children: "Do you want to join this channel?" }))] })), maxWidth: 0.9, actions: _jsxs(CardActions, Object.assign({ sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "20px",
                                    } }, { children: [_jsx(Button, Object.assign({ variant: "contained", color: "success", onClick: () => {
                                                doJoinChannel();
                                            } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "outlined", color: "error", onClick: () => {
                                                setJoinChannelVisible(false);
                                            } }, { children: "No" }))] })) }, "join.channel.prompt.content.card." + channelId)), deletePromptVisible && (_jsx(ContentCard, { keyStr: "delete.channel.prompt.content.card." + channelId, title: "DELETE CHANNEL", textColor: "#333333", backgroundColor: "#FFFFFF", childComponent: _jsxs(Typography, Object.assign({ variant: "body1", sx: {
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "20px",
                                    } }, { children: ["WARNING: This will permanently erase all content, posts, likes, and comments. This cannot be undone.", _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), "Are you sure you want to delete this channel?"] })), maxWidth: 0.9, actions: _jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "error", onClick: () => {
                                                doDeleteChannel();
                                            } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "contained", color: "success", onClick: () => {
                                                setDeletePromptVisible(false);
                                            } }, { children: "No" }))] })) }, "delete.channel.prompt.content.card." + channelId)), !postDeletedOK &&
                                !channelMembershipUpdatedSuccess &&
                                !joinChannelRequestedSuccess &&
                                !joinChannelVisible &&
                                !deletePromptVisible &&
                                !needsToBeFriends &&
                                !loading &&
                                hasMorePosts && (_jsx("div", Object.assign({ style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                } }, { children: _jsx(LoadMoreButton, { onClick: loadMorePosts, loading: loadingMore }) })))] })) })), _jsx(Footer, {})] })) }), "channelview.div." + (postIds === null || postIds === void 0 ? void 0 : postIds.length)));
    return output;
}
