import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, CardMedia, LinearProgress, Stack, styled, Typography, } from "@mui/material";
import { getLayoutInfo } from "../LayoutInfo";
import { useEffect, useState } from "react";
import { useCacheBusterStore } from "../UrlUrils";
import { useLogger } from "../logger";
import { computeScaleDownSize } from "../ImageUtils";
const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);
const LOGPREFIX = "ContentCard: ";
export const ContentCard = (props) => {
    const cacheBusterStore = useCacheBusterStore((state) => state);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [forceRender, setForceRender] = useState(cacheBusterStore.getCacheBusterNumber());
    const [mediaLoadError, setMediaLoadError] = useState(false);
    const [mediaUrl, setMediaUrl] = useState(props.mediaUrl);
    const [mediaLoading, setMediaLoading] = useState(false);
    const [width, setWidth] = useState(0);
    const layout = getLayoutInfo();
    const logger = useLogger((state) => state);
    useEffect(() => {
        if (mediaLoadError) {
            if (mediaUrl != props.mediaFallbackUrl) {
                logger.log(LOGPREFIX + "Using fallback Media for Content Card - " + props.keyStr);
                setMediaUrl(props.mediaFallbackUrl);
                setMediaLoadError(false);
            }
            else {
                setMediaUrl(undefined);
            }
        }
        if (props.onMediaLoadError) {
            props.onMediaLoadError();
        }
        return () => { };
    }, [mediaLoadError]);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setWidth]);
    useEffect(() => {
        logger.log(LOGPREFIX + "ContentCard: useEffect mount - mediaUrl = " + mediaUrl);
        if (mediaUrl && !mediaUrl.includes("img/")) {
            setMediaLoading(true);
        }
        return () => {
            logger.log(LOGPREFIX + "ContentCard: useEffect unmount - mediaUrl");
        };
    }, [mediaUrl]);
    const delayedClearMediaLoading = () => {
        setTimeout(() => {
            setMediaLoading(false);
        }, 1000);
    };
    let childComponentObj = props.childComponent;
    if (!childComponentObj && props.text) {
        childComponentObj = (_jsxs(Typography, Object.assign({ whiteSpace: "pre-line", variant: "body1", color: props.textColor, sx: { textAlign: "left", padding: "10px", wordBreak: "break-word" } }, { children: [mediaLoading && _jsx(LinearProgress, {}), props.text ? props.text : ""] }), props.keyStr + "-typography"));
    }
    let mediaSx;
    if (props.onMediaClick) {
        mediaSx = {
            objectFit: "contain",
            cursor: "pointer",
        };
    }
    else {
        mediaSx = { objectFit: "contain" };
    }
    mediaSx.maxWidth = props.maxMediaWidth ? "" + props.maxMediaWidth : "100%";
    mediaSx.maxHeight = props.maxMediaHeight ? "" + props.maxMediaHeight : "100%";
    let maxWidth;
    if (props.maxWidth && width >= 1000) {
        maxWidth = props.maxWidth * width;
    }
    let titleLineHeight = "32px";
    if (props.titleAvatar) {
        titleLineHeight = "46px";
    }
    const elm = (_jsxs(Card, Object.assign({ elevation: 10, sx: {
            backgroundColor: props.backgroundColor,
            padding: 0,
            margin: layout.cardMargin,
            alignContent: "center",
            textAlign: "center",
            width: "100%",
            maxWidth,
            objectFit: "contain",
        } }, { children: [props.header, mediaUrl && !props.overlayIcon && (_jsx(CardMedia, { component: "img", src: mediaUrl, sx: mediaSx, onClick: props.onMediaClick, onError: (err) => {
                    logger.error(LOGPREFIX + "CardMedia.onError-0: " + mediaUrl + " " + err);
                    setMediaLoadError(true);
                    delayedClearMediaLoading();
                }, onLoad: (event) => {
                    logger.log(LOGPREFIX + "CardMedia.onLoad: " + mediaUrl);
                    if (event.target instanceof HTMLImageElement) {
                        logger.log(LOGPREFIX + "CardMedia.onLoad - element is image");
                        const img = event.target;
                        let maxImgWidth;
                        let maxImgHeight;
                        if (props.maxMediaHeight != undefined) {
                            maxImgHeight = props.maxMediaHeight;
                        }
                        else {
                            maxImgHeight = window.innerHeight - 10;
                        }
                        if (props.maxMediaWidth != undefined) {
                            maxImgWidth = props.maxMediaWidth;
                        }
                        else {
                            maxImgWidth = window.innerWidth - 10;
                        }
                        const newSizes = computeScaleDownSize(img.width, img.height, maxImgWidth, maxImgHeight);
                        if (img.height > newSizes.newHeight) {
                            img.width = newSizes.newWidth;
                            img.height = newSizes.newHeight;
                        }
                    }
                    else {
                        logger.log(LOGPREFIX + "ContentCard.onLoad - element = " + event.target);
                    }
                    delayedClearMediaLoading();
                    if (props.onMediaLoad) {
                        props.onMediaLoad();
                    }
                } }, props.keyStr + "-card-subelm-media-" + mediaUrl + "-" + forceRender)), mediaUrl && props.overlayIcon && (_jsxs(CardMedia, Object.assign({ sx: mediaSx, onClick: props.onMediaClick }, { children: [_jsx("img", { src: mediaUrl, width: "100%", style: {
                            maxHeight: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                        }, onError: (err) => {
                            logger.error(LOGPREFIX + "CardMedia.onError-1: " + mediaUrl + " " + err);
                            setMediaLoadError(true);
                            delayedClearMediaLoading();
                        }, onLoad: () => {
                            logger.log(LOGPREFIX + "CardMedia.onLoad: " + mediaUrl);
                            delayedClearMediaLoading();
                            if (props.onMediaLoad) {
                                props.onMediaLoad();
                            }
                        } }, props.keyStr + ".cardmedia.image." + mediaUrl + forceRender), _jsx("div", Object.assign({ style: {
                            textAlign: "right",
                            width: "99%",
                            paddingRight: "0.3em",
                            marginTop: "-50px",
                            paddingLeft: "auto",
                            paddingBottom: "5px",
                        } }, { children: props.overlayIcon }), props.keyStr + ".cardmedia.overlay.button.div." + forceRender)] }), props.keyStr + "-card-subelm-media-2-" + mediaUrl + forceRender)), _jsxs(CardContentNoPadding, { children: [props.title && (_jsxs(Stack, Object.assign({ direction: "row", width: props.titleAvatar ? 0.96 : 1.0, sx: {
                            justifyContent: "center",
                            marginLeft: "3px",
                            marginRight: "3px",
                            paddingLeft: 0,
                            paddingRight: 0,
                        } }, { children: [props.titleAvatar, _jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: {
                                    lineHeight: titleLineHeight,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }, noWrap: true }, { children: props.title }), props.keyStr + "-card-content-subelm-typeography-0")] }))), childComponentObj] }, props.keyStr + "-card-content-subelm-" + mediaUrl + forceRender), props.actions] }), props.keyStr + "-card-subelm-" + mediaUrl + "-" + forceRender));
    return elm;
};
