import { create } from "zustand";
const CACHE_BUSTER_DIVISOR = 43200; // 12 hours
export const useCacheBusterStore = create()((set, get) => ({
    cacheBusterOffset: 0,
    cacheBusterActive: false,
    isCacheBusterActive: () => {
        return get().cacheBusterActive;
    },
    resetCacheBuster: () => {
        //    console.log("resetCacheBuster");
        const state = get();
        state.cacheBusterActive = true;
        const tsSecs = Math.trunc(new Date().getTime() / 1000);
        state.cacheBusterOffset = tsSecs % CACHE_BUSTER_DIVISOR;
        set(state);
    },
    getCacheBusterNumber: () => {
        const state = get();
        if (!state.cacheBusterActive) {
            const retVal = state.getDefaultCacheBusterValueHour();
            //      console.log("getCacheBusterNumber: " + retVal);
            return retVal;
        }
        let tsSecs = Math.trunc(new Date().getTime() / 1000);
        tsSecs = tsSecs - state.cacheBusterOffset + 1;
        tsSecs =
            Math.trunc(tsSecs / CACHE_BUSTER_DIVISOR) * CACHE_BUSTER_DIVISOR +
                state.cacheBusterOffset;
        //    console.log("getCacheBusterNumber: " + tsSecs);
        return tsSecs;
    },
    getCacheBuster: () => {
        const state = get();
        return "" + state.getCacheBusterNumber();
    },
    getDefaultCacheBusterValueHour: () => {
        let ts = new Date().getTime() / 1000;
        ts = ts / 60; // minutes
        ts = ts / 60; // hours
        ts = Math.trunc(ts / 6); // 6-hours
        return ts;
    },
    getDefaultCacheBusterValueDay: () => {
        let ts = new Date().getTime() / 1000;
        ts = ts / 60; // minutes
        ts = ts / 60; // hours
        ts = Math.trunc(ts / 24); // days
        return ts;
    },
}));
export const authUrl = (url, cacheBusterStore, auth) => {
    if (auth &&
        auth.isSignedIn() &&
        auth.bearer &&
        url &&
        !url.includes("bearer") &&
        !url.startsWith("blob")) {
        const retVal = url +
            "?bearer=" +
            encodeURIComponent(auth.bearer) +
            "&cb=" +
            cacheBusterStore.getCacheBuster();
        console.log("URL w/ bearer = " + retVal);
        return retVal;
    }
    return url;
};
