import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Button, ButtonGroup, Container, Stack, ThemeProvider, } from "@mui/material";
import getTheme from "./Theme";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { useEffect } from "react";
import { NotificationsCard } from "./components/NotificationsCards";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { useCacheBusterStore } from "./UrlUrils";
import { forceLogin } from "./Auth/AuthUtils";
import { useNavigate } from "react-router-dom";
const LOGPREFIX = "NotifPage: ";
export function NotificationsPage() {
    const auth = useProfileAuthStore((state) => state);
    const cb = useCacheBusterStore((state) => state);
    const navigate = useNavigate();
    const cards = [];
    useEffect(() => {
        const retVal = () => { };
        if (!auth || !auth.isSignedIn()) {
            console.log(LOGPREFIX + "Not signed in - redirect...");
            if (!restoreSession(auth)) {
                forceLogin(navigate);
            }
            return retVal;
        }
        else {
            console.log(LOGPREFIX + "User is signed in");
        }
        return retVal;
    }, [auth]);
    if (auth.isSignedIn()) {
        console.log(LOGPREFIX + "is logged in - adding notification card");
        cards.push(_jsx(NotificationsCard, { keyStr: "notifications.card", auth: auth, cb: cb }, "notifications.card"));
    }
    const content = _jsx(ContentCardStacks, { cards: cards });
    const buttonStyle = { paddingLeft: "15px", paddingRight: "15px" };
    const topButtons = (_jsx(Stack, Object.assign({ direction: "row", sx: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "3px",
            marginBottom: "0",
        } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "outlined", "aria-label": "Basic button group" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Home");
                    } }, { children: "Home" })), _jsx(Button, Object.assign({ variant: "contained", sx: buttonStyle, onClick: () => { } }, { children: "Notifications" })), _jsx(Button, Object.assign({ variant: "outlined", sx: buttonStyle, onClick: () => {
                        navigate("/Latest");
                    } }, { children: "Latest" }))] })) }), "top.notif.buttons"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF", padding: 0, margin: 0 } }, { children: _jsxs(Stack, Object.assign({ sx: {
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            justifyContent: "center",
                        } }, { children: [topButtons, content] }), "notif.page") })), _jsx(Footer, {})] })) })));
    return output;
}
