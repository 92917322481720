import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Card, CardContent, Container, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { useEffect, useState } from "react";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { PostCard } from "./components/PostCard";
import { getContentURL } from "./ContentAPI";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { ChannelButton } from "./components/ChannelButton";
import { deletePost } from "./DataUtils";
import { useCacheBusterStore } from "./UrlUrils";
export const PostView = () => {
    const [errorMessage, setErrorMessage] = useState();
    const [channelOwnerId, setChannelOwnerId] = useState();
    const [channelId, setChannelId] = useState();
    const [postId, setPostId] = useState();
    const [postDeletedOK, setPostDeletedOK] = useState(false);
    const [height, setHeight] = useState();
    const [width, setWidth] = useState();
    const auth = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const layout = getLayoutInfo();
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                console.log("User NOT logged in");
            }
        }
        return () => { };
    }, [auth]);
    useEffect(() => {
        const path = window.location.search
            ? window.location.search
            : window.location.href;
        console.log("mount: path = " + path);
        let capturedChannelOwnerId = undefined;
        let capturedChannelId = undefined;
        let capturedPostId = undefined;
        if (path.startsWith("?")) {
            const paramString = path.substring(1, path.length);
            const params = paramString.split("&");
            params.forEach((param) => {
                const fields = param.split("=");
                if (fields.length == 2) {
                    const key = fields[0];
                    const value = fields[1];
                    if ("channelOwnerId" == key) {
                        capturedChannelOwnerId = value;
                        setChannelOwnerId(capturedChannelOwnerId);
                    }
                    else if ("channelId" == key) {
                        capturedChannelId = value;
                        setChannelId(value);
                    }
                    else if ("postId" == key) {
                        capturedPostId = value;
                        if (capturedPostId.endsWith(".json")) {
                            capturedPostId = capturedPostId.substring(0, capturedPostId.length - 5);
                        }
                        setPostId(capturedPostId);
                    }
                    else {
                        console.error("Unexpected Parameter Name: " + key);
                    }
                }
                else {
                    console.error("Unexpected Field Count: " + fields.length);
                }
            });
        }
        if (!capturedChannelId) {
            console.error("Missing channel ID parameter.");
            setErrorMessage("Channel ID ('channelId=') is missing from URL. Please check the URL, and try again.");
        }
        else if (!capturedPostId) {
            console.error("Missing post ID parameter.");
            setErrorMessage("Post ID ('postId=...') is missing from URL. Please check the URL, and try again.");
        }
        else if (!capturedChannelOwnerId) {
            console.error("Missing channel owner ID parameter.");
            setErrorMessage("Channel Owner ID ('channelOwnerId=...') is missing from URL. Please check the URL, and try again.");
        }
        return () => { };
    }, []);
    useEffect(() => {
        function handleResize() {
            console.log("Window width = " + window.innerWidth);
            setWidth(window.innerWidth);
            console.log("Window height = " + window.innerHeight);
            setHeight(window.innerHeight);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setWidth]);
    const deletePostParent = (basePath, postJson) => {
        console.log("deletePostParent: " + basePath + " " + postJson);
        if (!(auth === null || auth === void 0 ? void 0 : auth.userId)) {
            setErrorMessage("User is not logged in.");
            return;
        }
        if (!channelOwnerId) {
            setErrorMessage("Channel owner is not set.");
            return;
        }
        deletePost({
            operation: "deletePost",
            channelOwnerId: channelOwnerId,
            channelId: channelId,
            postOwnerId: auth.userId,
            postId: postJson,
        }, {
            okCallback: (clientDetailCode) => {
                console.log("deletePost: OK " + clientDetailCode);
                setPostDeletedOK(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                console.log("deletePost: ERROR " + clientDetailCode + " " + errorMessage);
                setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    let postCard = undefined;
    const basePath = getContentURL() + "/u/" + channelOwnerId + "/" + channelId;
    if (channelOwnerId && channelId && postId) {
        if (postDeletedOK) {
            postCard = (_jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#EEFFEE",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "main" }, { children: "POST DELETED" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: "Your post has been deleted." }))] }), "channelview.content.cardcontent.deletedok") }), "postview.content.card.deletedok"));
        }
        else {
            postCard = (_jsx(ContentCardStacks, { cards: [
                    _jsx(PostCard, { basePath: basePath, postJson: postId + ".json", enableEditButtons: false, keyStr: "postcard.postview." + postId, maxResolution: true, maxMediaWidth: width, maxMediaHeight: height, channelOwnerId: channelOwnerId, channelId: channelId, postId: postId, expandPostContent: true, deletePost: deletePostParent }, "postcard.postview." + postId),
                ] }));
        }
    }
    const channelJson = "u/" + channelOwnerId + "/" + channelId + "/channelData.json";
    const channelButton = (_jsx(ChannelButton, { keyStr: "chanKeyCard." + channelId, basePath: getContentURL(), channelJson: channelJson }, "chanKeyCard." + channelId));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, marging: 0 }, spacing: 0 }, { children: [errorMessage && (_jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error" }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: errorMessage }))] }), "channelview.content.cardcontent.error") }), "postview.content.card.error")), channelButton, postCard] }), "postview.content.stack"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    }, maxWidth: false }, { children: content })), _jsx(Footer, {})] })) }), "postview.div"));
    return output;
};
