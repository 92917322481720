import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Box, Button, Card, CardActions, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Link, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { ProfileOperation, useProfileAuthStore, } from "./Store/ProfileStore";
import { useProfileStore } from "./Store/ProfileStore";
import { doLogoutRedirect } from "./Auth/AuthUtils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const updatedDate = "10/24/2024";
const acceptAllTerms = (auth, profileLoader) => {
    profileLoader.saveProfile(auth, {
        okCallback: (clientDetailCode) => {
            console.log("okCallback - " + clientDetailCode);
        },
        errorCallback: (clientDetailCode, errorMessage) => {
            console.log("errorCallback - " + clientDetailCode + " " + errorMessage);
        },
    }, undefined, undefined, undefined, true);
};
export function Terms() {
    const navigate = useNavigate();
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const auth = useProfileAuthStore((state) => state);
    const profileLoader = useProfileStore((state) => state);
    if (!auth.isSignedIn() || !auth.userId) {
        setTimeout(() => {
            navigate("/");
        }, 100);
    }
    else if (auth.userId) {
        // Does nothing if profile already loaded.
        profileLoader.loadProfile(auth.userId, auth);
    }
    console.log("profileLoader.operation = " + profileLoader.operation);
    console.log("profileLoader.ok = " + profileLoader.ok);
    console.log("profileLoader.done = " + profileLoader.done);
    const actionButtons = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsxs(Button, Object.assign({ variant: "contained", onClick: () => {
                    acceptAllTerms(auth, profileLoader);
                } }, { children: ["ACCEPT", _jsx("br", {}), _jsx("br", {}), "I agree to these terms"] })), _jsxs(Button, Object.assign({ variant: "outlined", onClick: () => {
                    setTimeout(() => {
                        doLogoutRedirect();
                    }, 100);
                } }, { children: ["DECLINE", _jsx("br", {}), _jsx("br", {}), "I do not agree to these terms"] }))] })));
    let actions = undefined;
    if (acceptPrivacy &&
        acceptTerms &&
        profileLoader.done &&
        profileLoader.ok &&
        profileLoader.operation == ProfileOperation.LoadProfile) {
        actions = actionButtons;
    }
    else if (profileLoader.done && profileLoader.error) {
        actions = (_jsxs("div", { children: [_jsx(Box, Object.assign({ sx: { backgroundColor: "#FFEEEE", padding: "20px", margin: "10px" } }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: profileLoader.error })) })), actionButtons] }));
    }
    else if (profileLoader.done &&
        profileLoader.ok &&
        profileLoader.operation == ProfileOperation.SaveProfile) {
        profileLoader.reset();
        if (auth.userId) {
            // Load new profile in background
            profileLoader.loadProfile(auth.userId, auth);
        }
        setTimeout(() => {
            navigate("/Home");
        }, 300);
    }
    const signedInContent = (_jsxs(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "primary", sx: { textAlign: "center", width: "100%" } }, { children: "Terms & Policies" })), _jsxs(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFFFFF",
                    padding: "4",
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: [_jsx(CardContent, { children: _jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Check the box next to the policies you have read and understand." }), _jsxs(FormGroup, { children: [_jsx(Divider, {}), _jsx(FormControlLabel, { label: "I have read and understand the following Terms of Service.", control: _jsx(Checkbox, { value: "1", onChange: (event) => {
                                                    console.log("tos-checked/unchecked: " + event.target.checked);
                                                    setAcceptTerms(event.target.checked);
                                                } }) }), _jsxs(Link, Object.assign({ target: "_terms", href: "terms.txt" }, { children: ["Terms of Use (updated ", updatedDate, ")"] })), _jsx(Divider, {}), _jsx(FormControlLabel, { label: "I have read and understand the following Privacy Policy.", control: _jsx(Checkbox, { value: "2", onChange: (event) => {
                                                    console.log("pp-checked/unchecked: " + event.target.checked);
                                                    setAcceptPrivacy(event.target.checked);
                                                } }) }), _jsxs(Link, Object.assign({ target: "_privacy", href: "privacy.txt" }, { children: ["Privacy Policy (updated ", updatedDate, ")"] })), _jsx(Divider, {})] })] }) }), actions] }))] })));
    const content = signedInContent;
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, { disableNav: true }), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF" } }, { children: content })), _jsx(Footer, { stickToBottom: true })] })) })));
    return output;
}
