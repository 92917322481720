import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import getTheme from "../Theme";
import { Avatar, Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, LinearProgress, Radio, RadioGroup, Stack, TextField, Typography, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { scaleAndUploadImages, 
//  ImageMultiScaleUpload,
//  scaleAndUploadImages,
scaleDownImage, updateTypesBestEffort, } from "../ImageUtils";
import { AddAPhoto, Close, 
//  PersonAdd,
//  Place,
Edit, IosShare, } from "@mui/icons-material";
import { ContentCard } from "./ContentCard";
import { saveChannelData } from "../DataUtils";
import { authUrl, useCacheBusterStore } from "../UrlUrils";
export const EditChannelCard = (props) => {
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const canvasRef = useRef(null);
    const [editChannelTitle, setEditChannelTitle] = useState(props.title ? props.title : "");
    const [editChannelHelperTitle, setEditChannelHelperTitle] = useState(undefined);
    const [editChannelSubtitle, setEditChannelSubtitle] = useState(props.subtitle ? props.subtitle : "");
    const [editChannelHelperSubtitle, setEditChannelHelperSubtitle] = useState(undefined);
    const [editChannelText, setEditChannelText] = useState(props.description ? props.description : "");
    const [editChannelHelperText, setEditChannelHelperText] = useState(undefined);
    const [permissionsAdultContent, setPermissionsAdultContent] = useState(props.permissions.permissionsAdultContent);
    const [permissionsAllowsComments, setPermissionsAllowsComments] = useState(props.permissions.permissionsAllowsComments);
    const [permissionsAllowsPosts, setPermissionsAllowsPosts] = useState(props.permissions.permissionsAllowsPosts);
    const [editChannelPrompt, setEditChannelPrompt] = useState(props.memberPrompt ? props.memberPrompt : "");
    const [editChannelHelperPrompt, setEditChannelHelperPrompt] = useState(undefined);
    const [editChannelImageUrl, setEditChannelImageUrl] = useState(props.imageUrl ? props.imageUrl : "");
    const [editChannelSaving, setEditChannelSaving] = useState(false);
    const [editChannelSavedOk, setEditChannelSavedOk] = useState(false);
    const [editChannelSaveError, setEditChannelSaveError] = useState(undefined);
    const hiddenFileInputEditChannel = useRef(null);
    const [fileInputListEditChannel, setFileInputListEditChannel] = useState();
    const [editChannelImageDataUrl, setEditChannelImageDataUrl] = useState(props.imageUrl ? props.imageUrl : "");
    const [access, setAccess] = useState(props.privateChannel ? "private" : "public");
    const [errorMessage, setErrorMessage] = useState();
    const validateEditChannelTitle = (title) => {
        setEditChannelSaveError(undefined);
        setEditChannelSaving(false);
        if (!title) {
            setEditChannelHelperTitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setEditChannelHelperTitle("Title cannot contain '<' character.");
        }
        else if (title.includes("@")) {
            setEditChannelHelperTitle("Title cannot contain '@' character.");
        }
        else if (title.includes("\n")) {
            setEditChannelHelperTitle("Title cannot contain newline character.");
        }
        else {
            setEditChannelHelperTitle(undefined);
        }
    };
    const validateeditChannelSubtitle = (title) => {
        setEditChannelSaveError(undefined);
        setEditChannelSaving(false);
        if (!title) {
            setEditChannelHelperSubtitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setEditChannelHelperSubtitle("Subtitle cannot contain '<' character.");
        }
        else if (title.includes("\n")) {
            setEditChannelHelperSubtitle("Subtitle cannot contain newline character.");
        }
        else {
            setEditChannelHelperSubtitle(undefined);
        }
    };
    const validateEditChannelText = (text) => {
        setEditChannelSaveError(undefined);
        setEditChannelSaving(false);
        if (!text) {
            setEditChannelHelperText(undefined);
            return;
        }
        if (text.includes("<")) {
            setEditChannelHelperText("Text cannot contain '<' character.");
        }
        else {
            setEditChannelHelperText(undefined);
        }
    };
    const validateEditChannelPrompt = (prompt) => {
        setEditChannelSaveError(undefined);
        setEditChannelSaving(false);
        if (!prompt) {
            setEditChannelHelperPrompt(undefined);
            return;
        }
        if (prompt.includes("<")) {
            setEditChannelHelperPrompt("Member question cannot contain '<' character.");
        }
        else if (prompt.includes("\n")) {
            setEditChannelHelperPrompt("Member question cannot contain newline character.");
        }
        else {
            setEditChannelHelperPrompt(undefined);
        }
    };
    const doPublish = () => {
        console.log("doPublish");
        const handleError = (errorMessage) => {
            console.log("doPublish: handleError " + errorMessage);
            setTimeout(() => {
                setEditChannelSaveError(errorMessage);
                setEditChannelSavedOk(false);
                setEditChannelSaving(false);
            }, 300);
        };
        const handleOk = () => {
            console.log("doPublish: handleOk");
            setEditChannelSaving(false);
            setEditChannelSaveError(undefined);
            setTimeout(() => {
                setEditChannelSavedOk(true);
            }, 300);
        };
        setEditChannelSaveError(undefined);
        setEditChannelSavedOk(false);
        setEditChannelSaving(true);
        if (!props.auth || !props.auth.isSignedIn() || !props.auth.userId) {
            console.error("User not signed in.");
            handleError("User is not signed in.");
            return;
        }
        const inputData = {
            title: editChannelTitle,
            subtitle: editChannelSubtitle,
            description: editChannelText,
            channelOwnerId: props.auth.userId,
            channelId: props.channelId,
            membershipPrompt: editChannelPrompt,
            private: access == "private" ? true : false,
            permissions: {
                permissionsAdultContent,
                permissionsAllowsComments,
                permissionsAllowsPosts,
                permissionsAllowsDiscovery: true,
            },
            hasImage: !!(editChannelImageDataUrl || editChannelImageUrl) || !!props.imageUrl,
        };
        console.log("hasImage == " + inputData.hasImage);
        if (!(canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current)) {
            console.error("Invalid canvas reference.");
            handleError("Invalid canvas reference.");
            return;
        }
        const canvas = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current;
        const imageFiles = fileInputListEditChannel;
        if (!imageFiles || imageFiles.length <= 0) {
            console.log("doPublish: no images");
            inputData.hasImage = !!props.imageUrl;
            console.log("Saving Channel: " + JSON.stringify(inputData));
            saveChannelData(inputData, {
                okCallback: (clientDetailCode) => {
                    console.log("okCallback: " + clientDetailCode);
                    handleOk();
                    return;
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    handleError(errorMessage + " (" + clientDetailCode + ")");
                    return;
                },
                authCallback: {
                    isSignedIn: () => props.auth.isSignedIn(),
                    bearer: () => props.auth.bearer,
                    sequence: () => props.auth.sequence,
                    setBearerAndSequence: (bearer, sequence) => {
                        props.auth.setBearerAndSequence(bearer, sequence);
                    },
                    getAuth: () => {
                        return props.auth;
                    },
                },
            }, cacheBusterStore);
            return;
        }
        inputData.hasImage = true;
        const partitionId = "u/" + props.channelOwnerId + "/" + inputData.channelId;
        const inputImageData = {
            canvas,
            filesList: imageFiles,
            targetParameters: [
                {
                    contentId: "channelImage-INDEX-_1920",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 1920,
                    newHeight: 1920,
                },
                {
                    contentId: "channelImage-INDEX-_1024",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 1024,
                    newHeight: 768,
                },
                {
                    contentId: "channelImage-INDEX-_512",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 512,
                    newHeight: 384,
                },
            ],
        };
        scaleAndUploadImages(inputImageData, {
            okCallback: (clientDetailCode) => {
                console.log("Feed: Image save done (" +
                    clientDetailCode +
                    "). Saving Channel data...");
                setFileInputListEditChannel(undefined);
                updateTypesBestEffort(props.auth, partitionId, "channelImage", cacheBusterStore);
                saveChannelData(inputData, {
                    okCallback: (clientDetailCode) => {
                        console.log("okCallback: " + clientDetailCode);
                        handleOk();
                        return;
                    },
                    errorCallback: (clientDetailCode, errorMessage) => {
                        handleError(errorMessage + " (" + clientDetailCode + ")");
                        return;
                    },
                    authCallback: {
                        isSignedIn: () => props.auth.isSignedIn(),
                        bearer: () => props.auth.bearer,
                        sequence: () => props.auth.sequence,
                        setBearerAndSequence: (bearer, sequence) => {
                            props.auth.setBearerAndSequence(bearer, sequence);
                        },
                        getAuth: () => {
                            return props.auth;
                        },
                    },
                }, cacheBusterStore);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                handleError(errorMessage + " (" + clientDetailCode + ")");
                return;
            },
            authCallback: {
                isSignedIn: () => props.auth.isSignedIn(),
                bearer: () => { var _a; return (_a = props.auth) === null || _a === void 0 ? void 0 : _a.bearer; },
                sequence: () => { var _a; return (_a = props.auth) === null || _a === void 0 ? void 0 : _a.sequence; },
                userId: () => props.auth.userId,
                setBearerAndSequence: (bearer, sequence) => {
                    props.auth.setBearerAndSequence(bearer, sequence);
                },
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        console.log("useEffect: editChannelPrompt = " + editChannelPrompt);
        validateEditChannelPrompt(editChannelPrompt);
        return () => { };
    }, [editChannelPrompt]);
    useEffect(() => {
        console.log("useEffect: editChannelText = " + editChannelText);
        validateEditChannelText(editChannelText);
        return () => { };
    }, [editChannelText]);
    useEffect(() => {
        console.log("useEffect: editChannelTitle = " + editChannelTitle);
        validateEditChannelTitle(editChannelTitle);
        return () => { };
    }, [editChannelTitle]);
    useEffect(() => {
        console.log("useEffect: editChannelSubtitle = " + editChannelSubtitle);
        validateeditChannelSubtitle(editChannelSubtitle);
        return () => { };
    }, [editChannelSubtitle]);
    useEffect(() => {
        console.log("useEffect: mount - editChannelSavedOk = " +
            editChannelSavedOk +
            ", editChannelTitle = " +
            editChannelTitle);
        if (editChannelSavedOk) {
            setTimeout(() => {
                if (props.reload) {
                    props.reload(editChannelTitle);
                }
                setEditChannelSavedOk(false);
                setEditChannelTitle("");
                setEditChannelSubtitle("");
                setEditChannelText("");
                setEditChannelImageUrl(undefined);
            }, 4000);
        }
        return () => {
            console.log("useEffect: unmount - editChannelSavedOk");
        };
    }, [editChannelSavedOk]);
    useEffect(() => {
        console.log("useEffect: mount - editChannelSaveError = " + editChannelSaveError);
        if (editChannelSaveError) {
            setTimeout(() => {
                setEditChannelSaveError(undefined);
                setEditChannelSavedOk(false);
                setEditChannelTitle("");
                setEditChannelSubtitle("");
                setEditChannelText("");
                setEditChannelImageUrl(undefined);
                if (props.reload) {
                    props.reload(editChannelTitle);
                }
            }, 5000);
        }
        return () => {
            console.log("useEffect: unmount - editChannelSaveError");
        };
    }, [editChannelSaveError]);
    useEffect(() => {
        console.log("useEffect: mount - editChannelImageDataUrl = " +
            !!editChannelImageDataUrl);
        return () => {
            console.log("useEffect: unmount - editChannelImageDataUrl");
        };
    }, [editChannelImageDataUrl]);
    const editChannelImageFilenameChanged = () => {
        var _a, _b, _c;
        console.log("editChannelImageFilenameChanged");
        if ((_a = hiddenFileInputEditChannel.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputListEditChannel((_b = hiddenFileInputEditChannel.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInputEditChannel.current) === null || _c === void 0 ? void 0 : _c.files[0];
            console.log("editChannelImageFilenameChanged: filename = " + filename.name);
            console.log("editChannelImageFilenameChanged: file type = " + filename.type);
            console.log("editChannelImageFilenameChanged: file size = " + filename.size);
            if (filename.type != "image/jpeg" &&
                filename.type != "image/png" &&
                filename.type != "video/mp4" &&
                filename.type != "video/quicktime") {
                setErrorMessage("Unsupported Image Type: " + filename.type + " " + filename.name);
                return;
            }
            const mediaUrl = URL.createObjectURL(filename);
            console.log("editChannelImageFilenameChanged: mediaUrl = " + mediaUrl);
            if (filename.type == "video/mp4") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                setErrorMessage("Videos are not yet supported.");
                return;
            }
            if (filename.type == "video/quicktime") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                setErrorMessage("Videos are not yet supported.");
                return;
            }
            setEditChannelImageUrl(mediaUrl);
            const img = new Image();
            img.onload = () => {
                console.log("New Edit Channel image loaded.");
                if (canvasRef.current) {
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    let newWidth;
                    let newHeight;
                    if (imgWidth > imgHeight) {
                        newWidth = 1920;
                        newHeight = (1920 * imgHeight) / imgWidth;
                    }
                    else {
                        newHeight = 1920;
                        newWidth = (1920 * imgWidth) / imgHeight;
                    }
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, newWidth, newHeight);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    console.log("imageDataUrl = " + imageDataUrl);
                    console.log("imageDataUrl len = " + imageDataUrl.length);
                    setEditChannelImageDataUrl(imageDataUrl);
                }
            };
            img.onerror = (error) => {
                console.error("Unable to load image : " + mediaUrl + " - " + error);
                setErrorMessage("Unable to load image. " + error);
            };
            img.crossOrigin = "anonymous";
            img.src = mediaUrl;
        }
    };
    const editChannelMediaClicker = () => {
        console.log("editChannelMediaClicker: edit Channel - upload - click");
        if (hiddenFileInputEditChannel.current) {
            console.log("editChannelMediaClicker: Edit Channel - upload - click: Click: hiddenFileInput");
            hiddenFileInputEditChannel.current.click();
        }
        else {
            console.error("editChannelMediaClicker: missing hidden file input reference");
        }
    };
    const editChannelSavingProgress = (_jsxs("div", Object.assign({ style: { width: "100%" } }, { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] })));
    const editChannelCardContent = (_jsxs(Stack, Object.assign({ style: { padding: "10px" } }, { children: [!editChannelSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-title", label: "Title", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log("edit Channel title onChange - " + value);
                    setEditChannelTitle(value);
                }, error: !!editChannelHelperTitle, helperText: editChannelHelperTitle, disabled: editChannelSaving, value: editChannelTitle, sx: { marginBottom: "8px" } }, "cards.editChannel.cardcontent.textfield.title")), !editChannelSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-subtitle", label: "Subtitle", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log("Edit Channel subtitle onChange - " + value);
                    setEditChannelSubtitle(value);
                }, error: !!editChannelHelperSubtitle, helperText: editChannelHelperSubtitle, disabled: editChannelSaving, value: editChannelSubtitle, sx: { marginBottom: "8px" } }, "cards.editChannel.cardcontent.textfield.subtitle")), !editChannelSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static", label: "Describe your channel here...", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("edit channel text onChange - " + value);
                    setEditChannelText(value);
                }, error: !!editChannelHelperText, helperText: editChannelHelperText, disabled: editChannelSaving, value: editChannelText, sx: { marginBottom: "3px" } }, "cards.editChannel.cardcontent.textfield")), !editChannelSaving && !editChannelSavedOk && (_jsx(Button, Object.assign({ variant: editChannelImageUrl ? "outlined" : "contained", endIcon: _jsx(AddAPhoto, {}), onClick: () => {
                    editChannelMediaClicker();
                }, sx: { marginBottom: "10px", marginTop: "5px" } }, { children: "SET PHOTO" }), "cards.editChannel.button.photo")), !editChannelSavedOk && !editChannelSaving && _jsx(Divider, {}), !editChannelSaving && !editChannelSavedOk && (_jsx(FormControl, { children: _jsxs(FormGroup, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: "ACCESS" }), "cards.settings.cardcontent.type.access"), _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "radio-buttons-group-channel-access-label", defaultValue: props.privateChannel ? "private" : "public", name: "radio-buttons-group-channel-access-name", onChange: (event) => {
                                const value = event.target.value;
                                console.log("setAccess: " + value);
                                setAccess(value);
                            }, value: access }, { children: [_jsx(FormControlLabel, { value: "public", control: _jsx(Radio, {}), label: "Public - Anyone can view channel content.", disabled: permissionsAdultContent }), _jsx(FormControlLabel, { value: "private", control: _jsx(Radio, {}), label: "Private - Channel viewers must be approved by channel owner." })] }))] })) })), !editChannelSavedOk && !editChannelSaving && access == "private" && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-memberprompt", label: "What question do you want to ask potential members? (optional)", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("edit channel prompt onChange - " + value);
                    setEditChannelPrompt(value);
                }, error: !!editChannelHelperPrompt, helperText: editChannelHelperPrompt, disabled: editChannelSaving, value: editChannelPrompt, sx: { marginBottom: "3px" } }, "cards.editChannel.cardcontent.memberprompt")), !editChannelSavedOk && !editChannelSaving && _jsx(Divider, {}), !editChannelSavedOk && !editChannelSaving && (_jsx(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: "VIEWER PERMISSIONS" }), "cards.settings.cardcontent.type.permissions")), !editChannelSavedOk && !editChannelSaving && (_jsx(Stack, Object.assign({ direction: "column", sx: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    peditingLeft: "15px",
                    peditingRight: "15px",
                } }, { children: _jsx(FormControl, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsxs(FormGroup, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(FormControlLabel, { label: "Posts contain adult content.", control: _jsx(Checkbox, { checked: permissionsAdultContent, onChange: (event) => {
                                        console.log("permissionsAdultContent - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAdultContent(event.target.checked);
                                        if (event.target.checked) {
                                            setAccess("private");
                                        }
                                    } }) }), _jsx(FormControlLabel, { label: "Viewers can post to the channel.", control: _jsx(Checkbox, { checked: permissionsAllowsPosts, onChange: (event) => {
                                        console.log("permissionsAllowsPosts - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAllowsPosts(event.target.checked);
                                    } }) }), _jsx(FormControlLabel, { label: "Viewers can comment on posts.", control: _jsx(Checkbox, { checked: permissionsAllowsComments, onChange: (event) => {
                                        console.log("permissionsAllowsComments - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAllowsComments(event.target.checked);
                                    } }) })] })) })) }))), _jsxs(Stack, Object.assign({ direction: "row", width: "100%", sx: { display: "flex", justifyContent: "center", marginTop: "5px" }, spacing: 1 }, { children: [_jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInputEditChannel, style: { display: "none" }, onChange: editChannelImageFilenameChanged }, "edit-Channel-hidden-file-input"), _jsx("canvas", { ref: canvasRef, style: { display: "none" } }, "canvas.newChannelcard"), editChannelSaving && editChannelSavingProgress] }), "edit-Channel-stack-2-" + editChannelSavedOk), editChannelSaveError && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", pediting: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "SAVE FAILED" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: editChannelSaveError }))] }, "edit-Channel-error-card-content") }), "edit-Channel-error-card")), editChannelSavedOk && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", pediting: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "SAVED" })), _jsxs(Typography, Object.assign({ variant: "body2", color: "black" }, { children: ["Your settings have been saved.", _jsx("br", {}), "(old images may be cached for 24 hours)"] }))] }, "edit-Channel-saved-ok-card-content") }), "edit-Channel-saved-ok-card" + editChannelSavedOk))] }), "Edit-Channel-stack-card-" + editChannelSavedOk + editChannelSaveError));
    const editChannelCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center", pediting: 0, margin: 0 } }, { children: [!editChannelSaving && !editChannelSavedOk && (_jsx(Button, Object.assign({ endIcon: _jsx(IosShare, {}), variant: "contained", onClick: () => {
                    doPublish();
                }, sx: { margin: "0.5em" }, disabled: !editChannelTitle ||
                    !!editChannelHelperTitle ||
                    !!editChannelHelperSubtitle ||
                    !!editChannelHelperText }, { children: "Save" }), "cards.editChannel.button.publish")), !editChannelSaving && !editChannelSavedOk && (_jsx(Button, Object.assign({ endIcon: _jsx(Close, {}), variant: "outlined", onClick: () => {
                    props.dismiss();
                }, sx: { margin: "0.5em" } }, { children: "Cancel" }), "cards.editChannel.button.close"))] }), "edit-Channel-card-actions"));
    let imageUrl = editChannelImageDataUrl;
    if (props.auth && imageUrl && imageUrl == editChannelImageUrl) {
        imageUrl = authUrl(imageUrl, cacheBusterStore, props.auth);
    }
    if (errorMessage) {
        return (_jsx(ContentCard, { title: "ERROR", keyStr: "error.message.card", textColor: "#000000", backgroundColor: "#FFEEEE", childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })), actions: _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setErrorMessage(undefined);
                    } }, { children: "DISMISS" })) })) }));
    }
    const editChannelCard = (_jsx(ContentCard, { keyStr: "content.card.editChannel." +
            editChannelSavedOk +
            editChannelImageDataUrl, title: "Edit Channel Settings", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, childComponent: editChannelCardContent, actions: editChannelCardActions, mediaUrl: editChannelSavedOk ? undefined : imageUrl, maxWidth: 0.5, overlayIcon: _jsx(Avatar, Object.assign({ sx: {
                marginLeft: "auto",
                backgroundColor: getTheme().palette.violet.light,
            } }, { children: _jsx(Edit, {}) }), "settings.overlay.edit.button"), onMediaClick: () => {
            editChannelMediaClicker();
        } }, "content.card.editChannel." +
        editChannelSavedOk +
        editChannelImageDataUrl));
    return editChannelCard;
};
