var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from "zustand";
import { sendAjaxRequest } from "../API";
var ClientDetailCode;
(function (ClientDetailCode) {
    ClientDetailCode[ClientDetailCode["SUCCESS"] = 1000] = "SUCCESS";
    ClientDetailCode[ClientDetailCode["MISSING_AUTH_HEADER"] = 4001] = "MISSING_AUTH_HEADER";
    ClientDetailCode[ClientDetailCode["INVALID_AUTH_HEADER"] = 4002] = "INVALID_AUTH_HEADER";
    ClientDetailCode[ClientDetailCode["MISSING_EVENT_BODY"] = 4003] = "MISSING_EVENT_BODY";
    ClientDetailCode[ClientDetailCode["INVALID_OPERATION"] = 4004] = "INVALID_OPERATION";
    ClientDetailCode[ClientDetailCode["INVALID_SEQUENCE"] = 4005] = "INVALID_SEQUENCE";
    ClientDetailCode[ClientDetailCode["USER_NOT_SIGNED_IN"] = 4006] = "USER_NOT_SIGNED_IN";
    ClientDetailCode[ClientDetailCode["MISSING_USER_ID"] = 5001] = "MISSING_USER_ID";
    ClientDetailCode[ClientDetailCode["MISSING_TARGET_USER_ID"] = 5002] = "MISSING_TARGET_USER_ID";
    ClientDetailCode[ClientDetailCode["UNEXPECTED_ERROR"] = 7001] = "UNEXPECTED_ERROR";
})(ClientDetailCode || (ClientDetailCode = {}));
export const setLastFriendImageUrl = (url) => {
    localStorage.setItem("lastFriendImageUrl", url);
};
export const getLastFriendImageUrl = () => {
    try {
        const retVal = localStorage.getItem("lastFriendImageUrl");
        if (retVal) {
            return retVal;
        }
        else {
            return undefined;
        }
    }
    catch (err) {
        console.error(err);
        return undefined;
    }
};
export const publishUpdate = (state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        if (state.callback) {
            try {
                state.callback.handleUpdate(state);
                resolve();
            }
            catch (err) {
                reject(err);
            }
        }
    });
});
const loadFriends = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            publishUpdate(state);
            resolve(state);
            return;
        }
        try {
            const payload = {
                userId: userId,
                sequence: auth.sequence,
                operation: "listFriends",
                targetUserId: undefined,
            };
            sendAjaxRequest((ok, status, message) => {
                var _a;
                console.log("FriendStore.load friends result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        state.friends = response.entries;
                        (_a = state.friends) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                            if (!a.displayName || !b.displayName) {
                                return 0;
                            }
                            return a.displayName.localeCompare(b.displayName);
                        });
                        publishUpdate(state);
                        resolve(state);
                        return;
                    }
                    else {
                        const response = JSON.parse(message);
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        publishUpdate(state);
                        resolve(state);
                        return;
                    }
                }
                catch (err) {
                    reject(err);
                    return;
                }
            }, "/friends", payload, auth.bearer);
        }
        catch (err) {
            reject(err);
            return;
        }
    });
});
const loadInvites = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            publishUpdate(state);
            resolve(state);
            return;
        }
        try {
            const payload = {
                userId: userId,
                sequence: auth.sequence,
                operation: "listInvites",
                targetUserId: undefined,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.load invites result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        state.invites = response.entries;
                        publishUpdate(state);
                        resolve(state);
                        return;
                    }
                    else {
                        const response = JSON.parse(message);
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        state.error = response.error;
                        publishUpdate(state);
                        resolve(state);
                        return;
                    }
                }
                catch (err) {
                    reject(err);
                    return;
                }
            }, "friends", payload, auth.bearer);
        }
        catch (err) {
            reject(err);
            return;
        }
    });
});
const inviteFriend = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        state.error = undefined;
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            state.error = "User is not signed in";
            publishUpdate(state);
            resolve(state);
            return;
        }
        try {
            const payload = {
                userId: auth.userId,
                sequence: auth.sequence,
                operation: "sendInvite",
                targetUserId: userId,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.sendInvite result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        publishUpdate(state);
                        if (!auth.userId) {
                            throw new Error("User not signed in.");
                        }
                        loadInvites(auth.userId, auth, state).finally(() => {
                            publishUpdate(state);
                            resolve(state);
                        });
                        return;
                    }
                    else {
                        if (!message) {
                            throw new Error("Unable to decline friend invite. No response received.");
                        }
                        const response = JSON.parse(message);
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        state.error = response.error;
                        publishUpdate(state);
                        resolve(state);
                        return;
                    }
                }
                catch (err) {
                    reject(err);
                    return;
                }
            }, "friends", payload, auth.bearer);
        }
        catch (err) {
            reject(err);
            return;
        }
    });
});
const declineFriendInvite = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        state.error = undefined;
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            state.error = "User is not signed in";
            publishUpdate(state);
            reject(state.error);
            return;
        }
        try {
            const payload = {
                userId: auth.userId,
                sequence: auth.sequence,
                operation: "denyInvite",
                targetUserId: userId,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.declineFriendInvite result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        publishUpdate(state);
                        if (!auth.userId) {
                            throw new Error("User not signed in.");
                        }
                        resolve(state);
                        return;
                    }
                    else {
                        if (!message) {
                            throw new Error("Unable to invite friend. No response received.");
                        }
                        const response = JSON.parse(message);
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        state.error = response.error;
                        publishUpdate(state);
                        reject(state.error);
                        return;
                    }
                }
                catch (err) {
                    reject(err);
                    return;
                }
            }, "friends", payload, auth.bearer);
        }
        catch (err) {
            reject(err);
            return;
        }
    });
});
const acceptFriendInvite = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        state.error = undefined;
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            state.error = "User is not signed in";
            publishUpdate(state);
            reject(state.error);
            return;
        }
        try {
            const payload = {
                userId: auth.userId,
                sequence: auth.sequence,
                operation: "acceptInvite",
                targetUserId: userId,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.acceptFriendInvite result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        publishUpdate(state);
                        if (!auth.userId) {
                            throw new Error("User not signed in.");
                        }
                        resolve(state);
                        return;
                    }
                    else {
                        if (!message) {
                            throw new Error("Unable to accept friend invite. No response received.");
                        }
                        const response = JSON.parse(message);
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        state.error = response.error;
                        publishUpdate(state);
                        reject(state.error);
                        return;
                    }
                }
                catch (err) {
                    reject(err);
                    return;
                }
            }, "friends", payload, auth.bearer);
        }
        catch (err) {
            reject(err);
            return;
        }
    });
});
const doUnfriend = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        state.error = undefined;
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            state.error = "User is not signed in";
            publishUpdate(state);
            reject(state.error);
            return;
        }
        try {
            const payload = {
                userId: auth.userId,
                sequence: auth.sequence,
                operation: "unfriend",
                targetUserId: userId,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.doUnfriend result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        publishUpdate(state);
                        if (!auth.userId) {
                            throw new Error("User not signed in.");
                        }
                        loadFriends(userId, auth, state)
                            .then((state) => {
                            resolve(state);
                        })
                            .catch((err) => {
                            state.error = err.message;
                            reject(err);
                        })
                            .finally(() => {
                            state.loading = false;
                            state.done = true;
                        });
                        return;
                    }
                    else {
                        if (!message) {
                            throw new Error("Unable to remove friend. No response received.");
                        }
                        console.log("doUnfriend: failed message = " + message);
                        const response = JSON.parse(message);
                        console.log("doUnfriend: failed parsed = " + JSON.stringify(response));
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        state.error = response.error;
                        publishUpdate(state);
                        resolve(state);
                        return;
                    }
                }
                catch (err) {
                    console.error(err);
                    state.loading = false;
                    state.done = true;
                    state.ok = false;
                    state.error = err.message;
                    publishUpdate(state);
                    resolve(state);
                    return;
                }
            }, "friends", payload, auth.bearer);
        }
        catch (err) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.error = err.message;
            publishUpdate(state);
            resolve(state);
            return;
        }
    });
});
const checkDidSendInvite = (userId, auth, state) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        state.error = undefined;
        if (!auth || !auth.userId || !auth.bearer) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.clientDetailCode = ClientDetailCode.USER_NOT_SIGNED_IN;
            state.error = "User is not signed in";
            publishUpdate(state);
            reject(new Error(state.error));
            return;
        }
        try {
            const payload = {
                userId: auth.userId,
                sequence: auth.sequence,
                operation: "didSendInvite",
                targetUserId: userId,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.checkDidSendInvite result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = true;
                        publishUpdate(state);
                        if (!auth.userId) {
                            throw new Error("User not signed in.");
                        }
                        if (response.entries && response.entries.length > 0) {
                            publishUpdate(state);
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                        return;
                    }
                    else {
                        if (!message) {
                            throw new Error("Unable to invite friend. No response received.");
                        }
                        const response = JSON.parse(message);
                        state.clientDetailCode = response.clientDetailCode;
                        state.loading = false;
                        state.done = true;
                        state.ok = false;
                        state.error = response.error;
                        publishUpdate(state);
                        reject(new Error(state.error));
                        return;
                    }
                }
                catch (err) {
                    state.loading = false;
                    state.done = true;
                    state.ok = false;
                    state.error = err.message;
                    publishUpdate(state);
                    reject(err);
                    return;
                }
            }, "friends", payload, auth.bearer);
        }
        catch (err) {
            state.loading = false;
            state.done = true;
            state.ok = false;
            state.error = err.message;
            publishUpdate(state);
            reject(err);
            return;
        }
    });
});
export const queryFriends = (userId, auth) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        if (!auth || !auth.userId || !auth.bearer) {
            reject(new Error("User is not signed in"));
            return;
        }
        try {
            const payload = {
                userId: userId,
                sequence: auth.sequence,
                operation: "listFriends",
                targetUserId: undefined,
            };
            sendAjaxRequest((ok, status, message) => {
                console.log("FriendStore.query friends result = " +
                    ok +
                    " " +
                    status +
                    " " +
                    message);
                try {
                    if (ok) {
                        const response = JSON.parse(message);
                        if (response.bearer && response.sequence) {
                            auth.setBearer(response.bearer);
                            auth.setSequence(response.sequence);
                        }
                        resolve(response);
                        return;
                    }
                    else {
                        const response = JSON.parse(message);
                        reject(new Error("Unable to query friends list. " +
                            response.clientDetailCode +
                            " " +
                            response.error));
                        return;
                    }
                }
                catch (err) {
                    reject(err);
                    return;
                }
            }, "/friends", payload, auth.bearer);
        }
        catch (err) {
            reject(err);
            return;
        }
    });
});
export const useFriendsStore = create()((set, get) => ({
    userId: undefined,
    ok: false,
    loading: false,
    done: false,
    error: undefined,
    clientDetailCode: undefined,
    friends: undefined,
    invites: undefined,
    load: (userId, auth) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve) => {
            const state = get();
            state.userId = userId;
            state.loading = true;
            state.done = false;
            publishUpdate(state);
            set(state);
            loadFriends(userId, auth, state)
                .then((state) => {
                publishUpdate(state);
                set(state);
                return state;
            })
                .then((state) => {
                return loadInvites(userId, auth, state);
            })
                .then((state) => {
                publishUpdate(state);
                set(state);
                return state;
            })
                .catch((error) => {
                console.error("FriendStore.load " + error);
                state.error = error;
                state.clientDetailCode = ClientDetailCode.UNEXPECTED_ERROR;
                publishUpdate(state);
            })
                .finally(() => {
                console.log("FriendStore - done");
                state.loading = false;
                state.done = true;
                publishUpdate(state);
                set(state);
                resolve();
            });
        });
    }),
    sendInvite: (userId, auth) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve) => {
            const state = get();
            state.loading = true;
            state.done = false;
            set(state);
            publishUpdate(state);
            inviteFriend(userId, auth, state)
                .then((state) => {
                set(state);
                publishUpdate(state);
                return state;
            })
                .catch((error) => {
                console.error("FriendStore.inviteFriend " + error);
                state.error = error.message;
                state.clientDetailCode = ClientDetailCode.UNEXPECTED_ERROR;
                publishUpdate(state);
            })
                .finally(() => {
                console.log("FriendStore - done");
                state.loading = false;
                state.done = true;
                set(state);
                publishUpdate(state);
                resolve();
            });
        });
    }),
    declineInvite: (userId, auth) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve) => {
            const state = get();
            state.loading = true;
            state.done = false;
            set(state);
            publishUpdate(state);
            declineFriendInvite(userId, auth, state)
                .then((state) => {
                publishUpdate(state);
                set(state);
                return state;
            })
                .catch((error) => {
                console.error("FriendStore.declineInvite " + error);
                state.error = error.message;
                state.clientDetailCode = ClientDetailCode.UNEXPECTED_ERROR;
                publishUpdate(state);
            })
                .finally(() => {
                console.log("FriendStore - done");
                state.loading = false;
                state.done = true;
                set(state);
                publishUpdate(state);
                resolve();
            });
        });
    }),
    acceptInvite: (userId, auth) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve) => {
            const state = get();
            state.loading = true;
            state.done = false;
            set(state);
            publishUpdate(state);
            acceptFriendInvite(userId, auth, state)
                .then((state) => {
                set(state);
                publishUpdate(state);
                return state;
            })
                .catch((error) => {
                console.error("FriendStore.acceptInvite " + error);
                state.error = error.message;
                state.clientDetailCode = ClientDetailCode.UNEXPECTED_ERROR;
                publishUpdate(state);
            })
                .finally(() => {
                console.log("FriendStore - done");
                state.loading = false;
                state.done = true;
                set(state);
                publishUpdate(state);
                resolve();
            });
        });
    }),
    didSendInvite: (userId, auth) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const state = get();
            state.loading = true;
            state.done = false;
            set(state);
            publishUpdate(state);
            checkDidSendInvite(userId, auth, state)
                .then((checkResult) => {
                publishUpdate(state);
                resolve(checkResult);
            })
                .catch((err) => {
                publishUpdate(state);
                reject(err);
            })
                .finally(() => {
                state.loading = false;
                state.done = true;
                set(state);
                publishUpdate(state);
            });
        });
    }),
    unfriend: (userId, auth) => {
        console.log("FriendStore.unfriend: start");
        return new Promise((resolve, reject) => {
            const state = get();
            state.loading = true;
            state.done = false;
            set(state);
            publishUpdate(state);
            doUnfriend(userId, auth, state)
                .then((state) => {
                console.log("FriendStore.unfriend: ok");
                set(state);
                publishUpdate(state);
                resolve();
            })
                .catch((err) => {
                console.error("FriendStore.unfriend: error = " + err);
                state.error = err.message;
                set(state);
                publishUpdate(state);
                reject(err);
            })
                .finally(() => {
                console.log("FriendStore.unfriend: finally");
                state.loading = false;
                state.done = true;
                set(state);
                publishUpdate(state);
            });
        });
    },
    reset: () => {
        set({
            ok: false,
            loading: false,
            done: false,
            error: undefined,
            clientDetailCode: undefined,
            friends: undefined,
            invites: undefined,
            callback: undefined,
        });
        publishUpdate(get());
    },
}));
