import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import getTheme from "./Theme";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, LinearProgress, Link, Stack, TextField, ThemeProvider, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { useEffect, useRef, useState } from "react";
import { getChannelViewBaseURL, getContentURL } from "./ContentAPI";
import { ContentCard } from "./components/ContentCard";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { scaleAndUploadImages, scaleDownImage, updateTypesBestEffort, } from "./ImageUtils";
import { channelOperation, DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED_SESSION_EXPIRED, deletePost, loadChannelData, saveChannelData, } from "./DataUtils";
import { 
//  PersonAdd,
//  Place,
Edit, Settings as SettingsIcon, } from "@mui/icons-material";
import { PostCard } from "./components/PostCard";
import { LoadMoreButton } from "./components/LoadMoreButton";
import { UserAvatar } from "./components/UserAvatar";
import { NewPostCard } from "./components/NewPostCard";
import { authUrl, useCacheBusterStore } from "./UrlUrils";
import { forceLogin } from "./Auth/AuthUtils";
import { TopButtonMenu } from "./components/TopButtonMenu";
import { useLogger } from "./logger";
const cameraImg = "./img/camera_512.jpg";
const questionImg = "./img/question_512.jpg";
const unlockedImg = "./img/unlocked_512.jpg";
const channelsImg = "./img/channels_512.jpg";
const LOGPREFIX = "Feed: ";
export function Feed() {
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [forceRender, setForceRender] = useState(cacheBusterStore.getCacheBusterNumber());
    const [helpVisible, setHelpVisible] = useState(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [feedDataLoaded, setFeedDataLoaded] = useState(false);
    const [feedTitleText, setFeedTitleText] = useState("");
    const [feedTitleTextModified, setFeedTitleTextModified] = useState(false);
    const [feedTitleTextError, setFeedTitleTextError] = useState("");
    const [feedDescriptionText, setFeedDescriptionText] = useState("Welcome to my Feed!");
    const [feedDescriptionTextError, setFeedDescriptionTextError] = useState("");
    const [feedImageUrl, setFeedImageUrl] = useState(cameraImg);
    const [feedSaving, setFeedSaving] = useState(false);
    const [feedSavedOk, setFeedSavedOk] = useState(false);
    const [feedSaveError, setFeedSaveError] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    const [fileInputList, setFileInputList] = useState();
    const [postDeletedOk, setPostDeletedOk] = useState();
    const hiddenFileInput = useRef(null);
    const feedTitleTextFieldRef = useRef(null);
    const feedDescriptionTextFieldRef = useRef(null);
    const canvasRef = useRef(null);
    const [postIds, setPostIds] = useState();
    const [hasMorePosts, setHasMorePosts] = useState(false);
    const [permissionsAdultContent, setPermissionsAdultContent] = useState(false);
    const [permissionsRequiresSignIn, setPermissionsRequiresSignIn] = useState(false);
    const [permissionsRequiresFriend, setPermissionsRequiresFriend] = useState(false);
    const [permissionsAllowsComments, setPermissionsAllowsComments] = useState(false);
    const logger = useLogger((state) => state);
    //  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string>("");
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    const validateFeedFields = () => {
        logger.log(LOGPREFIX + "validateFeedFields - title = " + feedTitleText);
        logger.log(LOGPREFIX + "validateFeedFields - description = " + feedDescriptionText);
        const titleValue = feedTitleText === null || feedTitleText === void 0 ? void 0 : feedTitleText.trim();
        if (feedTitleTextModified && !titleValue) {
            setFeedTitleTextError("Title cannot be empty.");
        }
        else {
            setFeedTitleTextError(undefined);
        }
        const descValue = feedDescriptionText === null || feedDescriptionText === void 0 ? void 0 : feedDescriptionText.trim();
        if (!descValue) {
            setFeedDescriptionTextError("Description cannot be empty.");
        }
        else {
            setFeedDescriptionTextError(undefined);
        }
        // TODO: Validation
    };
    const loadMorePosts = () => {
        logger.log(LOGPREFIX + "loadMorePosts");
        if (!postIds || postIds.length <= 0 || !hasMorePosts) {
            logger.log(LOGPREFIX + "loadMorePosts: No posts exist.");
            return;
        }
        if (!auth || !auth.isSignedIn() || !auth.userId) {
            logger.log(LOGPREFIX + "not signed in");
            return;
        }
        const lastPostId = postIds.at(postIds.length - 1);
        logger.log(LOGPREFIX + "loadMorePosts: lastPostId = " + lastPostId);
        setLoadingMore(true);
        channelOperation({
            operation: "listPosts",
            channelOwnerId: auth.userId,
            channelId: auth.userId,
            postOwnerId: auth.userId,
            postId: lastPostId,
        }, {
            okCallback: (clientDetailCode, data) => {
                logger.log(LOGPREFIX + "Feed: loadMorePosts: " + data);
                setLoadingMore(false);
                const results = JSON.parse(data);
                if (results === null || results === void 0 ? void 0 : results.postIds) {
                    results.postIds.forEach((entry) => {
                        if (postIds.includes(entry)) {
                            logger.error(LOGPREFIX + "Feed: loadMorePosts: DUPLICATE: " + entry);
                        }
                        else {
                            logger.log(LOGPREFIX + "Feed: loadMorePosts: ADDING: " + entry);
                            postIds.push(entry);
                        }
                    });
                }
                if (results === null || results === void 0 ? void 0 : results.hasMorePosts) {
                    setHasMorePosts(true);
                }
                else {
                    setHasMorePosts(false);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "Feed: listPosts: ERROR = " +
                    clientDetailCode +
                    " - " +
                    errorMessage);
                setErrorMessage("Unable to load posts. " +
                    errorMessage +
                    " (" +
                    clientDetailCode +
                    ")");
                setLoadingMore(false);
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
        // TODO: Get more post IDs to load.
    };
    const deletePostParent = (basePath, postJson) => {
        logger.log(LOGPREFIX + "deletePostParent: " + basePath + " " + postJson);
        if (!(auth === null || auth === void 0 ? void 0 : auth.userId)) {
            // TODO: Error
            return;
        }
        deletePost({
            operation: "deletePost",
            channelOwnerId: auth.userId,
            channelId: auth.userId,
            postOwnerId: auth.userId,
            postId: postJson,
        }, {
            okCallback: (clientDetailCode) => {
                logger.log(LOGPREFIX + "deletePost: OK " + clientDetailCode);
                setPostDeletedOk(true);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "deletePost: ERROR " +
                    clientDetailCode +
                    " " +
                    errorMessage);
                let message = errorMessage;
                if (!message) {
                    message = "Unknown error";
                }
                setErrorMessage(message + " (" + clientDetailCode + ")");
            },
        }, {
            isSignedIn: () => auth.isSignedIn(),
            bearer: () => auth.bearer,
            sequence: () => auth.sequence,
            setBearerAndSequence: (bearer, sequence) => {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        }, cacheBusterStore);
    };
    const dataLoaderCallbacks = {
        okCallback: (clientDetailCode, data) => {
            var _a, _b;
            logger.log(LOGPREFIX + "Feed: okCallback: " + clientDetailCode + " " + data);
            setLoading(false);
            setHelpVisible(false);
            setPrivacyVisible(false);
            try {
                // Load Channel Data from JSON File
                const dataObj = JSON.parse(data);
                if (dataObj.title) {
                    setFeedTitleText(dataObj.title);
                }
                if (dataObj.description) {
                    setFeedDescriptionText(dataObj.description);
                }
                if ((_a = dataObj.permissions) === null || _a === void 0 ? void 0 : _a.permissionsAdultContent) {
                    setPermissionsAdultContent(true);
                }
                else {
                    setPermissionsAdultContent(false);
                }
                if (dataObj.private) {
                    setPermissionsRequiresFriend(true);
                }
                else {
                    setPermissionsRequiresFriend(false);
                }
                if ((_b = dataObj.permissions) === null || _b === void 0 ? void 0 : _b.permissionsAllowsComments) {
                    setPermissionsAllowsComments(true);
                }
                else {
                    setPermissionsAllowsComments(false);
                }
                const imageUrl = getContentURL() +
                    "/u/" +
                    auth.userId +
                    "/" +
                    auth.userId +
                    "/channelImage_512.jpg";
                if (dataObj.hasImage) {
                    setFeedImageUrl(imageUrl);
                }
                setFeedDataLoaded(true);
                setForceRender(forceRender + 1);
            }
            catch (err) {
                logger.error(LOGPREFIX + "Feed.dataLoaderCallbacks: okCallback: error = " + err);
                // TODO
            }
        },
        errorCallback: (clientDetailCode, errorMessage) => {
            logger.log(LOGPREFIX +
                "Feed.dataLoaderCallbacks: errorCallback: " +
                clientDetailCode +
                " " +
                errorMessage);
            setLoading(false);
            if (!errorMessage) {
                setErrorMessage("Unable to load feed data.");
            }
            else {
                if (errorMessage.includes("NoSuchKey")) {
                    setErrorMessage(undefined);
                    setFeedTitleText(auth.displayName + "'s Feed");
                    setFeedDescriptionText("Welcome to my Feed!");
                }
                else {
                    setErrorMessage(errorMessage);
                }
            }
            setHelpVisible(true);
            setPrivacyVisible(true);
            setSettingsVisible(true);
            if (clientDetailCode ==
                DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED_SESSION_EXPIRED) {
                forceLogin(navigate);
            }
        },
    };
    const loadFeedData = () => {
        if (feedDataLoaded) {
            logger.log(LOGPREFIX + "loadFeedData - skip");
            setLoading(false);
            return;
        }
        logger.log(LOGPREFIX + "loadFeedData - start");
        if (!auth || !auth.userId) {
            dataLoaderCallbacks.errorCallback(0, "Missing user ID.");
            setLoading(false);
            return;
        }
        setSettingsVisible(false);
        const userId = auth.userId;
        setLoading(true);
        loadChannelData("u", userId, userId, "channelData", "json", dataLoaderCallbacks, auth, cacheBusterStore);
    };
    useEffect(() => {
        if (postDeletedOk) {
            setTimeout(() => {
                setPostDeletedOk(false);
                setPostIds(undefined);
                setFeedDataLoaded(false);
            }, 3000);
        }
        return () => { };
    }, [postDeletedOk]);
    useEffect(() => {
        const retVal = () => { };
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                logger.log(LOGPREFIX + "User NOT logged in - navigating...");
                forceLogin(navigate);
                return retVal;
            }
        }
        else {
            if (!feedDataLoaded) {
                loadFeedData();
            }
        }
        return retVal;
    }, [auth, feedDataLoaded]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: mount - feedTitleText = " + feedTitleText);
        validateFeedFields();
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - feedTitleText");
        };
    }, [feedTitleText]);
    useEffect(() => {
        logger.log(LOGPREFIX +
            "useEffect: mount - feedDescriptionText = " +
            feedDescriptionText);
        validateFeedFields();
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - feedDescriptionText");
        };
    }, [feedDescriptionText]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: mount - feedImageUrl = " + feedImageUrl);
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - feedImageUrl");
        };
    }, [feedImageUrl]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: mount - feedSavedOk = " + feedSavedOk);
        if (feedSavedOk) {
            setTimeout(() => {
                setFeedSavedOk(false);
            }, 3500);
        }
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - feedSavedOk");
        };
    }, [feedSavedOk]);
    useEffect(() => {
        if (feedDataLoaded && auth.userId && !postIds) {
            setLoading(true);
            channelOperation({
                operation: "listPosts",
                channelOwnerId: auth.userId,
                channelId: auth.userId,
            }, {
                okCallback: (clientDetailCode, data) => {
                    logger.log(LOGPREFIX + "Feed: listPosts: " + data);
                    setLoading(false);
                    const results = JSON.parse(data);
                    if (results === null || results === void 0 ? void 0 : results.postIds) {
                        setPostIds(results.postIds);
                    }
                    if (results === null || results === void 0 ? void 0 : results.hasMorePosts) {
                        setHasMorePosts(true);
                    }
                    else {
                        setHasMorePosts(false);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    logger.log(LOGPREFIX + "Feed: listPosts: ERROR = " + errorMessage);
                    setLoading(false);
                    if (!errorMessage) {
                        setErrorMessage("Unknown failure");
                    }
                    else {
                        setErrorMessage(errorMessage + " " + clientDetailCode);
                    }
                },
            }, {
                isSignedIn: () => auth.isSignedIn(),
                bearer: () => auth.bearer,
                sequence: () => auth.sequence,
                setBearerAndSequence: (bearer, sequence) => {
                    auth.setBearerAndSequence(bearer, sequence);
                },
                getAuth: () => {
                    return auth;
                },
            }, cacheBusterStore);
        }
        return () => { };
    }, [feedDataLoaded, postIds]);
    const feedImageFilenameChanged = () => {
        var _a, _b, _c;
        logger.log(LOGPREFIX + "feedImageFilenameChanged");
        if ((_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputList((_b = hiddenFileInput.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInput.current) === null || _c === void 0 ? void 0 : _c.files[0];
            logger.log(LOGPREFIX + "feedImageFilenameChanged: filename = " + filename);
            const imageUrl = URL.createObjectURL(filename);
            logger.log(LOGPREFIX + "feedImageFilenameChanged: imageUrl = " + imageUrl);
            setFeedImageUrl(imageUrl);
            const img = new Image();
            img.onload = () => {
                logger.log(LOGPREFIX + "New feed image loaded.");
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, 1920, 1920);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    logger.log(LOGPREFIX + "imageDataUrl = " + imageDataUrl);
                    logger.log(LOGPREFIX + "imageDataUrl len = " + imageDataUrl.length);
                    setForceRender(forceRender + 1);
                }
            };
            img.onerror = (error) => {
                logger.error(LOGPREFIX + "Unable to load image : " + imageUrl + " - " + error);
            };
            img.crossOrigin = "anonymous";
            img.src = imageUrl;
        }
    };
    const showFeedSavedOkWithTimer = () => {
        setFeedSaving(false);
        setFeedSavedOk(true);
        setFeedSaveError("");
        setFileInputList(undefined);
        setTimeout(() => {
            setFeedSavedOk(false);
            setSettingsVisible(false);
        }, 3500);
    };
    const saveFeedData = () => {
        logger.log(LOGPREFIX + "saveFeedData");
        const title = feedTitleText;
        if (!title || !title.trim()) {
            setFeedSaveError("Feed title is not set.");
            return;
        }
        if (!feedDescriptionText || !feedDescriptionText.trim()) {
            setFeedSaveError("Feed description is not set.");
            return;
        }
        if (!auth.isSignedIn() || !auth.userId) {
            setFeedSaveError("Feed description is not set.");
            return;
        }
        const canvas = canvasRef.current;
        if (!canvas) {
            logger.error(LOGPREFIX + "no canvas");
            return;
        }
        const files = fileInputList;
        const input = {
            title: title,
            description: feedDescriptionText,
            channelOwnerId: auth.userId,
            channelId: auth.userId,
            private: permissionsRequiresFriend ? true : false,
            permissions: {
                permissionsAdultContent,
                permissionsAllowsComments,
            },
        };
        if (!files || files.length <= 0) {
            logger.log(LOGPREFIX + "no file list");
            input.hasImage = false;
        }
        else {
            input.hasImage = true;
        }
        setFeedSaving(true);
        setFeedSaveError("");
        setFeedSavedOk(false);
        const saveChannelImage = () => {
            const canvas = canvasRef.current;
            if (!canvas) {
                logger.error(LOGPREFIX + "no canvas");
                return;
            }
            const files = fileInputList;
            if (!files || files.length <= 0) {
                logger.log(LOGPREFIX + "no file list");
                showFeedSavedOkWithTimer();
                return;
            }
            logger.log(LOGPREFIX + "saveChannelImage: files = " + JSON.stringify(files));
            const input = {
                canvas,
                filesList: files,
                targetParameters: [
                    {
                        contentId: "channelImage_1920",
                        fileType: "jpg",
                        partition: "u/" + auth.userId + "/" + auth.userId,
                        newWidth: 1920,
                        newHeight: 1920,
                    },
                    {
                        contentId: "channelImage_1024",
                        fileType: "jpg",
                        partition: "u/" + auth.userId + "/" + auth.userId,
                        newWidth: 1024,
                        newHeight: 768,
                    },
                    {
                        contentId: "channelImage_512",
                        fileType: "jpg",
                        partition: "u/" + auth.userId + "/" + auth.userId,
                        newWidth: 512,
                        newHeight: 384,
                    },
                ],
            };
            const callbacks = {
                okCallback: (clientDetailCode) => {
                    logger.log(LOGPREFIX + "Multi-file Upload OK: " + clientDetailCode);
                    updateTypesBestEffort(auth, "u/" + auth.userId + "/" + auth.userId, "channelImage_", cacheBusterStore);
                    showFeedSavedOkWithTimer();
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    logger.log(LOGPREFIX +
                        "Multi-file Upload Failed: " +
                        clientDetailCode +
                        " " +
                        errorMessage);
                    setFeedSaving(false);
                    setFeedSavedOk(false);
                    setFeedSaveError("Unable to upload image. " + errorMessage);
                },
                authCallback: {
                    isSignedIn: () => {
                        return auth.isSignedIn();
                    },
                    bearer: () => {
                        return auth.bearer;
                    },
                    sequence: () => {
                        return auth.sequence;
                    },
                    userId: () => {
                        return auth.userId;
                    },
                    setBearerAndSequence: (bearer, sequence) => {
                        auth.setBearerAndSequence(bearer, sequence);
                    },
                },
            };
            scaleAndUploadImages(input, callbacks, cacheBusterStore);
        };
        saveChannelData(input, {
            okCallback: (clientDetailCode) => {
                logger.log(LOGPREFIX + "okCallback: " + clientDetailCode);
                saveChannelImage();
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.log(LOGPREFIX +
                    "errorCallback: " +
                    clientDetailCode +
                    " " +
                    errorMessage);
                setFeedSaving(false);
                setFeedSaveError(errorMessage + " (" + clientDetailCode + ")");
            },
            authCallback: {
                isSignedIn: () => {
                    return auth.isSignedIn();
                },
                bearer: () => {
                    return auth.bearer;
                },
                sequence: () => {
                    return auth.sequence;
                },
                setBearerAndSequence: (bearer, sequence) => {
                    auth.setBearerAndSequence(bearer, sequence);
                },
                getAuth: () => {
                    return auth;
                },
            },
        }, cacheBusterStore);
    };
    let topLinkCard;
    if (auth.displayName) {
        topLinkCard = (_jsx(Button, Object.assign({ href: getChannelViewBaseURL() + encodeURI(auth.displayName), target: "_blank", variant: "contained", sx: { marginLeft: "5px", marginRight: "5px", marginTop: "15px" } }, { children: "Switch to Public View" })));
    }
    const privacyCardText = (_jsxs(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main, sx: { textAlign: "left", padding: "10px" }, gutterBottom: true }, { children: ["Anyone can see your feed title, description, and image. You may choose whether feed posts may be viewed by anyone or only by your friends.", _jsx("br", {}), _jsx("br", {}), "You can also post content or host discussions by creating additional\u00A0", _jsx(Link, Object.assign({ href: "/Channels" }, { children: "Channels" }), "privacy.channels.link"), ".", _jsx("br", {}), _jsx("br", {}), "You may also review our", " ", _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=27991f50-d1d3-4aa9-a83a-41a121bc371c", target: "_blank" }, { children: "Privacy Notice" }), "privacy.policy.link"), " ", "and", " ", _jsx(Link, Object.assign({ href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=426bfff1-769a-4e7b-a7ff-23c76382b689", target: "_blank" }, { children: "Terms of Use" }), "terms.of.use"), "."] }), "card.privacy.text.typography"));
    const privacyCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    navigate("/Channels");
                } }, { children: "Channels" }), "privacy.channels.button"), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                    setPrivacyVisible(false);
                } }, { children: "Hide" }), "privacy.visible.button")] })));
    const privacyCard = (_jsx(ContentCard, { keyStr: "cards.privacy", title: "Privacy Notice", mediaUrl: unlockedImg, childComponent: privacyCardText, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, actions: privacyCardActions }, "cards.privacy"));
    const helpButtonDiv = (_jsx("div", { children: _jsx(Link, Object.assign({ href: "#", onClick: () => {
                logger.log(LOGPREFIX + "help link - onClick");
                setHelpVisible(true);
                setPrivacyVisible(true);
            } }, { children: _jsx(Typography, Object.assign({ variant: "h6", color: "primary", sx: { margin: "5px" }, gutterBottom: true }, { children: "Show Help & Privacy Notice" })) }), "help.visible.link") }));
    const feedUrl = getChannelViewBaseURL() + auth.displayName;
    const feedLink = (_jsx(Link, Object.assign({ href: feedUrl, target: "_blank", fontSize: "18px" }, { children: feedUrl })));
    const helpCardText = (_jsxs(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main, sx: { textAlign: "left", padding: "10px" }, gutterBottom: true }, { children: ["Feed is your own personal blog space where you share content. Once you set a title, description, and image for your feed channel, you can then post articles and images. Share your feed link with your friends.", _jsx("br", {}), _jsx("br", {}), "Your feed link is:", _jsx("br", {}), feedLink, _jsx("br", {}), _jsx("br", {}), "Welcome to your Feed!"] }), "card.help.text.typography"));
    const helpCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    window.open(feedUrl);
                } }, { children: "View My Feed" }), "help.preview.button"), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                    if (helpVisible) {
                        setHelpVisible(false);
                    }
                    else {
                        setHelpVisible(true);
                    }
                } }, { children: "Hide" }), "help.visible.button")] })));
    const helpCard = (_jsx(ContentCard, { keyStr: "cards.help", title: "Feed - What is this?", mediaUrl: questionImg, childComponent: helpCardText, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, actions: helpCardActions }, "cards.help"));
    let settingsProgress = (_jsxs("div", { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] }));
    if (!feedSaving) {
        settingsProgress = _jsx("div", {});
        if (feedSaveError) {
            settingsProgress = (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "SAVE FAILED" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: feedSaveError }))] }, "feed.saving.error.content") }), "feed.saving.error"));
        }
        else if (feedSavedOk) {
            settingsProgress = (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", padding: "4" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "SAVED" })), _jsx(Typography, Object.assign({ variant: "body2", color: "black" }, { children: "Your changes have been saved." }))] }, "feed.saved.ok.card.content") }), "feed.saved.ok.card"));
        }
    }
    const settingsCardContent = (_jsxs("div", Object.assign({ style: { padding: "10px" } }, { children: [_jsxs(Typography, Object.assign({ variant: "body1", color: "black", textAlign: "left" }, { children: ["Set the title, subtitle, description, and image. These will appear at the top of your feed page.", _jsx("br", {}), _jsx("br", {})] })), _jsx(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: "TITLE" }), "cards.settings.cardcontent.type.title"), _jsx(TextField, { ref: feedTitleTextFieldRef, variant: "outlined", label: "Title", size: "small", maxRows: 10, sx: { width: "auto", marginLeft: "10px", marginRight: "10px" }, color: "black", value: feedTitleText, onChange: (event) => {
                    const value = event.target.value;
                    logger.log(LOGPREFIX + "feed title onChange - " + value);
                    setFeedTitleText(value);
                    setFeedSavedOk(false);
                    setFeedTitleTextModified(true);
                }, error: !!feedTitleTextError, helperText: feedTitleTextError, disabled: feedSaving, multiline: true }, "cards.settings.cardcontent.textfield.title"), _jsxs(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: [_jsx("br", {}), "DESCRIPTION"] }), "cards.settings.cardcontent.type.description"), _jsx(TextField, { id: "outlined-multiline-static", label: "Description", multiline: true, rows: 5, fullWidth: true, value: feedDescriptionText, color: "black", ref: feedDescriptionTextFieldRef, onChange: (event) => {
                    const value = event.target.value;
                    logger.log(LOGPREFIX + "feed desc onChange - " + value);
                    setFeedDescriptionText(value);
                    setFeedSavedOk(false);
                }, error: !!feedDescriptionTextError, helperText: feedDescriptionTextError, disabled: feedSaving }, "cards.settings.cardcontent.textfield.description"), _jsxs(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: [_jsx("br", {}), "VIEWER PERMISSIONS"] }), "cards.settings.cardcontent.type.permissions"), _jsx(Typography, Object.assign({ variant: "body2", color: "grey" }, { children: "(optional)" })), _jsx(Stack, Object.assign({ direction: "column", sx: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                } }, { children: _jsx(FormControl, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsxs(FormGroup, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(FormControlLabel, { label: "Posts contain adult content.", control: _jsx(Checkbox, { checked: permissionsAdultContent, onChange: (event) => {
                                        logger.log(LOGPREFIX +
                                            "permissionsAdultContent - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAdultContent(event.target.checked);
                                        if (event.target.checked) {
                                            setPermissionsRequiresSignIn(true);
                                        }
                                    } }) }), _jsx(FormControlLabel, { label: "Posts can only be viewed by my friends.", control: _jsx(Checkbox, { checked: permissionsRequiresFriend, onChange: (event) => {
                                        logger.log(LOGPREFIX +
                                            "permissionsRequiresFriend - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsRequiresFriend(event.target.checked);
                                    } }) }), _jsx(FormControlLabel, { label: "Viewers can comment on posts.", control: _jsx(Checkbox, { checked: permissionsAllowsComments, onChange: (event) => {
                                        logger.log(LOGPREFIX +
                                            "permissionsAllowsComments - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAllowsComments(event.target.checked);
                                    } }) })] })) })) })), settingsProgress] }), "settings.card.content." + forceRender));
    let settingsCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    saveFeedData();
                }, disabled: feedDescriptionTextError || feedTitleTextError ? true : false }, { children: "Save" }), "settings.card.action.save.button"), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                    setSettingsVisible(false);
                } }, { children: "Hide" }), "settings.card.action.hide.button")] }), "settings.card.actions"));
    if (feedSaving) {
        settingsCardActions = _jsx(CardActions, {});
    }
    let mediaClicker = undefined;
    if (!feedSaving) {
        mediaClicker = () => {
            logger.log(LOGPREFIX + "settings - upload - click");
            if (hiddenFileInput.current) {
                logger.log(LOGPREFIX + "settings - upload - click: Click: hiddenFileInput");
                hiddenFileInput.current.click();
            }
        };
    }
    const settingsCard = (_jsx(ContentCard, { keyStr: "settings.card." + forceRender, title: "Feed Settings", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, childComponent: settingsCardContent, actions: settingsCardActions, mediaUrl: authUrl(feedImageUrl, cacheBusterStore, auth), mediaFallbackUrl: cameraImg, onMediaClick: mediaClicker, onMediaLoad: () => {
            logger.log(LOGPREFIX + "Settings Card - media loaded OK");
        }, onMediaLoadError: () => {
            logger.log(LOGPREFIX + "Settings Card - media load failed");
        }, maxWidth: 0.5, overlayIcon: _jsx(Avatar, Object.assign({ sx: {
                marginLeft: "auto",
                backgroundColor: getTheme().palette.violet.light,
            } }, { children: _jsx(Edit, {}) }), "settings.overlay.edit.button") }, "settings.card." + forceRender));
    const makeSettingsVisible = () => {
        setSettingsVisible(true);
    };
    const channelCardActions = (_jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: makeSettingsVisible, endIcon: _jsx(SettingsIcon, {}) }, { children: "Edit Feed Settings" })) })));
    const userAvatar = (_jsx(UserAvatar, { userId: auth.userId ? auth.userId : "", keyStr: auth.userId + ".avatar", onUserClicked: () => {
            navigate("/ProfileView?userId=" + auth.userId);
        }, userName: auth.displayName ? auth.displayName : "Unknown", userNameVisible: false, auth: auth }, auth.userId + ".avatar"));
    const channelCardChildContent = (_jsxs("div", Object.assign({ style: { padding: 0, margin: 0 } }, { children: [_jsx(Typography, Object.assign({ whiteSpace: "pre-line", variant: "body1", color: "#444444", sx: { textAlign: "left", padding: "10px", wordBreak: "break-word" } }, { children: feedDescriptionText ? feedDescriptionText : "" }), "channel.card." + feedImageUrl + forceRender + "-child-typography"), (permissionsAdultContent ||
                permissionsAllowsComments ||
                permissionsRequiresFriend ||
                permissionsRequiresSignIn) && (_jsxs("div", Object.assign({ style: {
                    padding: 0,
                    margin: 0,
                    paddingTop: "15px",
                    paddingBottom: "10px",
                    color: "#999999",
                    fontSize: "small",
                } }, { children: [_jsx(Divider, {}), _jsxs(Typography, Object.assign({ whiteSpace: "pre-line", variant: "body1", color: "#444444", sx: { textAlign: "left", padding: "10px", wordBreak: "break-word" } }, { children: [permissionsAllowsComments && (_jsxs("span", { children: ["- Viewers can comment and like Feed posts.", _jsx("br", {})] })), permissionsRequiresSignIn && (_jsxs("span", { children: ["- Feed can be viewed ONLY by signed-in users.", _jsx("br", {})] })), permissionsRequiresFriend && (_jsxs("span", { children: ["- Feed can be viewed ONLY by friends.", _jsx("br", {})] })), permissionsAdultContent && (_jsxs("span", { children: ["- Feed contains adult content.", _jsx("br", {})] }))] }), "channel.card.permissions." +
                        feedImageUrl +
                        forceRender +
                        "-child-typography")] })))] })));
    const channelCard = (_jsx(ContentCard, { keyStr: "channel.card." + feedImageUrl + forceRender, title: feedTitleText ? feedTitleText : "", titleAvatar: userAvatar, backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, mediaUrl: authUrl(feedImageUrl, cacheBusterStore, auth), mediaFallbackUrl: channelsImg, onMediaLoad: () => {
            logger.log(LOGPREFIX + "Channel Card - media loaded OK");
        }, onMediaLoadError: () => {
            logger.log(LOGPREFIX + "Channel Card - media load failed");
        }, maxWidth: 0.5, actions: channelCardActions, childComponent: channelCardChildContent, header: _jsx(CardHeader, { title: "Feed Heading", titleTypographyProps: { variant: "body2" }, sx: { margin: 0, padding: 0 } }) }, "channel.card." + feedImageUrl + forceRender));
    let newPostCard;
    if (auth.userId) {
        newPostCard = (_jsx(NewPostCard, { keyStr: "new.post.card.feed.page", channelOwnerId: auth.userId, channelId: auth.userId, auth: auth, reload: () => {
                setPostIds(undefined);
            } }, "new.post.card.feed.page"));
    }
    const cardsList = [];
    if (errorMessage) {
        cardsList.push(_jsx(ContentCard, { keyStr: "content.card.error.message", title: "ERROR", backgroundColor: "#FFEEEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })), actions: _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setErrorMessage(undefined);
                    } }, { children: "DISMISS" })) })) }, "content.card.error.message"));
    }
    else if (postDeletedOk) {
        cardsList.push(_jsx(ContentCard, { keyStr: "content.card.deletedok.message", title: "SUCCESS", backgroundColor: "#EEFFEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Your post has been deleted." })) }, "content.card.deletedok.message"));
    }
    else {
        if (helpVisible) {
            cardsList.push(helpCard);
        }
        if (privacyVisible) {
            cardsList.push(privacyCard);
        }
        if (settingsVisible) {
            cardsList.push(settingsCard);
        }
        else {
            if (newPostCard) {
                cardsList.push(newPostCard);
            }
            cardsList.push(channelCard);
            if (auth.userId && postIds && postIds.length > 0) {
                for (let i = 0; i < postIds.length; ++i) {
                    const entry = postIds[i];
                    const basePath = getContentURL() + "/u/" + auth.userId + "/" + auth.userId;
                    const postId = entry.substring(0, entry.lastIndexOf("."));
                    cardsList.push(_jsx(PostCard, { channelOwnerId: auth.userId, channelId: auth.userId, postId: postId, keyStr: entry + forceRender, basePath: basePath, postJson: authUrl(entry, cacheBusterStore, auth), enableEditButtons: true, expandPostContent: false, deletePost: deletePostParent, onClick: () => {
                            navigate("/PostView?channelOwnerId=" +
                                auth.userId +
                                "&channelId=" +
                                auth.userId +
                                "&postId=" +
                                entry.substring(0, entry.lastIndexOf(".")));
                        }, maxMediaHeight: window.innerHeight - 70 }, entry + forceRender));
                }
            }
        }
    }
    const columnStack = (_jsx(ContentCardStacks, { cards: cardsList }, "cards.stack.feed"));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [_jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInput, style: { display: "none" }, onChange: feedImageFilenameChanged }, "feed-hidden-file-input"), _jsx("canvas", { ref: canvasRef, style: { display: "none" } }, "canvas"), _jsx(TopButtonMenu, { current: "feed" }), loading && _jsx(LinearProgress, { sx: { width: "100%", margin: "2px" } }), columnStack, hasMorePosts && !settingsVisible && (_jsx("div", Object.assign({ style: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                } }, { children: _jsx(LoadMoreButton, { onClick: loadMorePosts, loading: loadingMore }) }))), !errorMessage && !postDeletedOk && topLinkCard, !helpVisible && !postDeletedOk && helpButtonDiv] }), "content.feed"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }, maxWidth: false }, { children: content }), "feed.container." + forceRender), _jsx(Footer, {})] })) }), "page.div." + forceRender));
    return output;
}
